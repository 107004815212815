import { Route } from "react-router-dom";
import { Routes } from "react-router-dom";
import Logon from "./logon/Logon";
import Find from "./find/Find";
import Register from "./register/Register";
import Info from "./info/Info";

function User() {
  return (
    <Routes>
      <Route element={<Logon />} path="logon" />
      <Route element={<Find />} path="find" />
      <Route element={<Register />} path="register" />
      <Route element={<Info />} path="info" />
    </Routes>
  );
}

export default User;
