import request from "utils/request";
import { Toast } from "react-vant";
export async function upload(data) {
  return await request.post("/profile/uploadStation.php", data, "nocache");
}

export const uploadFile = async (file, key = "file") => {
  try {
    const body = new FormData();
    body.append(key, file);
    const response = await upload(body);
    if (response && response.status === true) {
      return response.data;
    }
    Toast.info(response.message);
    return { url: "/error" };
    // return Promise.reject("发生异常");
  } catch (error) {
    return { url: "/error" };
    // return Promise.reject("发生异常");
  }
};
