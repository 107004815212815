import { useNavigate, useLocation } from "react-router-dom";
import CommonNavBar from "views/components/CommonNavBar";
import { Toast } from "react-vant";
import styles from "./PointsDetail.module.less";
import { getIntegralRecord } from "api/points";
import { useEffect, useRef, useState } from "react";
import useScrollLoad from "utils/useScrollLoad";

const loadingText = {
  nomore: "已经到底啦～",
  loading: "正在加载～",
  empty: "暂无数据"
};

function PointsDetail() {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [dataList, setDataList] = useState([]);
  const pointLoadingRef = useRef(null);
  const initData = async () => {
    pointLoadingRef.current = Toast.loading({
      message: "积分明细获取中...",
      forbidClick: true,
      duration: 0
    });
    setLoading(true);
    const res = await getIntegralRecord(currentPage);
    setLoading(false);
    if (res.code === 200) {
      setCurrentPage(currentPage + 1);
      setTotalCount(res.data.total_count);
      if (Array.isArray(res.data?.data) && res.data.data.length > 0) {
        setDataList([...dataList, ...res.data.data]);
      } else {
        setTotalCount(0);
      }
    }
    pointLoadingRef.current.clear();
  };
  const containerRef = useScrollLoad(() => {
    if (dataList.length < totalCount && !loading) {
      initData();
    }
  });
  useEffect(() => {
    initData();
  }, []);
  return (
    <div className={styles["points-detail-container"]}>
      <CommonNavBar
        onClickBack={() => {
          navigate(-1);
        }}
        title="积分明细"
      />
      <img
        className={styles["right-banner"]}
        src={require("@/images/mine/img_integration_top_bg.png")}
      />
      <div className={styles["points-header"]}>
        <span className={styles["points-header-title"]}>
          {queryParams.get("integral")}
        </span>
        <span className={styles["points-header-text"]}>积分</span>
      </div>
      <div className={styles["points-list"]} ref={containerRef}>
        <div className={styles["points-list-detail"]}>
          <div className={styles["detail-title"]}>积分明细</div>
          {dataList.map((item, index) => (
            <div
              className={styles["detail-item"]}
              key={`points-detail-${index}`}
            >
              <div className={styles["detail-item-left"]}>
                <div className={styles["left-title"]}>{`${
                  item.type ? item.type + "-" : ""
                }${item.notes}`}</div>
                <div className={styles["left-text"]}>{item.create_time}</div>
              </div>
              <div className={styles["detail-item-right"]}>
                <div className={styles["right-title"]}>{`${
                  item.integral > 0 ? "+" : ""
                }${item.integral}`}</div>
                <div
                  className={styles["right-text"]}
                >{`剩余${item.total_integral}`}</div>
              </div>
            </div>
          ))}
        </div>
        <div className={styles["points-list-bottom"]}>
          {loading
            ? loadingText["loading"]
            : dataList.length === 0
            ? loadingText["empty"]
            : loadingText["nomore"]}
        </div>
      </div>
    </div>
  );
}
export default PointsDetail;
