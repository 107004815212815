import { useNavigate, useLocation } from "react-router-dom";
import { Cell, Card, Button, Image, NavBar, Toast } from "react-vant";
import "./ProductDetail.less";
import DSBridge from "dsbridge";
const ic_order_copy = require("assets/icon/ic_order_copy.png");
function ProductDetail() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const {
    product_name,
    product_description,
    order_status,
    order_no,
    order_date,
    total_amount,
    unit_price,
    quantity,
    thumb,
    order_detail_id
  } = state;
  const [YYYY, MM, DD] = order_date.split("-");
  const copy = (text) => {
    // navigator.clipboard
    //   .writeText(text)
    //   .then(() => {
    //     Toast.info("已复制到剪贴板！");
    //   })
    //   .catch((err) => {
    //     Toast.fail("复制失败：" + err);
    //   });
    try {
      const hiddenText = document.createElement("textarea");
      hiddenText.id = "hiddenText";
      hiddenText.value = text;
      document.body.appendChild(hiddenText);
      hiddenText.select();
      hiddenText.setSelectionRange(0, 99999);
      document.execCommand("copy");
      document.body.removeChild(hiddenText);
      Toast.info("已复制到剪贴板！");
    } catch (err) {
      Toast.fail("复制失败：" + err);
    }
  };
  return (
    <div className="product-detail">
      <NavBar
        fixed
        title="订单信息"
        onClickLeft={() => navigate(-1)}
        style={{ background: "transparent" }}
      />
      <div className="product-detail-context">
        <div className="top1 card">
          <div className="top1_status">{order_status}</div>
          <div className="top1_default">{`创建时间：${YYYY}年${MM}月${DD}日`}</div>
          <div className="top1_default1" onClick={() => copy(order_no)}>
            <div>{`订单编号：${order_no}`}</div>
            <img src={ic_order_copy} />
          </div>
          <div className="top1_default">购买数量：{quantity}</div>
          <div className="top1_bottom">
            实际支付<span>￥{total_amount}</span>
          </div>
        </div>
        <div className="content card">
          <div className="content_title">商品信息</div>
          <div className="top">
            <img src={`${process.env.REACT_APP_API_CDN}${thumb}`} alt="" />
            <div className="top_right">
              <div className="top_right_title">{product_name}</div>
              <div className="top_right_orderCode">单位价格：</div>
            </div>
          </div>
          <div className="msg">{product_description}</div>
          {/* <Button
            block
            plain
            round
            onClick={() => navigate(`/mall/lessonState?id=${order_detail_id}`)}
            type="primary"
            style={{ marginTop: "1rem" }}
          >
            查看课程使用状态
          </Button> */}
          <div
            className="btn"
            onClick={() => navigate(`/mall/lessonState?id=${order_detail_id}`)}
          >
            查看课程使用状态
          </div>
        </div>
        {/* <Card round>
          <Card.Cover>
            <div className="product-detail-content-img">
              <Image fit="contain" src={`${process.env.REACT_APP_API_CDN}` + thumb} />
            </div>
          </Card.Cover>
          <Card.Header>{product_name}</Card.Header>
          <Card.Body>{product_description}</Card.Body>
          <Card.Footer>
            <Button
              block
              onClick={() => {
                navigate(`/mall/lessonState?id=${order_detail_id}`);
              }}
              size="small"
              type="primary"
            >
              查看课程使用状态
            </Button>
          </Card.Footer>
        </Card>
        <Cell.Group>
          <Cell title="单位价格" value={unit_price} />
          <Cell title="商品总价" value={total_amount} />
          <Cell title="购买数量" value={quantity} />
          <Cell title="实付款" value={total_amount} />
          <Cell title="下单时间" value={order_date} />
          <Cell title="订单号码" value={order_no} />
          <Cell title="订单状态" value={order_status} />
        </Cell.Group>
        <div className="double-block "></div> */}
      </div>
    </div>
  );
}

export default ProductDetail;
