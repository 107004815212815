import { useEffect, useState } from "react";
import styles from "./LoginTab.module.less";

function LoginTab({ defaultValue, dataList, onSelect, className }) {
  const [activeTab, setActiveTab] = useState("");
  const handleSelect = (key) => {
    setActiveTab(key);
    if (onSelect) {
      onSelect(key);
    }
  };
  useEffect(() => {
    try {
      setActiveTab(defaultValue || dataList[0].key);
    } catch {
      console.log("传入数据错误");
    }
  }, []);
  return (
    <div className={`${styles["tabs-wrapper"]} ${className}`}>
      {dataList.map((item, index) => (
        <div
          className={`${styles["tabs-wrapper-item"]} ${
            item.key === activeTab || dataList.length === 1
              ? styles["tabs-wrapper-item--selected"]
              : ""
          }`}
          key={`login-tabs-${index}`}
          onClick={() => handleSelect(item.key)}
        >
          <span className={styles["tabs-wrapper-item-text"]}>{item.value}</span>
        </div>
      ))}
    </div>
  );
}
export default LoginTab;
