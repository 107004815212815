import React from "react";
import "utils/rem.js";
import ReactDOM from "react-dom/client";
import "./index.less";
import App from "./App";
import { ConfigProvider } from "react-vant";
import reportWebVitals from "./reportWebVitals";

const themeVars = {
  "--rv-nav-bar-text-color": "#202244",
  "--rv-nav-bar-icon-color": "#202244",
  "--rv-nav-bar-arrow-size": "1.5rem",
  "--rv-nav-bar-title-font-size": "2rem",
  "--rv-nav-bar-height": "3.5rem",
  "--rv-swipe-slide-size": "50%",
  "--rv-tabbar-item-text-color": "red"
};
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <ConfigProvider themeVars={themeVars}>
    <App />
  </ConfigProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
