import React, { useEffect, useState } from "react";
import CommonNavBar from "views/components/CommonNavBar";
import { useNavigate } from "react-router-dom";
import styles from "./ClassHourRecord.module.less";

function ClassHourRecord() {
    const navigate = useNavigate();

    return (
        <div className={styles["main-container"]}>
            <CommonNavBar title={"课时记录"} onClickBack={() => {
                navigate(-1);
            }}
            />
            <img
                className={styles["main-container-banner"]}
                src={require("@/images/mine/img_integration_top_bg.png")}
            />
            <div className={styles["classHour"]}>
                <div className={styles["classHour-item"]}>
                    <div className={styles["item-hour"]}>
                        <span className={styles["item-hour-text"]}>
                         1096
                        </span>
                    </div>
                    <div className={styles["item-desc"]}>缴费课时</div>
                </div>
                <div className={styles["classHour-item"]}>
                    <div className={styles["item-hour"]}>
                        <span className={styles["item-hour-text"]}>
                          178
                        </span>
                    </div>
                    <div className={styles["item-desc"]}>消耗课时</div>
                </div>
                <div className={styles["classHour-item"]}>
                    <div className={styles["item-hour"]}>
                        <div className={styles["item-hour-integral"]}>2485</div>
                    </div>
                    <div className={styles["item-desc"]}>剩余课时</div>
                </div>
            </div>
            <div className={styles["class-record"]}>
                <div className={styles["class-record-list"]}>
                    <div className={styles["class-record-list-item1"]}>
                        <div className={styles["class-record-list-item1-text1"]}>课程过期</div>
                        <div className={styles["class-record-list-item1-text2"]}>-300</div>
                    </div>
                    <div className={styles["class-record-list-item2"]}>
                        <div>2024.10.11 11:23:54</div>
                        <div>-剩余1095</div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default ClassHourRecord;
