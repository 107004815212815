import React from "react";
import { Image, Skeleton } from "react-vant";
import { useNavigate } from "react-router-dom";
import "./CollectCom.less";
import { useEffect } from "react";
import { getMyMaterials } from "api/course";
import { useState } from "react";
import { Photo } from "@react-vant/icons";

function CollectCom() {
  const [collectList, setCollect] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      const favoriteRes = await getMyMaterials();
      if (favoriteRes.code === 200) {
        try {
          if (favoriteRes.data.length < 2) {
            favoriteRes.data.push({
              cover: require("@/images/image/seize.png"),
              title: "更多教材待收藏"
            });
          }
          setCollect(favoriteRes.data);
        } catch (e) {
          console.log(e);
        }
      }
    };

    fetchData();
  }, []);

  return (
    <div className="collectBox">
      {collectList.length > 0 ? (
        <div className="collect-list">
          {collectList.map((item, index) => (
            <div
              className="collect-list-item"
              key={`collect-item-${index}`}
              onClick={() => {
                if (item.series_id && item.grade_id && item.book_id) {
                  navigate(
                    `/textbook/coursePackage?series_id=${item.series_id}&grade_id=${item.grade_id}&book_id=${item.book_id}`
                  );
                } else {
                  navigate("/main/textbook");
                }
              }}
            >
              <Image
                className="item-img"
                src={item.cover}
                showLoading={false}
                loadingIcon={
                  <Photo src={item.cover.replace("small", "thumb")} />
                }
              />
              <div className="item-text">{item.title}</div>
            </div>
          ))}
        </div>
      ) : (
        <Skeleton title />
      )}
    </div>
  );
}

export default CollectCom;
