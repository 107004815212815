import React, { useRef, useState, useEffect } from "react";
import { Image, Toast } from "react-vant";
import { useNavigate } from "react-router-dom";
import "./TeacherTop.less";
import { getTeacherList } from "api/home";
import { getAudioInfo } from "api/teacher";
import { Player } from "@lottiefiles/react-lottie-player";
import animationData from "@/images/image/voice.json";
import { Photo } from "@react-vant/icons";
const audioUnplayImg = require("@/assets/icon/ic_teacher_list_voice.png");
function TeacherTop() {
  const [teacher, setTeacher] = useState([]);
  const [currentPlay, setCurrentPlay] = useState(null);
  const audioRef = useRef(null);
  const navigate = useNavigate();
  async function handlePlayAudio(index) {
    audioRef.current.pause();
    if (currentPlay !== index) {
      if (teacher[index].audio) {
        audioRef.current.src = teacher[index].audio;
      } else {
        Toast.loading({
          message: "音频加载中...",
          forbidClick: true,
          duration: 0
        });
        const audioForm = new FormData();
        audioForm.append("voice", teacher[index].voice_file_name);
        audioForm.append("text", teacher[index].introduction);
        const audioRes = await getAudioInfo(audioForm);
        console.log(audioRes);
        Toast.clear();
        teacher[index].audio = `data:audio/wav;base64,${audioRes.data}`;
        audioRef.current.src = `data:audio/wav;base64,${audioRes.data}`;
        setTeacher([...teacher]);
      }
      audioRef.current.currentTime = 0;
      audioRef.current.play();
      setCurrentPlay(index);
    }
  }
  useEffect(() => {
    const fetchData = async () => {
      const result = await getTeacherList();
      setTeacher(result.data);
    };

    fetchData();
  }, []);
  useEffect(() => {
    audioRef.current = new Audio();
    function handleStop() {
      audioRef.current.src = "";
      setCurrentPlay(null);
    }
    audioRef.current.addEventListener("pause", handleStop);
    audioRef.current.addEventListener("ended", handleStop);
    return () => {
      handleStop();
      audioRef.current.pause();
      audioRef.current.removeEventListener("pause", handleStop);
      audioRef.current.removeEventListener("ended", handleStop);
    };
  }, []);
  return (
    <div className="teacher-top">
      <div className="teacher-list">
        {teacher.map((item, index) => (
          <div className="teacher-list-item" key={`teacherTop-item-${index}`}>
            <Image
              src={item.image_url}
              showLoading={false}
              loadingIcon={<Photo src={item.image_url + "-thumb"} />}
              onClick={() => {
                navigate("/main/learn/0");
              }}
            />
            <div className="center-text">
              <span className="center-text-name">{item.name}</span>
              <div
                className="teacher-title-icon"
                onClick={(e) => {
                  e.stopPropagation();
                  handlePlayAudio(index);
                }}
              >
                {currentPlay === index ? (
                  <Player
                    className="icon-content"
                    autoplay
                    loop
                    src={animationData}
                  />
                ) : (
                  <img className="icon-content" src={audioUnplayImg} />
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default TeacherTop;
