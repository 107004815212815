import request from "utils/request";

export function getDemoLesson() {
  return request.get(`/materials/demoLesson.php`, "nocache");
}

export function expirationDate() {
  return request.get(`/mall/expirationDate.php`, "nocache");
}

export function updateUserName(data) {
  return request.post(`/profile/updateUserName.php`, data, "nocache");
}