import { Route } from "react-router-dom";
import { Routes } from "react-router-dom";
import MyProduct from "./MyProduct";
import ProductDetail from "./ProductDetail";
import LessonState from "./LessonState";
import NotFoundPage from "views/NotFoundPage";
import Record from "./Record";
import RecordIframe from "./RecordIframe";

function Mall() {
  return (
    <>
      <Routes>
        <Route element={<MyProduct />} path="myProduct" />
        <Route element={<ProductDetail />} path="productDetail" />
        <Route element={<LessonState />} path="lessonState" />
        <Route element={<NotFoundPage />} path="*" />
        <Route element={<Record />} path="record" />
        <Route element={<RecordIframe />} path="recordIframe" />
      </Routes>
    </>
  );
}

export default Mall;
