import { Link } from "react-router-dom";
import { Image } from "react-vant";
import "./NotFoundPage.less";
function NotFoundPage() {
  return (
    <div className="not-found-container">
      <div style={{ marginTop: "20%" }}></div>
      <Image src={require("../images/image/empty1.png")} />
      <h3>很抱歉你看到这个页面</h3>
      <p>这表明您访问的页面不存在</p>
      <p>
        <Link to="/main/home">请返回主页继续浏览。</Link>
      </p>
    </div>
  );
}
export default NotFoundPage;
