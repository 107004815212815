import {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle
} from "react";
import "./Countdown.less";
const Countdown = forwardRef(({ handleCountChange }, ref) => {
  const [show, setShow] = useState(false);
  const timer = useRef(null);
  const [count, setCount] = useState(null);
  let firstRef = useRef(0);
  function handleStart(count) {
    setCount(count);
  }
  function handleStop() {
    if (timer.current) {
      clearInterval(timer.current);
      timer.current = null;
      setShow(false);
    }
  }
  useEffect(() => {
    function handleAnimationEnd(e) {
      const classNames = e.target.className.split(" ");
      if (classNames.includes("countdown-container--hide")) {
        setCount(null);
      }
    }
    const countdownDom = document.querySelector(".countdown-container");
    if (countdownDom) {
      countdownDom.addEventListener("transitionend", handleAnimationEnd);
      countdownDom.addEventListener("webkitTransitionEnd", handleAnimationEnd);
    }
    return () => {
      if (countdownDom) {
        countdownDom.removeEventListener("transitionend", handleAnimationEnd);
        countdownDom.removeEventListener(
          "webkitTransitionEnd",
          handleAnimationEnd
        );
      }
      handleStop();
    };
  }, []);
  useEffect(() => {
    if (!!!timer.current && count !== null && count > 0) {
      timer.current = setInterval(() => {
        setCount((count) => count - 1);
      }, 1000);
      setShow(true);
    }
    if (!!timer.current && count !== null && count <= 0) {
      handleStop();
    }
    if (!firstRef.current) {
      firstRef.current = 1;
    } else {
      handleCountChange(count);
    }
  }, [count]);
  useImperativeHandle(ref, () => ({
    handleStart,
    handleStop
  }));
  return (
    <div
      className={`countdown-container${
        show ? " countdown-container--show" : " countdown-container--hide"
      }`}
    >
      <div className="text">
        <span className="text-number">{count}</span>
        <span className="text-unit">s</span>
      </div>
    </div>
  );
});
export default Countdown;
