import { useNavigate, useLocation } from "react-router-dom";
import styles from "./GoodsDetail.module.less";
import { useEffect, useRef, useState } from "react";
import CommonNavBar from "views/components/CommonNavBar";
import CommonButton from "views/components/CommonButton";
import { getAddressList, getGoodsInfo, integralExchange } from "api/points";
import { Toast } from "react-vant";
function GoodsDetail() {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [integral, setIntegral] = useState(0);
  const [goodData, setGoodData] = useState(null);
  const [popShow, setPopShow] = useState(false);
  const [addressId, setAddressId] = useState(null);
  const [addressList, setAddressList] = useState([]);
  const addressLoadingRef = useRef(null);
  const handleClick = async () => {
    if (popShow === false) {
      addressLoadingRef.current = Toast.loading({
        message: "正在获取收货地址...",
        forbidClick: true,
        duration: 0
      });
      const res = await getAddressList();
      addressLoadingRef.current.clear();
      if (res.code === 200) {
        setAddressList(res.data);
        setPopShow(true);
      }
    }
    if (popShow === true) {
      addressLoadingRef.current = Toast.loading({
        message: "正在兑换商品...",
        forbidClick: true,
        duration: 0
      });
      const integralForm = new FormData();
      integralForm.append("address_id", addressId);
      integralForm.append("goods_id", goodData.goods_id);
      const res = await integralExchange(integralForm);
      addressLoadingRef.current.clear();
      if (res.code === 200) {
        Toast.info(res.message);
        navigate(-2);
      }
    }
  };
  const initData = async (id) => {
    addressLoadingRef.current = Toast.loading({
      message: "正在加载数据...",
      forbidClick: true,
      duration: 0
    });
    const res = await getGoodsInfo(id);
    addressLoadingRef.current.clear();
    if (res.code === 200) {
      setGoodData(res.data);
    }
  };
  useEffect(() => {
    if (queryParams.get("integral")) {
      setIntegral(Number(queryParams.get("integral")));
    }
    if (queryParams.get("id")) {
      initData(queryParams.get("id"));
    } else {
      Toast.info("缺少商品id，请返回重试");
    }
  }, []);
  return (
    <div
      className={styles["goods-detail-container"]}
      style={{ overflow: popShow ? "hidden" : "auto" }}
    >
      <CommonNavBar
        isTop
        onClickBack={() => {
          navigate(-1);
        }}
      />
      {goodData ? (
        <>
          {goodData.thumb ? (
            <img className={styles["goods-pic"]} src={goodData.thumb} />
          ) : null}

          <div className={styles["goods-desc"]}>
            <div className={styles["goods-desc-block"]}>
              <div className={styles["block-text"]}>{goodData.goods_name}</div>
              <div
                className={styles["block-integral"]}
              >{`${goodData.integral}积分`}</div>
            </div>
            <div className={styles["goods-desc-block"]}>
              <div className={styles["block-title"]}>商品描述</div>
              <div className={styles["block-details"]}></div>
              {goodData.content_img ? (
                <img
                  className={styles["block-content"]}
                  src={goodData.content_img}
                />
              ) : null}
            </div>
          </div>
          <div className={styles["button-wrapper"]}>
            <CommonButton
              delay={500}
              disabled={
                integral < goodData.integral ||
                (popShow === true && addressId === null)
              }
              onClick={handleClick}
              text={
                integral < goodData.integral
                  ? "积分不足"
                  : popShow === true
                  ? "确认兑换"
                  : "立即兑换"
              }
            />
          </div>
        </>
      ) : null}
      {popShow ? (
        <div
          className={styles["address-pop"]}
          onClick={() => {
            setPopShow(false);
          }}
        >
          <div
            className={styles["address-pop-content"]}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className={styles["content-header"]}>
              <span className={styles["content-header-title"]}>
                请选择收货地址
              </span>
              {addressList.length > 0 ? (
                <div
                  className={styles["content-header-add"]}
                  onClick={() => {
                    navigate("/mine/mailingAddress");
                  }}
                >
                  <img
                    className={styles["add-icon"]}
                    src={require("@/images/mine/ic_address_add.png")}
                  />
                  <span className={styles["add-text"]}>新增收货地址</span>
                </div>
              ) : null}
            </div>
            <div className={styles["content-body"]}>
              {addressList.map((item, index) => {
                return (
                  <div
                    className={styles["content-item"]}
                    key={`goods-detail-address-${index}`}
                    onClick={() => setAddressId(item.address_id)}
                  >
                    <div className={styles["content-item-left"]}>
                      <div className={styles["left-title"]}>
                        <span className={styles["left-title-name"]}>
                          {item.consignee}
                        </span>
                        <span className={styles["left-title-phone"]}>
                          {item.mobile}
                        </span>
                      </div>
                      <div className={styles["left-address"]}>
                        {`${item.province || ""}${item.city || ""}${item.area}`}
                      </div>
                    </div>
                    {item.address_id === addressId ? (
                      <img
                        className={styles["content-item-right--icon"]}
                        src={require("@/images/mine/ic_address_pick.png")}
                      />
                    ) : (
                      <div className={styles["content-item-right"]}></div>
                    )}
                  </div>
                );
              })}
              {addressList.length === 0 ? (
                <div
                  className={styles["add-item"]}
                  onClick={() => {
                    navigate("/mine/mailingAddress");
                  }}
                >
                  <img
                    className={styles["add-item-icon"]}
                    src={require("@/images/mine/ic_address_add.png")}
                  />
                  <span className={styles["add-item-text"]}>
                    还没有收获地址，去添加
                  </span>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}
export default GoodsDetail;
