import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Register.module.less";
import LoginInput from "../components/LoginInput";
import LoginTab from "../components/LoginTab";
import CommonButton from "../../components/CommonButton";
import InputContainer from "../components/InputContainer";
import CommonNavBar from "views/components/CommonNavBar";
import CodeDown from "../components/CodeDown";
import { Toast } from "react-vant";
import { register } from "api/register";
import { commonRules, handleRulesOfUseClick } from "../components/utils";

const initTabs = [
  {
    key: "register",
    value: "注册账号"
  }
];

function Register() {
  const navigate = useNavigate();
  const [agree, setAgree] = useState(false);
  const inputContainerRef = useRef(null);
  const registerToastRef = useRef(null);
  const handleRegister = async (params) => {
    registerToastRef.current = Toast.loading({
      message: "正在注册中...",
      forbidClick: true,
      duration: 0
    });
    console.log(params);
    const res = await register(params);
    registerToastRef.current.clear();
    if (res.code === 200) {
      Toast.info(res.message);
      navigate("/login/logon", { replace: true });
    }
  };
  const handleClick = async () => {
    try {
      const params = await inputContainerRef.current.getValues();
      if (params.repassword !== params.password) {
        return Toast.info("密码与确认密码不同");
      }
      if (!agree) {
        return Toast.info("请在登录前阅读并同意我们的条款 ");
      }
      handleRegister(params);
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <div className={styles["register-container"]}>
      <CommonNavBar
        onClickBack={() => {
          navigate(-1);
        }}
      />
      <img
        className={styles["right-banner"]}
        src={require("@/images/login/right-banner.png")}
      />
      <img
        className={styles["top-banner"]}
        src={require("@/images/login/center-banner.png")}
      />
      <LoginTab className={styles["login-tab"]} dataList={initTabs} />
      <InputContainer ref={inputContainerRef} rules={commonRules}>
        <LoginInput
          className={styles["login-input"]}
          field="phone_number"
          icon={require("@/images/login/ic_mobile.png")}
          inputMode="numeric"
          placeholderText="请输入手机号"
          selectedIcon={require("@/images/login/ic_mobile_selected.png")}
        />
        <LoginInput
          className={styles["login-input"]}
          field="verification_code"
          icon={require("@/images/login/ic_mail.png")}
          inputMode="numeric"
          placeholderText="请输入验证码"
          selectedIcon={require("@/images/login/ic_mail_selected.png")}
        >
          <CodeDown
            getPhone={() => {
              return inputContainerRef.current.getValue("phone_number");
            }}
          />
        </LoginInput>
        <LoginInput
          className={styles["login-input"]}
          field="password"
          icon={require("@/images/login/ic_pwd.png")}
          placeholderText="登录密码"
          selectedIcon={require("@/images/login/ic_pwd_selected.png")}
          type="password"
        />
        <LoginInput
          className={styles["login-input"]}
          field="repassword"
          icon={require("@/images/login/ic_pwd.png")}
          placeholderText="确认登录密码"
          selectedIcon={require("@/images/login/ic_pwd_selected.png")}
          type="password"
        />
        <LoginInput
          className={styles["login-input"]}
          field="inviteCode"
          icon={require("@/images/login/ic_code.png")}
          placeholderText="邀请码（非必填）"
          selectedIcon={require("@/images/login/ic_code_selected.png")}
        />
      </InputContainer>
      <div className={styles["bottom-rule"]}>
        <div
          className={`${styles["bottom-rule-icon"]} ${
            agree ? styles["bottom-rule-icon--checked"] : ""
          }`}
          onClick={() => setAgree(!agree)}
        ></div>
        <div className={styles["bottom-rule-text"]}>
          <div>已阅读并同意</div>
          <div
            className={styles["bottom-rule-text--link"]}
            onClick={() => handleRulesOfUseClick(navigate, "app")}
          >
            《使用规则》
          </div>
          <div
            className={styles["bottom-rule-text--link"]}
            onClick={() => handleRulesOfUseClick(navigate, "yszc")}
          >
            《隐私协议》
          </div>
          <div
            className={styles["bottom-rule-text--link"]}
            onClick={() => handleRulesOfUseClick(navigate, "zxky")}
          >
            《服务协议》
          </div>
          <div
            className={styles["bottom-rule-text--link"]}
            onClick={() => handleRulesOfUseClick(navigate, "AI")}
          >
            《平台服务协议》
          </div>
        </div>
      </div>
      <CommonButton
        className={styles["login-submit"]}
        delay={500}
        onClick={handleClick}
        text="注册"
      />
      <div
        className={styles["check-way"]}
        onClick={() => {
          navigate(-1);
        }}
      >
        <span className={styles["check-way-left"]}>已有账号？</span>
        <span className={styles["check-way-right"]}>去登录</span>
      </div>
    </div>
  );
}
export default Register;
