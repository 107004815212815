import React, { useState } from "react";
import "./VoiceMessage.less";
import { createFromIconfontCN } from "@react-vant/icons";

const IconFont = createFromIconfontCN({
  scriptUrl: import("@/images/iconfont.js")
});
const VoiceMessage = ({
  color,
  message,
  type,
  created_at,
  togglePlay,
  src,
  srcTime,
  srcText
}) => {
  const [flag, setFlag] = useState(false);
  const translate = () => {
    setFlag(!flag);
  };
  const isPlaying1 = () => {
    return created_at == srcTime && message == srcText;
  };
  return (
    <div className="voice-message">
      {type === "teacher" ? (
        <div className="voice-contont">
          <div onClick={translate} style={{ marginTop: ".2rem" }}>
            <IconFont
              className="icon"
              name="icon-weibiaoti556"
              style={{ width: "2rem", height: "2rem" }}
            />
          </div>
          <button
            onClick={() => {
              togglePlay(src, created_at, message);
            }}
            style={{ backgroundColor: `${color}` }}
          >
            {isPlaying1() ? (
              <>
                <IconFont className="icon" name="icon-yuyinhecheng" />
                <span>正在播放</span>
              </>
            ) : (
              <>
                <IconFont className="icon" name="icon-yuyin" />
                <span>播放</span>
              </>
            )}
          </button>
        </div>
      ) : (
        <div className="voice-contont">
          <button
            onClick={() => {
              togglePlay(src, created_at, message);
            }}
            style={{
              backgroundColor: `${color}`,
              flexDirection: "row-reverse"
            }}
          >
            {isPlaying1() ? (
              <>
                <IconFont className="icon" name="icon-yuyinhecheng" />
                <span>正在播放</span>
              </>
            ) : (
              <>
                <IconFont className="icon" name="icon-yuyin" />
                <span>播放</span>
              </>
            )}
          </button>
          <div onClick={translate}>
            <IconFont
              className="icon"
              name="icon-weibiaoti556"
              style={{ width: "2rem", height: "2rem" }}
            />
          </div>
        </div>
      )}
      {flag && (
        <div className="translate" style={{ backgroundColor: `${color}` }}>
          {flag ? message : <></>}
          <p></p>
          <div>{flag ? created_at : <></>}</div>
        </div>
      )}
    </div>
  );
};

export default React.memo(VoiceMessage);
