import { useNavigate } from "react-router-dom";
import CommonNavBar from "views/components/CommonNavBar";
import styles from "./RecommendedTextbooks.module.less";
const RecommendedTextbooks = () => {
  const navigate = useNavigate();
  return (
    <div className={styles["recommended-container"]}>
      <CommonNavBar
        onClickBack={() => {
          navigate(-1);
        }}
      />
      <img
        className={styles["imgs"]}
        src="https://cdn03.myaitalk.vip/static/banner-bg.jpg"
      />
    </div>
  );
};

export default RecommendedTextbooks;
