import styles from "./CommonNavBar.module.less";
function CommonNavBar({
  title,
  backgroundColor,
  isTop = false,
  noSafe = false,
  showLeft = true,
  onClickBack = () => {}
}) {
  return (
    <div
      className={`${styles["common-bar"]} ${
        noSafe ? styles["common-bar--no-safe"] : ""
      }`}
    >
      {!isTop ? <div className={styles["placeholder-block"]}></div> : null}
      <div
        className={`${styles["bar-wrapper"]}`}
        style={{ background: backgroundColor || "" }}
      >
        <div className={styles["bar-wrapper-content"]}>
          {showLeft ? (
            <img
              className={styles["content-icon"]}
              onClick={onClickBack}
              src={require("@/images/login/ic_back.png")}
            />
          ) : null}
          <div className={styles["content-title"]}>{title}</div>
        </div>
      </div>
    </div>
  );
}
export default CommonNavBar;
