import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect
} from "react";
import { Toast } from "react-vant";

const InputContainer = forwardRef((props, ref) => {
  const [childRefs, setChildRefs] = useState([]);

  useImperativeHandle(ref, () => ({
    getValue: (key) => {
      return (
        childRefs
          .find((childRef) => childRef.current.getKey() === key)
          ?.current.getValue() || ""
      );
    },
    getValues: () => {
      return new Promise((resolve, reject) => {
        const values = {};
        let errorMessage = undefined;
        for (let i = 0; i < childRefs.length; i++) {
          const childRef = childRefs[i];
          if (childRef.current.getKey()) {
            if (
              Object.prototype.hasOwnProperty.call(
                props.rules,
                childRef.current.getKey()
              )
            ) {
              props.rules[childRef.current.getKey()](
                childRef.current.getValue(),
                (message) => {
                  if (message) {
                    Toast(message);
                    errorMessage = message;
                  }
                }
              );
            }
            Object.assign(values, {
              [childRef.current.getKey() || i]: childRef.current.getValue()
            });
            if (errorMessage) {
              break;
            }
          }
        }
        if (errorMessage) {
          reject(errorMessage);
        } else {
          resolve(values);
        }
      });
    }
  }));

  useEffect(() => {
    setChildRefs(
      React.Children.map(
        props.children,
        (_, index) => childRefs[index] || React.createRef()
      )
    );
  }, [props.children]);

  return React.Children.map(props.children, (child, index) =>
    React.cloneElement(child, { ref: childRefs[index] })
  );
});

export default InputContainer;
