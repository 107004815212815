import React, { useEffect, useState, useRef } from "react";
import { Image, Cell, Toast, Empty, Dialog } from "react-vant";
import "./TeacherList.less";
import { useNavigate } from "react-router-dom";
import TransferPage from "../material/TransferPage";
import { getAudioInfo } from "api/teacher";
import { Player } from "@lottiefiles/react-lottie-player";
import animationData from "@/images/image/voice.json";
import { Photo } from "@react-vant/icons";
const audioUnplayImg = require("@/assets/icon/ic_teacher_list_voice.png");
const likeImg = require("@/assets/icon/ic_book_list_like.png");
const unlikeImg = require("@/assets/icon/ic_book_list_unlike.png");
function TeacherList({ handleFavorite, dataList, isSearch }) {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [teacherList, setTeacherList] = useState([]);
  const [currentPlay, setCurrentPlay] = useState(null);
  const [isStudy, setIsStudy] = useState(false);
  const audioRef = useRef(null);
  function course(item) {
    if (!!!localStorage.getItem("access_token")) {
      Toast.info("当前操作需要登录!");
      navigate("/login/logon");
      return;
    }
    if (!item.character_id || !item.image_url || !item.name) {
      Dialog.alert({
        message: "当前教师不能授课, 请选择其他教师"
      });
      return;
    }
    localStorage.setItem(
      "teacher_info",
      JSON.stringify({
        character_id: item.character_id,
        image_url: item.image_url,
        name: item.name
      })
    );
    localStorage.setItem(
      "game_info",
      JSON.stringify({
        teacher_avatar: item.image_url
      })
    );
    if (localStorage.getItem("courseware_name") !== null) {
      setVisible(true);
    } else {
      navigate("/main/textbook");
      Dialog.alert({
        message: "选择外教成功，请选择课程！"
      });
    }
  }
  async function handlePlayAudio(index) {
    audioRef.current.pause();
    if (currentPlay !== index) {
      if (teacherList[index].audio) {
        audioRef.current.src = teacherList[index].audio;
      } else {
        Toast.loading({
          message: "音频加载中...",
          forbidClick: true,
          duration: 0
        });
        const audioForm = new FormData();
        audioForm.append("voice", teacherList[index].voice_file_name);
        audioForm.append("text", teacherList[index].introduction);
        const audioRes = await getAudioInfo(audioForm);
        Toast.clear();
        teacherList[index].audio = `data:audio/wav;base64,${audioRes.data}`;
        audioRef.current.src = `data:audio/wav;base64,${audioRes.data}`;
        setTeacherList([...teacherList]);
      }
      audioRef.current.currentTime = 0;
      audioRef.current.play();
      setCurrentPlay(index);
    }
  }
  useEffect(() => {
    setIsStudy(localStorage.getItem("courseware_name") !== null);
    audioRef.current = new Audio();
    function handleStop() {
      audioRef.current.src = "";
      setCurrentPlay(null);
    }
    audioRef.current.addEventListener("pause", handleStop);
    audioRef.current.addEventListener("ended", handleStop);
    return () => {
      audioRef.current.pause();
      handleStop();
      audioRef.current.removeEventListener("pause", handleStop);
      audioRef.current.removeEventListener("ended", handleStop);
    };
  }, []);
  useEffect(() => {
    setTeacherList(dataList);
  }, [dataList]);
  return (
    <div className="teacher-list-content">
      {teacherList.length > 0 ? (
        <>
          {teacherList.map((item, idx) => (
            <Cell
              center
              icon={
                <Image
                  round
                  src={item.image_url}
                  showLoading={false}
                  loadingIcon={<Photo src={item.image_url + "-thumb"} />}
                />
              }
              key={idx}
              label={<div className="teacher_intro">{item.introduction}</div>}
              title={
                <div className="teacher-title">
                  <div className="teacher-title-name">{item.name}</div>
                  <div className="teacher-title-icon-box">
                    {currentPlay === idx ? (
                      <div
                        className="lottie-wrapper"
                        onClick={() => handlePlayAudio(idx)}
                      >
                        <Player
                          autoplay
                          className="lottie-wrapper-icon"
                          loop
                          src={animationData}
                        />
                      </div>
                    ) : (
                      <img
                        className="teacher-title-icon"
                        onClick={() => handlePlayAudio(idx)}
                        src={audioUnplayImg}
                      />
                    )}

                    <img
                      className="teacher-title-icon"
                      onClick={async () => handleFavorite(item.character_id)}
                      src={item.favorite ? likeImg : unlikeImg}
                    />
                    <div
                      className="teacher-list-btn"
                      onClick={() => course(item)}
                    >
                      {isStudy ? "去学习" : "选择外教"}
                    </div>
                  </div>
                </div>
              }
            />
          ))}
          {dataList.length > 0 ? (
            <div className="bottom-text">已经到底啦～</div>
          ) : null}
        </>
      ) : (
        <Empty
          description={
            localStorage.getItem("access_token") ? (
              isSearch ? (
                <>对不起，没有您搜索的内容哦~</>
              ) : (
                <>
                  还没有外教被您收藏哦~
                  <br></br>
                  快去外教列表收藏心仪的外教吧！
                </>
              )
            ) : (
              <>请登录以便获取更多信息</>
            )
          }
          image={
            <Image
              fit="contain"
              src={require("../../../images/image/Ub.png")}
            />
          }
          imageSize={140}
          style={{ backgroundColor: "transparent", marginTop: "10vh" }}
        />
      )}
      <TransferPage setVisible={setVisible} visible={visible} />
    </div>
  );
}
export default React.memo(TeacherList);
