import { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styles from "./ModifyPhone.module.less";
import LoginInput from "views/login/components/LoginInput";
import LoginTab from "views/login/components/LoginTab";
import CommonButton from "views/components/CommonButton";
import InputContainer from "views/login/components/InputContainer";
import CommonNavBar from "views/components/CommonNavBar";
import CodeDown from "views/login/components/CodeDown";
import { Toast } from "react-vant";
import { updateUserMobile, getUserPhone } from "api/points";
import { commonRules } from "views/login/components/utils";

const initTabs = [
  {
    key: "modify",
    value: "换绑手机号"
  }
];

function ModifyPhone() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const inputContainerRef = useRef(null);
  const phoneLoadingRef = useRef(null);
  const [defaultPhone, setDefaultPhone] = useState("");
  const handleModify = async (params) => {
    const res = await updateUserMobile(params);
    if (res.code === 200) {
      Toast.info(res.message);
      localStorage.clear();
      navigate("/login/logon", { replace: true });
    }
  };
  const handleClick = async () => {
    try {
      const params = await inputContainerRef.current.getValues();
      handleModify(params);
    } catch (e) {
      console.log(e);
    }
  };
  const initPhone = async () => {
    phoneLoadingRef.current = Toast.loading({
      message: "正在获取手机号中...",
      forbidClick: true,
      duration: 0
    });
    const res = await getUserPhone();
    phoneLoadingRef.current.clear();
    if (res.code === 200 && res?.data?.phone_number) {
      setDefaultPhone(res.data.phone_number);
    }
  };
  useEffect(() => {
    initPhone();
  }, []);
  return (
    <div className={styles["modify-phone-container"]}>
      <CommonNavBar
        onClickBack={() => {
          if (state && state.lastRoute) {
            navigate(state.lastRoute, { replace: true });
          } else {
            navigate(-1);
          }
        }}
      />
      <img
        className={styles["right-banner"]}
        src={require("@/images/login/right-banner.png")}
      />
      <img
        className={styles["top-banner"]}
        src={require("@/images/login/center-banner.png")}
      />
      <LoginTab className={styles["login-tab"]} dataList={initTabs} />
      <InputContainer ref={inputContainerRef} rules={commonRules}>
        <LoginInput
          className={styles["login-input"]}
          field="phone_number"
          icon={require("@/images/login/ic_mobile.png")}
          inputMode="numeric"
          placeholderText="请输入手机号"
          selectedIcon={require("@/images/login/ic_mobile_selected.png")}
          defaultValue={defaultPhone}
        />
        <LoginInput
          className={styles["login-input"]}
          field="verification_code"
          icon={require("@/images/login/ic_mail.png")}
          inputMode="numeric"
          placeholderText="请输入验证码"
          selectedIcon={require("@/images/login/ic_mail_selected.png")}
        >
          <CodeDown
            getPhone={() => {
              return inputContainerRef.current.getValue("phone_number");
            }}
          />
        </LoginInput>
      </InputContainer>
      <CommonButton
        className={styles["login-submit"]}
        delay={500}
        onClick={handleClick}
        text="提交"
      />
    </div>
  );
}
export default ModifyPhone;
