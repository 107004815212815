import request from "utils/request";

export function sendVerificationCode(data) {
  return request.post("/register/sendVerificationCode.php", data, "nocache");
}

export function userLogin(data) {
  return request.post("/register/userLogin.php", data, "nocache");
}

export function changePassword(data) {
  return request.post("/register/changePassword.php", data, "nocache");
}

export function register(data) {
  return request.post("/register/register.php", data, "nocache");
}

export function refreshTokenApi(data) {
  return request.post("/register/refreshToken.php", data, "nocache");
}
