import { useEffect, useRef, useState } from "react";
import "./Ssc.less";
import Countdown from "./components/Countdown";
import DSBridge from "dsbridge";
import { getStudentInfo, getTeacherInfo } from "utils/userProfile";
export default function Ssc() {
  const [startDisabled, setStartDisabled] = useState(false);
  const countRef = useRef(null);
  const sscRef = useRef(null);
  const [studentSelect, setStudentSelect] = useState(null);
  const [teacherSelect, setTeacherSelect] = useState(null);
  const gameItems = ["stone", "scissor", "cloth"];
  const changeTimerRef = useRef(null);
  const [score, setScore] = useState(0);
  const resultScoreRef = useRef(0);
  const [showSelect, setShowSelect] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [regular, setRegular] = useState(true);
  const exitRef = useRef(null);
  const [studentAvatar, setStudentAvatar] = useState("");
  const [teacherAvatar, setTeacherAvatar] = useState("");
  function handleClickItem(item) {
    if (!startDisabled) {
      setStartDisabled(true);
      countRef.current.handleStop();
      if (changeTimerRef.current) {
        clearInterval(changeTimerRef.current);
        changeTimerRef.current = null;
      }
      setStudentSelect(item);
      if (Math.random() < 0.8) {
        if (item === "stone") {
          setTeacherSelect("scissor");
        }
        if (item === "scissor") {
          setTeacherSelect("cloth");
        }
        if (item === "cloth") {
          setTeacherSelect("stone");
        }
        const randomScore = [6, 8, 10][Math.floor(Math.random() * 3)];
        setScore(randomScore);
        resultScoreRef.current = randomScore;
      } else {
        if (item === "stone") {
          setTeacherSelect("cloth");
        }
        if (item === "scissor") {
          setTeacherSelect("stone");
        }
        if (item === "cloth") {
          setTeacherSelect("scissor");
        }
      }
      setShowSelect(true);
    }
  }
  function handleCountChange(count) {
    if (count === 0) {
      setStartDisabled(true);
      countRef.current.handleStop();
      setStudentSelect(null);
      if (changeTimerRef.current) {
        clearInterval(changeTimerRef.current);
        changeTimerRef.current = null;
      }
      exitRef.current = setTimeout(() => {
        DSBridge.call("stopAudio", "ssc.mp3");
        DSBridge.call("exitWebView");
      }, 1000);
      console.log("倒计时未选择，跳出");
    }
  }
  async function init() {
    setTeacherAvatar(
      JSON.parse(localStorage.getItem("game_info"))?.teacher_avatar ||
        getTeacherInfo()?.image_url ||
        ""
    );
    if (getStudentInfo()?.profile_image) {
      setStudentAvatar(
        `${process.env.REACT_APP_API_CDN}${
          getStudentInfo().profile_image[0] === "/" ? "" : "/"
        }${getStudentInfo().profile_image}`
      );
    }
    setStartDisabled(false);
    setStudentSelect(null);
    setTeacherSelect(null);
    setRegular(true);
    setScore(0);
    resultScoreRef.current = 0;
    setShowSelect(false);
    setShowResult(false);
    countRef.current.handleStart(15);
    let index = 0;
    changeTimerRef.current = setInterval(() => {
      setStudentSelect(gameItems[index]);
      if (index < 2) {
        index++;
      } else {
        index = 0;
      }
    }, 400);
    DSBridge.call("playAudio", "ssc.mp3");
  }
  useEffect(() => {
    init();
    function handleAnimationEnd(e) {
      const classNames = e.target.className.split(" ");
      if (
        classNames.includes("animation-wrapper-item") &&
        e.animationName === "hand_shake_left"
      ) {
        setRegular(false);
      }
      if (classNames.includes("animation-wrapper")) {
        setShowSelect(false);
        setShowResult(true);
        if (resultScoreRef.current > 0) {
          DSBridge.call("playAudio", "victory.mp3");
        } else {
          DSBridge.call("playAudio", "failure.mp3");
        }
      }
      if (classNames.includes("result-wrapper")) {
        exitRef.current = setTimeout(() => {
          if (!!resultScoreRef.current) {
            DSBridge.call("addScore", String(resultScoreRef.current));
          }
          DSBridge.call("stopAudio", "ssc.mp3");
          DSBridge.call("stopAudio", "victory.mp3");
          DSBridge.call("stopAudio", "failure.mp3");
          DSBridge.call("exitWebView");
        }, 1000);
        console.log(
          `动画结束，进入最终页面,获得加分为:${resultScoreRef.current}`
        );
      }
    }
    sscRef.current = document.querySelector(".ssc-container");
    if (sscRef.current) {
      sscRef.current.addEventListener("animationend", handleAnimationEnd);
      sscRef.current.addEventListener("webkitAnimationEnd", handleAnimationEnd);
    }
    return () => {
      if (sscRef.current) {
        sscRef.current.removeEventListener("animationend", handleAnimationEnd);
        sscRef.current.removeEventListener(
          "webkitAnimationEnd",
          handleAnimationEnd
        );
      }
      if (changeTimerRef.current) {
        clearInterval(changeTimerRef.current);
        changeTimerRef.current = null;
      }
      if (exitRef.current) {
        clearTimeout(exitRef.current);
      }
    };
  }, []);
  return (
      <div className="ssc-container">
        {/*<img className="bg-top" src={require("@/images/game/ssc/bg-top.png")} />*/}
        {/*<img className="bg-bottom" src={require("@/images/game/ssc/bg-bottom.png")}/>*/}
        <div className="bg-top"></div>
        <div className="bg-bottom"></div>
        <Countdown handleCountChange={handleCountChange} ref={countRef}/>
        <div className="top-title"></div>
        {!showResult ? (
            <>
              <div className="teacher-bg">
                <img className="teacher-bg-avator" src={teacherAvatar}/>
                <div className="teacher-bg-text">Teacher</div>
              </div>
              <div className="student-bg">
                <div className="student-bg-list">
                  {gameItems.map((item, index) => (
                      <div
                          className={`list-item ${item} ${
                              studentSelect === item ? " list-item--select" : ""
                          }`}
                          key={`ssc-item-${index}`}
                          onClick={() => handleClickItem(item)}
                      ></div>
                  ))}
                </div>
                {studentAvatar ? (
                    <img className="student-bg-avator" src={studentAvatar}/>
                ) : null}
                <div className="student-bg-text">Student</div>
              </div>
            </>
        ) : (
            <div className="result-wrapper">
              <img
                  className="result-wrapper-wl"
                  src={require(
                      `@/images/game/ssc/${score !== 0 ? "win-text" : "lose-text"}.png`
                  )}
              />
              <img
                  className="result-wrapper-u"
                  src={require(
                      `@/images/game/ssc/${score !== 0 ? "score-" + score : "lose"}.png`
                  )}
              />
            </div>
        )}
        {showSelect ? (
            <div className="animation-wrapper">
              <img
                  className="animation-wrapper-item"
                  src={
                    regular
                        ? require(`@/images/game/ssc/stone.png`)
                        : require(`@/images/game/ssc/${teacherSelect}.png`)
                  }
              />
              <div className="animation-wrapper-center">VS</div>
              <img
                  className="animation-wrapper-item"
                  src={
                    regular
                        ? require(`@/images/game/ssc/stone.png`)
                        : require(`@/images/game/ssc/${studentSelect}.png`)
                  }
              />
            </div>
        ) : null}
      </div>
  );
}
