export function getTeacherInfo() {
    const teacher = {
        character_id: "loki",
        image_url: `${process.env.REACT_APP_API_CDN}/utalkrc/teacher/4.png`,
        name: "Ethan Davis"
    };
    let teacherInfo = {};
    try {
        teacherInfo = JSON.parse(localStorage.getItem("teacher_info"));
    } catch {
        console.log("教师信息解析失败");
    }
    const ret = { ...teacher, ...teacherInfo };
    ret.isCrash = teacherInfo == null;
    return ret;
}

export function getStudentInfo() {
    const studenrt = {
        birthday: "1996-01-19",
        english_name: "",
        gender: "女",
        member_id: 1,
        phone_number: "26305395431",
        profile_image: "/utalkrc/global/utalk.png"
    };
    let studenrtInfo = {}
    try {
        studenrtInfo = JSON.parse(localStorage.getItem("userInfo"));
    } catch {
        console.log("学生信息解析失败");
    }
    const ret = { ...studenrt, ...studenrtInfo };
    ret.isCrash = studenrtInfo == null;
    return ret;
}

export function getTTS() {
    return "elevenlabs";
}

export function getServicePoint() {
    if (!localStorage.getItem("servicePoint")) {
        return process.env.REACT_APP_ENV === "development" ? "local" : "gpt-4";
    }
    return localStorage.getItem("servicePoint");
}

export function getKcid(lesson = "") {
    const path =
        localStorage.getItem("data_json_path") ??
        "test1/materials/test1_lesson1_1120.json";
    const kcid = {
        "gpt-4": lesson ? lesson : path,
        azure: lesson ? lesson : path,
        xfllm: lesson ? lesson : path,
        local: lesson ? lesson : path,
    };
    return kcid;
}

export function isAppleDevice() {
    return /iPhone|iPad|iPod|Macintosh/.test(navigator.userAgent);
}
