export function isNonEmptyObject(variable) {
  if (typeof variable === "object" && variable !== null) {
    return Object.keys(variable).length > 0;
  }
  return false;
}

export function isNonEmptyArray(variable) {
  return Array.isArray(variable) && variable.length > 0;
}
export function mergeValidValues(obj1, obj2) {
  // 使用Object.keys获取两个对象的所有键名
  const allKeys = new Set([...Object.keys(obj1), ...Object.keys(obj2)]);

  // 使用Array.from将键名集合转换为数组
  const merged = Array.from(allKeys).reduce((result, key) => {
    // 检查两个对象中对应键名的值是否有效
    const value1 = obj1[key];
    const value2 = obj2[key];

    // 这里定义“有效值”的逻辑，例如不为null、undefined或空字符串
    const isValidValue = (value) => value !== null && value !== undefined && value !== '';

    // 如果两个值都有效，使用第二个值
    if (isValidValue(value1) && isValidValue(value2)) {
      result[key] = value2;
    } else if (isValidValue(value1) || (!isValidValue(value2) && value1 !== undefined)) {
      // 如果第一个值有效，或者两个值都无效但第一个值存在，使用第一个值
      result[key] = value1;
    } else {
      // 否则使用第二个值
      result[key] = value2;
    }

    return result;
  }, {});

  return merged;
}