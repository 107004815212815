import { useNavigate, useLocation } from "react-router-dom";
import CommonNavBar from "views/components/CommonNavBar";
import styles from "./LogisticsInformation.module.less";
import { useEffect, useRef, useState } from "react";
import { getQueryExpress } from "api/points";
import { Toast } from "react-vant";

const recordStatus = {
  已发货: "info-wrapper-title--status1",
  已完成: "info-wrapper-title--status2",
  待发货: "info-wrapper-title--status3"
};
function LogisticsInformation() {
  const navigate = useNavigate();
  const location = useLocation();
  const [goodsParams, setGoodsParams] = useState(null);
  const [expressData, setExpressData] = useState(null);
  const logLoadingRef = useRef(null);
  const queryParams = new URLSearchParams(location.search);
  const initData = async () => {
    const params = JSON.parse(decodeURIComponent(queryParams.get("goods")));
    if (!params) {
      return Toast.fail("缺少物流参数，请返回");
    }
    setGoodsParams(params);
    const { shipping_code, invoice_no, order_sn } = params;
    logLoadingRef.current = Toast.loading({
      message: "物流信息获取中...",
      forbidClick: true,
      duration: 0
    });
    const res = await getQueryExpress({
      shipping_code,
      invoice_no,
      order_sn
    });
    logLoadingRef.current.clear();
    if (res.code === 200) {
      if (res.data?.status == 200) {
        setExpressData(res.data?.data);
      } else {
        Toast.info(res.data.message);
      }
    }
  };
  useEffect(() => {
    initData();
  }, []);
  return (
    <div className={styles["log-info-container"]}>
      <CommonNavBar
        onClickBack={() => {
          navigate(-1);
        }}
        title="物流信息"
      />
      <div className={styles["log-list"]}>
        {goodsParams ? (
          <div className={styles["info-wrapper"]}>
            <div
              className={`${styles["info-wrapper-title"]} ${
                styles[recordStatus[goodsParams.status]]
              }`}
            >
              {goodsParams.status}
            </div>
            <div className={styles["info-wrapper-text"]}>
              {`物流公司：${goodsParams.shipping_name}`}
            </div>
            <div className={styles["info-wrapper-text"]}>
              {`创建时间：${goodsParams.create_time}`}
            </div>
            <div
              className={styles["info-wrapper-text"]}
              onClick={() => {
                try {
                  const hiddenText = document.createElement("textarea");
                  hiddenText.id = "hiddenText";
                  hiddenText.value = goodsParams.order_sn;
                  document.body.appendChild(hiddenText);
                  hiddenText.select();
                  hiddenText.setSelectionRange(0, 99999);
                  document.execCommand("copy");
                  document.body.removeChild(hiddenText);
                  Toast.info("已复制到剪贴板！");
                } catch (err) {
                  Toast.fail("复制失败：" + err);
                }
                // navigator.clipboard
                //   .writeText(goodsParams.order_sn)
                //   .then(() => {
                //     Toast.info("已复制到剪贴板！");
                //   })
                //   .catch((err) => {
                //     Toast.fail("复制失败：" + err);
                //   });
              }}
            >
              <span>{`订单编号：${goodsParams.order_sn}`}</span>
              <img
                className={styles["info-wrapper-text-icon"]}
                src={require("@/images/mine/ic_order_copy.png")}
              />
            </div>
            <div className={styles["info-wrapper-good"]}>
              <img className={styles["good-pic"]} src={goodsParams.thumb} />
              <div className={styles["good-title"]}>
                {goodsParams.goods_name}
              </div>
            </div>
          </div>
        ) : null}
        {expressData ? (
          <div className={styles["status-list"]}>
            {expressData.map((item, index) => (
              <div
                className={styles["status-list-item"]}
                key={`logistics-express-${index}`}
              >
                <div className={styles["item-top"]}>
                  {/* <span className={styles["item-top-title"]}>已签收</span> */}
                  <span className={styles["item-top-time"]}>{item.time}</span>
                </div>
                <div className={styles["item-content"]}>{item.context}</div>
              </div>
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
}
export default LogisticsInformation;
