import React from "react";
import { Skeleton } from "react-vant";
import { getBanner } from "api/home";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Image } from "react-vant";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import "./Banners.less";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
function Banners() {
  const [banner, setBanner] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const fetchData = async () => {
    const bannerData = await getBanner();
    setLoading(false);
    setBanner(bannerData.data);
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div className="banners-content">
      {loading ? (
        <Skeleton title />
      ) : (
        <>
          <div className="banner-box--phone">
            <Swiper
              autoplay={{
                delay: 5000,
                stopOnLastSlide: false,
                disableOnInteraction: false
              }}
              loop
              modules={[Pagination, Autoplay]}
              pagination={{ clickable: true }}
              slidesPerView={1}
              spaceBetween={10}
            >
              {banner.length > 0 ? (
                banner.map((result) => (
                  <SwiperSlide key={result.banner_id}>
                    <Image
                      fit="cover"
                      onClick={() => {
                        if (result.link_url) {
                          navigate(result.link_url);
                        }
                      }}
                      src={result.image_url}
                    />
                  </SwiperSlide>
                ))
              ) : (
                <>
                  <SwiperSlide>
                    <Image cover src={require(`@/images/image/banner1.png`)} />
                  </SwiperSlide>
                </>
              )}
            </Swiper>
          </div>
          <div className="banner-box--ipad">
            {banner.length > 0 ? (
              banner.map((item, index) => (
                <Image
                  className="banner-item"
                  src={item.image_url}
                  key={`ipad--${item.banner_id}-${index}`}
                  onClick={() => {
                    if (item.link_url) {
                      navigate(item.link_url);
                    }
                  }}
                />
              ))
            ) : (
              <Image
                className="banner-item"
                src={require(`@/images/image/banner1.png`)}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default Banners;
