import { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./AccountManage.module.less";
import CommonButton from "views/components/CommonButton";
import CommonNavBar from "views/components/CommonNavBar";
import { DatetimePicker, Picker, Toast, Notify } from "react-vant";
import { formatDateToYYYYMMDD } from "utils/date";
import { uploadFile } from "api/upload";
import { getUserProfile, updateUserProfile } from "api/profile";
import { getUserPhone } from "api/points";
import { mergeValidValues } from "utils/objectTools.js";
import { getStudentInfo } from "utils/userProfile";

const columns = ["男", "女"];
function AccountManage() {
  const navigate = useNavigate();
  const [defaultPhone, setDefaultPhone] = useState("");
  const [userData, setUserData] = useState({});
  const infoLoadingRef = useRef(null);
  const handleAvatarChange = async (e) => {
    const file = e.target.files[0];
    const maxSize = 2 * 1024 * 1024;
    if (file.size > maxSize) {
      return Toast.fail("请选择小于2MB头像文件上传");
    }
    try {
      infoLoadingRef.current = Toast.loading({
        message: "上传头像中...",
        forbidClick: true,
        duration: 0
      });
      const avatarRes = await uploadFile(file);
      infoLoadingRef.current.clear();
      if (avatarRes && avatarRes.key) {
        setUserData({ ...userData, profile_image: avatarRes.key });
      }
    } catch (e) {
      console.log(e);
    }
  };
  const handleConfirm = async () => {
    const regEmoji =
      /[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF][\u200D|\uFE0F]|[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF]|[0-9|*|#]\uFE0F\u20E3|[0-9|#]\u20E3|[\u203C-\u3299]\uFE0F\u200D|[\u203C-\u3299]\uFE0F|[\u2122-\u2B55]|\u303D|[\xA9|\xAE]\u3030|\uA9|\uAE|\u3030/;
    if (
      !/^[a-zA-Z\s]*$/.test(userData.english_name) ||
      regEmoji.test(userData.english_name)
    ) {
      return Notify.show({
        type: "danger",
        message: "英文名仅允许输入英文字符"
      });
    }
    const res = await updateUserProfile(userData);
    if (res.code === 200) {
      Toast.info(res.message);
      localStorage.setItem(
        "userInfo",
        JSON.stringify(mergeValidValues(getStudentInfo(), userData))
      );
      navigate(-1);
    }
  };
  const initData = async () => {
    infoLoadingRef.current = Toast.loading({
      message: "正在获取个人信息中...",
      forbidClick: true,
      duration: 0
    });
    const phoneRes = await getUserPhone();
    if (phoneRes.code === 200 && phoneRes?.data?.phone_number) {
      setDefaultPhone(phoneRes.data.phone_number);
    }
    const profileRes = await getUserProfile();
    if (profileRes.code === 200) {
      if (profileRes?.data.english_name) {
        profileRes.data.english_name = decodeURIComponent(
          profileRes.data.english_name
        );
      }
      setUserData(profileRes.data);
    }
    infoLoadingRef.current.clear();
  };
  useEffect(() => {
    initData();
  }, []);
  return (
    <div className={styles["account-container"]}>
      <CommonNavBar
        backgroundColor="#fff"
        onClickBack={() => {
          navigate(-1);
        }}
        title="账号管理"
      />
      <div className={styles["info-avatar"]}>
        <div className={styles["info-avatar-content"]}>
          <img
            className={styles["content-img"]}
            src={
              userData.profile_image
                ? `${process.env.REACT_APP_API_CDN}/${userData.profile_image}`
                : require("@/images/login/ic_avatar.png")
            }
          />
          <input
            accept="image/*"
            className={styles["info-avatar-input"]}
            onChange={handleAvatarChange}
            type="file"
          />
          <span className={styles["content-text"]}>
            {userData.profile_image ? "点击修改头像" : "点击上传头像"}
          </span>
        </div>
        <img
          className={styles["info-avatar-arrow"]}
          src={require("@/images/login/ic_right_arrow.png")}
        />
      </div>
      <div className={styles["info-common-cell"]}>
        <div className={styles["cell-content"]}>
          <img
            className={styles["cell-content-icon"]}
            src={require("@/images/login/ic_mobile.png")}
          />
          <div className={styles["cell-content-input"]}>{defaultPhone}</div>
        </div>
        <div
          className={styles["cell-modify"]}
          onClick={() => {
            navigate("/mine/modifyPhone", {
              state: {
                lastRoute: "/mine/accountManage"
              },
              replace: true
            });
          }}
        >
          换绑手机
        </div>
      </div>
      <div className={styles["info-common-cell"]}>
        <div className={styles["cell-content"]}>
          <img
            className={styles["cell-content-icon"]}
            src={require("@/images/login/ic_name.png")}
          />
          <input
            className={styles["cell-content-input"]}
            onChange={(e) => {
              setUserData({ ...userData, english_name: e.target.value });
            }}
            placeholder="英文名"
            type="text"
            value={userData?.english_name ?? ""}
            maxLength={15}
          />
        </div>
      </div>
      <Picker
        columns={columns}
        onConfirm={(v) => {
          setUserData({ ...userData, gender: v });
        }}
        popup={{
          round: true
        }}
        title="选择性别"
        value={userData?.gender || ""}
      >
        {(val, _, actions) => {
          return (
            <div
              className={styles["info-common-cell"]}
              onClick={() => actions.open()}
            >
              <div className={styles["cell-content"]}>
                <img
                  className={styles["cell-content-icon"]}
                  src={require(
                    `@/images/login/ic_${
                      userData.gender === "男"
                        ? "gender_man"
                        : userData.gender === "女"
                        ? "gender_woman"
                        : "gender"
                    }.png`
                  )}
                />
                <span className={styles["cell-content-input"]}>
                  {userData.gender || "选择性别"}
                </span>
              </div>
              <img
                className={styles["cell-arrow"]}
                src={require("@/images/login/ic_right_arrow.png")}
              />
            </div>
          );
        }}
      </Picker>
      <DatetimePicker
        maxDate={new Date()}
        minDate={new Date(1900, 0, 1)}
        onConfirm={(v) => {
          setUserData({ ...userData, birthday: formatDateToYYYYMMDD(v) });
        }}
        popup={{
          round: true
        }}
        title="选择年月日"
        type="date"
        value={userData.birthday || ""}
      >
        {(val, _, actions) => {
          return (
            <div
              className={styles["info-common-cell"]}
              onClick={() => actions.open()}
            >
              <div className={styles["cell-content"]}>
                <img
                  className={styles["cell-content-icon"]}
                  src={require("@/images/login/ic_date.png")}
                />
                <span className={styles["cell-content-input"]}>
                  {userData.birthday || "选择出生日期"}
                </span>
              </div>
              <img
                className={styles["cell-arrow"]}
                src={require("@/images/login/ic_right_arrow.png")}
              />
            </div>
          );
        }}
      </DatetimePicker>
      <CommonButton
        className={styles["login-submit"]}
        delay={500}
        onClick={handleConfirm}
        text="保存"
      />
    </div>
  );
}
export default AccountManage;
