import "./joinSuccess.less";
const az = require("@/assets/images/joinSuccess/安卓-8.png");
const bt = require("@/assets/images/joinSuccess/标题_1-8.png");
const pg = require("@/assets/images/joinSuccess/苹果-8.png");
const xz = require("@/assets/images/joinSuccess/下载安装方框-8.png");
const ub = require("@/assets/images/joinSuccess/U宝-8.png");
const gwb = require("@/assets/images/joinSuccess/公文包.png");
const s = require("@/assets/images/joinSuccess/书.png");
const y1 = require("@/assets/images/joinSuccess/云1.png");
const y2 = require("@/assets/images/joinSuccess/云2.png");
export default () => (
  <div className="box">
    <img src={bt} className="box_1" />
    <img src={xz} className="box_2" />
    <img src={ub} className="box_3" />
    <div className="box_4">
      <img src={az} />
      <div className="name">安卓下载二维码</div>
    </div>
    <div className="box_5">
      <img src={pg} />
      <div className="name">苹果下载二维码</div>
    </div>
    <img src={gwb} className="box_6" />
    <img src={s} className="box_7" />
    <img src={y1} className="box_8" />
    <img src={y2} className="box_9" />
  </div>
);
