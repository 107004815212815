import request from "utils/request";

export async function getIntegralList() {
  return request.get("/integral/index.php", "nocache");
}

export async function getIntegralRecord(v) {
  return request.get(`/integral/integralRecord.php?currentPage=${v}`, "nocache");
}

export async function getGoodsList(v) {
  return request.get(`/integral/goodsList.php?currentPage=${v}`, "nocache");
}

export async function getGoodsInfo(id) {
  return request.get(`/integral/goodsInfo.php?goods_id=${id}`, "nocache");
}

export async function integralExchange(data) {
  return request.post("/integral/integralExchange.php", data, "nocache");
}

export async function getExchangeRecord(v) {
  return request.get(`/integral/exchangeRecord.php?currentPage=${v}`, "nocache");
}

export async function getQueryExpress({ shipping_code, invoice_no, order_sn }) {
  return request.get(
    `/integral/queryExpress.php?shipping_code=${shipping_code}&invoice_no=${invoice_no}&order_sn=${order_sn}`,
    "nocache"
  );
}

export async function getAddress(parent_id) {
  return request.get(
    `/integral/getAddress.php?parent_id=${parent_id}`,
    "nocache"
  );
}

export async function getAddressList() {
  return request.get("/integral/addressList.php", "nocache");
}

export async function addressAdd(data) {
  return request.post("/integral/addressAdd.php", data, "nocache");
}

export async function addressEdit(data) {
  return request.post("/integral/addressEdit.php", data, "nocache");
}

export async function addressDel(id) {
  return request.get(`/integral/addressDel.php?address_id=${id}`, "nocache");
}

export async function updateUserMobile(data) {
  return request.post("/profile/updateUserMobile.php", data, "nocache");
}

export async function getUserPhone() {
  return request.get(`/profile/getUserPhone.php`, "nocache");
}