export class WebSocketSingleton {
  static _instance = null;
  static _url = "wss://logs.myaitalk.vip";
  static oldMessageList = [];
  static reconnectNum = 2;

  constructor() {
    // if (process.env.NODE_ENV === "production") {
    if (!WebSocketSingleton._instance) {
      try {
        if (
          localStorage.getItem("userInfo") &&
          JSON.parse(localStorage.getItem("userInfo"))?.phone_number
        ) {
          this._webSocket = new WebSocket(
            `${WebSocketSingleton._url}?mobile=${JSON.parse(localStorage.getItem("userInfo"))?.phone_number
            }`
          );
          this._webSocket.onopen = this._onOpen.bind(this);
          this._webSocket.onmessage = this._onMessage.bind(this);
          this._webSocket.onerror = this._onError.bind(this);
          this._webSocket.onclose = this._onClose.bind(this);
          WebSocketSingleton._instance = this;
        } else {
          console.log("暂无登录用户手机号，不链接ws");
        }
      } catch (e) {
        console.log(e);
      }
    }
    // } else {
    //   console.log("开发及测试环境不链接ws");
    // }
  }

  static getInstance() {
    if (!WebSocketSingleton._instance) {
      WebSocketSingleton._instance = new WebSocketSingleton();
    }
    return WebSocketSingleton._instance;
  }
  static reconnect() {
    if (WebSocketSingleton._instance) {
      try {
        if (
          WebSocketSingleton._instance._webSocket &&
          WebSocketSingleton._instance._webSocket.readyState !==
          WebSocket.CLOSED
        ) {
          WebSocketSingleton._instance._webSocket.close();
        }
        WebSocketSingleton._instance = null;
      } catch (e) {
        console.log(e);
      }
    }
    WebSocketSingleton._instance = new WebSocketSingleton();
  }
  send(message) {
    try {
      if (this._webSocket) {
        if (this._webSocket.readyState === WebSocket.OPEN) {
          this._webSocket.send(message);
          console.log(`ws 发送信息：${message}`);
        } else {
          console.error("ws 发送失败，状态:", this._webSocket.readyState);
          WebSocketSingleton.oldMessageList.push(message);
          if (WebSocketSingleton.reconnectNum > 0) {
            WebSocketSingleton.reconnectNum -= 1;
            WebSocketSingleton.reconnect();
          }
        }
      } else {
        console.log("暂无ws链接，不发送数据");
      }
    } catch (e) {
      console.log(e);
    }
  }

  _onOpen(event) {
    console.log("ws 打开链接", event);
    if (WebSocketSingleton.oldMessageList.length > 0) {
      const oldMessage = [...WebSocketSingleton.oldMessageList];
      if (!oldMessage.join("").includes("上次未发送数据：")) {
        oldMessage.unshift(`上次未发送数据：`);
      }
      this.send(oldMessage.join("\n"));
      WebSocketSingleton.oldMessageList = [];
    }
  }

  _onMessage(event) {
    console.log("ws 接收信息:", event.data);
  }

  _onError(error) {
    console.error("ws 错误:", error);
  }

  _onClose(event) {
    if (event.wasClean) {
      console.log(`连接已关闭，代码: ${event.code}, 原因: ${event.reason}`);
    } else {
      console.error("连接意外断开", event.reason);
    }
  }
}