import request from "utils/request";

export async function myProductList() {
  return request.get("/mall/getMyProductList.php", "nocache");
}

export async function getLessonStateList(id) {
  return request.get(
    `/mall/getLessonStateList.php?mall_order_detail_id=${id}`,
    "nocache"
  );
}
