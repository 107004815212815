import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./StudyToday.module.less";
import { getLastAsset } from "api/home";
import { expirationDate } from "api/welcome";
import { Skeleton } from "react-vant";
const StudyToday = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [todayData, setTodayData] = useState(null);
  const [expiration, setExpiration] = useState("");
  const initData = async () => {
    try {
      setIsLoading(true);
      const lastRes = await getLastAsset();
      if (
        lastRes.code === 200 &&
        typeof lastRes.data === "object" &&
        lastRes.data.hasOwnProperty("lesson_id")
      ) {
        setTodayData(lastRes.data);
      }
      const exRes = await expirationDate();
      if (exRes.code === 200 && typeof exRes.data === "string") {
        setExpiration(exRes.data);
      }
      setIsLoading(false);
    } catch (e) {
      console.log(e);
    }
  };
  const renderHeaderTitle = () => {
    return <div className={styles["header-title"]}>最近学习</div>;
  };
  const renderHeaderText = () => {
    return (
      <div className={styles["header-text"]}>
        <div className={styles["text--1"]}>Hi, 欢迎来到口袋领航外教!</div>
        <div className={styles["text--2"]}>准备好今天的学习了吗?</div>
      </div>
    );
  };
  const renderCenter = () => {
    return (
      <>
        {!isLoading && todayData ? (
          <div className={styles["today-bottom"]}>
            <div className={styles["today-bottom-content"]}>
              <div className={styles["content-left"]}>
                <img
                  className={styles["content-left-pic"]}
                  src={todayData?.cover}
                />
                <div className={styles["content-left-text"]}>
                  <div className={styles["text--1"]}>{todayData?.series}</div>
                  <div className={styles["text--2"]}>{todayData?.title}</div>
                  <div className={styles["text--3"]}>
                    {todayData?.grade_title}
                  </div>
                </div>
              </div>
              <div
                className={styles["content-right"]}
                onClick={() => {
                  navigate(`/studyReport?id=${todayData.lesson_id}`);
                }}
              >
                课程报告
              </div>
            </div>
            {expiration ? (
              <div className={styles["today-bottom-toast"]}>
                <img
                  className={styles["toast-icon"]}
                  src={require("@/assets/icon/ic_landing_warn.png")}
                />
                <span>{expiration}</span>
              </div>
            ) : null}
          </div>
        ) : null}
        {!isLoading && !todayData ? (
          <div className={styles["empty-block"]}>
            <div className={styles["empty-block-title"]}>
              {localStorage.getItem("access_token")
                ? "您还没有任何课程记录哦～"
                : "请登录以便获取更多信息"}
            </div>
            <div
              className={styles["empty-block-button"]}
              onClick={() => {
                if (localStorage.getItem("access_token")) {
                  navigate("/main/textbook");
                } else {
                  navigate("/login/logon");
                }
              }}
            >
              {localStorage.getItem("access_token") ? "立即选课" : "立即登录"}
            </div>
          </div>
        ) : null}
      </>
    );
  };
  useEffect(() => {
    initData();
  }, []);
  return (
    <div className={styles["study-today-container"]}>
      {isLoading && !todayData ? <Skeleton title /> : null}
      <div className={styles["box--phone"]}>
        <div className={styles["box--header"]}>
          {renderHeaderTitle()}
          {renderHeaderText()}
        </div>
        {renderCenter()}
      </div>
      <div className={styles["box--ipad"]}>
        {renderHeaderTitle()}
        <div className={styles["box-center"]}>
          <div className={styles["box-center-left"]}>{renderCenter()}</div>
          {renderHeaderText()}
        </div>
      </div>
    </div>
  );
};
export default StudyToday;
