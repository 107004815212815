import { useRef, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import CommonNavBar from "views/components/CommonNavBar";
import { Toast } from "react-vant";
import styles from "./PointsMall.module.less";
import { getGoodsList } from "api/points";
import useScrollLoad from "utils/useScrollLoad";

const loadingText = {
  nomore: "已经到底啦～",
  loading: "正在加载～",
  empty: "暂无数据"
};
function PointsMall() {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [dataList, setDataList] = useState([]);
  const pointLoadingRef = useRef(null);
  const initData = async () => {
    pointLoadingRef.current = Toast.loading({
      message: "正在加载数据...",
      forbidClick: true,
      duration: 0
    });
    setLoading(true);
    const res = await getGoodsList(currentPage);
    pointLoadingRef.current.clear();
    setLoading(false);
    if (res.code === 200) {
      setCurrentPage(currentPage + 1);
      setTotalCount(res.data.total_count);
      if (Array.isArray(res.data?.data) && res.data.data.length > 0) {
        setDataList([...dataList, ...res.data.data]);
      } else {
        setTotalCount(0);
      }
    }
  };
  const containerRef = useScrollLoad(() => {
    if (dataList.length < totalCount && !loading) {
      initData();
    }
  });
  useEffect(() => {
    initData();
  }, []);
  return (
    <div className={styles["points-mall-container"]}>
      <CommonNavBar
        isTop
        onClickBack={() => {
          navigate(-1);
        }}
      />
      <div className={styles["mall-header"]}>
        <div className={styles["mall-header-content"]}>
          <div className={styles["content-title"]}>
            {queryParams.get("integral")}
          </div>
          <div className={styles["content-text"]}>我的积分</div>
        </div>
        <img
          className={styles["mall-header-icon"]}
          src={require("@/images/mine/img_integration_store_bg.png")}
        />
      </div>
      <div className={styles["mall-list-container"]} ref={containerRef}>
        <div className={styles["mall-list"]}>
          {dataList.map((item, index) => (
            <div
              className={styles["mall-list-item"]}
              key={`points-mall-${index}`}
              onClick={() => {
                navigate(
                  `/mine/goodsDetail?integral=${queryParams.get(
                    "integral"
                  )}&id=${item.goods_id}`
                );
              }}
            >
              <img className={styles["item-pic"]} src={item.thumb} />
              <div className={styles["item-text"]}>{item.goods_name}</div>
              <div
                className={styles["item-integral"]}
              >{`${item.integral}积分`}</div>
            </div>
          ))}
          <div className={styles["mall-list-bottom"]}>
            {loading
              ? loadingText["loading"]
              : dataList.length === 0
              ? loadingText["empty"]
              : loadingText["nomore"]}
          </div>
        </div>
      </div>
    </div>
  );
}
export default PointsMall;
