import styles from "./Pay.module.less";
import { useEffect, useRef, useState } from "react";
import { Popup, Toast, Radio, Button } from "react-vant";
import { useNavigate } from "react-router-dom";
import { getProductList, getMyQualification, paymentCallback } from "api/order";
import { isAppleDevice } from "utils/userProfile";
import CommonNavBar from "views/components/CommonNavBar";
import ExchangePop from "./ExchangePop";
import DSBridge from "dsbridge";
const img_payment_bg = require("assets/icon/img_payment_bg.png");
const img_payment_kv = require("assets/icon/img_payment_kv.png");
const ic_payment_applepay = require("assets/icon/ic_payment_applepay.png");
const ic_payment_wx = require("assets/icon/ic_payment_wx.png");
const ic_payment_zfb = require("assets/icon/ic_payment_zfb.png");
function Pay() {
  const [goodsList, setGoodsList] = useState([]);
  const [popupShow, setPopupShow] = useState(false);
  const [targetData, setTargetData] = useState({});
  const [payValue, setPayValue] = useState("wechat");
  const [popState, setPopState] = useState(null);
  const payRef = useRef(null);
  const isApple = isAppleDevice();
  const navigate = useNavigate();
  const [exchangeShow, setExchangeShow] = useState(false);
  const [agree, setAgree] = useState(false);
  function getList() {
    getProductList().then((res) => {
      if (res.code === 200) {
        res.data.forEach((item) => {
          item.apple_price = Number(item.apple_price);
          item.other_price = Number(item.other_price);
        });
        setGoodsList(res.data);
      }
    });
  }
  const onClose = () => {
    setPopupShow(false);
  };
  const goPay = (data) => {
    setTargetData(data);
    if (isApple) {
      setPayValue("apple");
    } else {
      setPayValue("wechat");
    }
    setPopupShow(true);
  };
  function handlePay() {
    if (!agree) {
      return Toast.info("请阅读并同意课程服务协议");
    }
    Toast.info("加载支付中，请稍后...");
    const form = new FormData();
    const params = {
      activity_id: targetData.activity_id,
      pay_type: payValue
    };
    Object.keys(params).forEach((key) => {
      form.append(key, params[key]);
    });
    getMyQualification(form).then((res) => {
      if (res.code === 200) {
        if (payValue == "wechat" || payValue == "alipay") {
          const type = payValue === "wechat" ? "WXPay" : "AliPay";
          DSBridge.call(
            "pay.sendRequest",
            {
              type,
              data: res.data.payData
            },
            (back) => {
              setPopupShow(false);
              Toast.clear();
              try {
                back = JSON.parse(back);
                if (back.result === true) {
                  const paymentForm = new FormData();
                  paymentForm.append("orderNo", res.data.orderNo);
                  setTimeout(() => {
                    paymentCallback(paymentForm).then((res) => {
                      if (res.code === 200) {
                        setPopState({
                          result: true
                        });
                      } else {
                        setPopState({
                          result: false
                        });
                      }
                    });
                  }, 3000);
                } else {
                  setPopState({
                    result: false
                  });
                }
              } catch {
                Toast.fail({
                  message: "返回数据解析失败"
                });
              }
            }
          );
        } else {
          DSBridge.call(
            "pay.appleIAPRquest",
            {
              orderId: res.data.orderNo,
              productId: res.data.product_id
            },
            (back) => {
              Toast.clear();
              setPopupShow(false);
              try {
                back = JSON.parse(back);
                if (back.result) {
                  setPopState({
                    result: true
                  });
                } else {
                  setPopState({
                    result: false
                  });
                }
              } catch {
                Toast.fail({
                  message: "返回数据解析失败"
                });
              }
            }
          );
        }
      }
    });
  }
  useEffect(() => {
    getList();
  }, []);
  return (
    <div
      className={`${styles["pay-container"]} ${popupShow ? styles.pay : ""}`}
      ref={payRef}
    >
      <CommonNavBar
        onClickBack={() => {
          navigate(-1);
        }}
      />
      <div className={styles.topBox}>
        选择课时卡
        <img
          className={styles.topIcon1}
          src={popupShow ? img_payment_kv : img_payment_bg}
        />
        <div className={styles.topIcon2}>
          <div className={styles.top1}></div>
          <div className={styles.top2}></div>
        </div>
      </div>
      <div className={styles.goodsList}>
        {goodsList.map((item, index) => (
          <div key={index} className={styles.goodItemBox}>
            <div className={styles.left}>
              <div>仅需</div>
              <div>
                <span>￥</span>
                <span>{isApple ? item.apple_price : item.other_price}</span>
              </div>
            </div>
            <div className={styles.center}>
              <div>{`${item.quantity ?? ""}课时卡`}</div>
              <div>{`有效期${item.duration_days ?? ""}天`}</div>
            </div>
            <div className={styles.right}>
              <div onClick={() => goPay(item)}>立即购买</div>
            </div>
          </div>
        ))}
        <div
          className={styles["exchange-button"]}
          onClick={() => setExchangeShow(true)}
        >
          兑换码
        </div>
        <div className={styles["phone-number"]}>客服电话 0571-28010729</div>
      </div>

      <Popup
        visible={popupShow}
        position="bottom"
        onClose={onClose}
        style={{ borderRadius: "0.6rem 0.6rem 0 0" }}
      >
        <div className={styles.popupBox}>
          <div className={styles.popupBox1}>
            <div className={styles.left}>
              <div>{`${targetData.quantity ?? ""}课时卡`}</div>
              <div>{`有效期${targetData.duration_days ?? ""}天`}</div>
            </div>
            <div className={styles.right}>
              支付金额
              <span>￥</span>
              <span>
                {isApple ? targetData.apple_price : targetData.other_price}
              </span>
            </div>
          </div>
          <div className={styles["agreement-box"]}>
            <div
              className={`${styles["box-icon"]} ${
                agree ? styles["box-icon--checked"] : ""
              }`}
              onClick={() => setAgree(!agree)}
            ></div>
            <div className={styles["text-1"]} onClick={() => setAgree(!agree)}>
              同意
            </div>
            <div
              className={styles["text-2"]}
              onClick={() => {
                navigate(
                  `/webUrlView?webUrl=${encodeURIComponent(
                    `${process.env.REACT_APP_API_CDN}/utalkrc/privacy/en/app-service.html`
                  )}&title=${encodeURIComponent("课程服务协议")}`
                );
              }}
            >
              《课程服务协议》
            </div>
          </div>
          <div className={styles.popupBox2}>
            <div className={styles.top}>请选择支付方式</div>
            <div className={styles.pays}>
              <Radio.Group value={payValue} onChange={setPayValue}>
                {isApple ? (
                  <div
                    className={styles.payItem}
                    onClick={() => setPayValue("apple")}
                  >
                    <div>
                      <img src={ic_payment_applepay} alt="" />
                      苹果支付
                    </div>
                    <Radio name="apple"></Radio>
                  </div>
                ) : (
                  <>
                    <div
                      className={styles.payItem}
                      onClick={() => setPayValue("alipay")}
                    >
                      <div>
                        <img src={ic_payment_zfb} alt="" />
                        支付宝支付
                      </div>
                      <Radio name="alipay"></Radio>
                    </div>
                    <div
                      className={styles.payItem}
                      onClick={() => setPayValue("wechat")}
                    >
                      <div>
                        <img src={ic_payment_wx} alt="" />
                        微信支付
                      </div>
                      <Radio name="wechat"></Radio>
                    </div>
                  </>
                )}
              </Radio.Group>
            </div>
          </div>
          <div className={styles.popupBox3} onClick={handlePay}>
            立即支付
            <span>￥</span>
            <span>{targetData.price}</span>
          </div>
        </div>
      </Popup>
      <Popup
        closeOnClickOverlay={false}
        teleport={payRef.current}
        visible={popState !== null}
      >
        {popState !== null ? (
          <div className={styles["pop-content"]}>
            <img
              className={styles["pop-content-icon"]}
              src={
                popState.result === true
                  ? require("@/images/image/pay-success.png")
                  : require("@/images/image/pay-fail.png")
              }
            />
            <div className={styles["pop-content-result"]}>
              {popState.result === true ? "支付成功" : "支付失败"}
            </div>
            <Button
              className={styles["pop-content-confirm"]}
              onClick={() => {
                setPopState(null);
              }}
              type="primary"
            >
              确认
            </Button>
          </div>
        ) : null}
      </Popup>
      <ExchangePop show={exchangeShow} setShow={setExchangeShow} />
    </div>
  );
}

export default Pay;
