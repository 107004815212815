import React, { useState, useRef, useEffect } from "react";
import DSBridge from "dsbridge";
import { Toast } from "react-vant";
import { getMyQualification, getPreLesson } from "api/lesson";
import { calculateAge } from "utils/date";
import { useNavigate } from "react-router-dom";
import { getStudentInfo, getTeacherInfo } from "utils/userProfile";
import CommonModal from "views/components/CommonModal";
import styles from "./TransferPage.module.less";
import SetName from "views/welcome/SetName";
import { getUserProfile } from "api/profile";
import { debounce } from "lodash";
import { WebSocketSingleton } from "utils/logTool";

function TransferPage({ visible, setVisible }) {
  const navigate = useNavigate();
  const loadingRef = useRef(null);
  const [initResult, setInitResult] = useState(null);
  const [isFirst, setIsFirst] = useState(false);
  const teacherInfo = getTeacherInfo();

  const character_id = teacherInfo.character_id;
  function close() {
    setVisible(false);
    localStorage.removeItem("teacher_info");
    localStorage.removeItem("courseware_name");
    localStorage.removeItem("material_id");
    localStorage.removeItem("data_json_path");
  }
  const Qualification = debounce(async () => {
    loadingRef.current = Toast.loading({
      message: "正在进入教室...",
      forbidClick: true,
      duration: 0
    });
    WebSocketSingleton.getInstance().send(
      `上课调用startLesson：material_id:${localStorage.getItem(
        "data_json_path"
      )}、character_id:${character_id}`
    );
    try {
      await getUserProfile();
      let { data, message } = await getMyQualification({
        material_id: localStorage.getItem("data_json_path"),
        character_id
      });
      loadingRef.current.clear();
      const studentInfo = getStudentInfo();
      const age = studentInfo.birthday;
      const phone_number = studentInfo.phone_number;
      const member_id = studentInfo.member_id;
      if (data && typeof data === "object" && Object.keys(data).length > 0) {
        const language = localStorage.getItem("language") || "remote";
        const args = {
          url:
            `${process.env.REACT_APP_API_HTTP}://${
              process.env.REACT_APP_API_WEBSOCKET
            }?tts=elevenlabs&student_name=${
              studentInfo.english_name || "Emily"
            }&material=${
              data.material_id
            }&character_id=${character_id}&llm_model=gpt-4` +
            `&lesson_id=${data.lesson_id}` +
            `&token=${data.token}` +
            `&age=${calculateAge(age)}` +
            `&mobile=${phone_number}` +
            `&member_id=${member_id}`,
          lesson_id: data.lesson_id,
          language: language,
          age: calculateAge(age),
          teaAvatar: teacherInfo.image_url,
          teaName: teacherInfo.name,
          stuAvatar: studentInfo.profile_image
            ? `${process.env.REACT_APP_API_CDN}` +
              `${studentInfo.profile_image[0] === "/" ? "" : "/"}` +
              studentInfo.profile_image
            : "",
          stuName: studentInfo.english_name || "Emily",
          coursewareTitle: localStorage.getItem("courseware_name"),
          debugInfo: "false"
        };
        console.log(args, "发送的args");
        WebSocketSingleton.getInstance().send(
          `接口返回参数：${JSON.stringify(
            data
          )},上课发送的参数：${JSON.stringify(args)}`
        );
        DSBridge.call("startLesson", args, (value) => {
          console.log(value, "返回的value");
          WebSocketSingleton.getInstance().send(`调用startLesson后进入回调`);
        });
        WebSocketSingleton.getInstance().send(`准备关闭弹窗并返回我的页面`);
        close();
        navigate("/main/mine");
        WebSocketSingleton.getInstance().send(`返回我的页面成功`);
      } else {
        Toast.info({
          message: message || "请重试！",
          duration: 4000
        });
        WebSocketSingleton.getInstance().send(
          `startLesson返回数据错误：${String(message)}}`
        );
        setVisible(false);
      }
    } catch (e) {
      loadingRef.current.clear();
      setVisible(false);
      WebSocketSingleton.getInstance().send(`上课catch流程错误：${String(e)}`);
      console.log(e);
    }
  }, 250);

  const initData = async () => {
    const material_id = localStorage.getItem("material_id") || "";
    const material_path = localStorage.getItem("data_json_path") || "";
    const postForm = new FormData();
    postForm.append("material_id", material_id);
    postForm.append("material_path", material_path);
    loadingRef.current = Toast.loading({
      message: "正在获取课程信息...",
      forbidClick: true,
      duration: 0
    });
    try {
      const res = await getPreLesson(postForm);
      loadingRef.current.clear();
      if (res.code === 200) {
        if (res.data?.msg && res.data?.code) {
          // if (res.data.code === 202) {
          //   Qualification();
          // } else {
          setInitResult(res.data);
          // }
        } else {
          Toast.info({
            message: "获取课程信息错误，请重试...",
            duration: 4000
          });
          setVisible(false);
        }
      } else {
        setVisible(false);
      }
    } catch (e) {
      loadingRef.current.clear();
      console.log(e);
      setVisible(false);
    }
  };
  const getPopResult = () => {
    if (initResult.code === 404) {
      return (
        <div className={styles["fail-pop-wrapper"]}>
          <div className={styles["fail-pop-wrapper-title"]}>温馨提示</div>
          <div className={styles["fail-pop-wrapper-text"]}>
            {initResult?.msg || ""}
          </div>
          <div
            className={styles["fail-pop-wrapper-confirm"]}
            onClick={() => {
              setVisible(false);
            }}
          >
            确认
          </div>
        </div>
      );
    } else {
      return (
        <div className={styles["success-pop-wrapper"]}>
          <div className={styles["success-pop-wrapper-title"]}>进入课程</div>
          <div className={styles["success-pop-wrapper-text"]}>
            {initResult?.msg || ""}
          </div>
          <div className={styles["success-pop-wrapper-button"]}>
            <div
              className={`${styles["button-item"]} ${styles["button-cancel"]}`}
              onClick={() => {
                setVisible(false);
              }}
            >
              返回再看看
            </div>
            <div
              className={`${styles["button-item"]} ${styles["button-confirm"]}`}
              onClick={Qualification}
            >
              确认
            </div>
          </div>
        </div>
      );
    }
  };
  useEffect(() => {
    if (visible) {
      let userInfo = {};
      try {
        userInfo = JSON.parse(localStorage.getItem("userInfo"));
      } catch {
        console.log("用户信息解析失败");
      }
      if (!userInfo?.english_name) {
        setIsFirst(true);
      } else {
        setIsFirst(false);
      }
      initData();
    } else {
      setInitResult(null);
    }
  }, [visible]);
  return (
    <>
      {initResult ? (
        isFirst && initResult.code !== 404 ? (
          <SetName
            navigate={navigate}
            setVisible={setVisible}
            visible={visible}
          />
        ) : (
          <CommonModal isOpen={visible}>
            <div className={styles["pop-container"]}>
              {getPopResult()}
              <img
                onClick={() => {
                  setVisible(false);
                }}
                className={styles["modal-close"]}
                src={require("@/images/mine/recommend-officer/button_cancel.png")}
              />
            </div>
          </CommonModal>
        )
      ) : null}
    </>
  );
}
export default TransferPage;
