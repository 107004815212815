import { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Info.module.less";
import LoginTab from "../components/LoginTab";
import CommonButton from "../../components/CommonButton";
import { DatetimePicker, Picker, Toast } from "react-vant";
import { formatDateToYYYYMMDD } from "utils/date";
import { uploadFile } from "api/upload";
import { getUserProfile, updateUserProfile } from "api/profile";

const initTabs = [
  {
    key: "info",
    value: "填写个人信息"
  }
];
const columns = ["男", "女"];
function Info() {
  const navigate = useNavigate();
  const infoRef = useRef(null);
  const [userData, setUserData] = useState({});
  const infoLoadingRef = useRef(null);
  const handleAvatarChange = async (e) => {
    const file = e.target.files[0];
    const maxSize = 10 * 1024 * 1024;
    if (file.size > maxSize) {
      return Toast.fail("请选择小于10MB头像文件上传");
    }
    try {
      infoLoadingRef.current = Toast.loading({
        message: "上传头像中...",
        forbidClick: true,
        duration: 0
      });
      const avatarRes = await uploadFile(file);
      infoLoadingRef.current.clear();
      if (avatarRes && avatarRes.key) {
        setUserData(Object.assign(userData, { profile_image: avatarRes.key }));
      }
    } catch (e) {
      console.log(e);
    }
  };
  const handleConfirm = async () => {
    const res = await updateUserProfile(userData);
    if (res.code === 200) {
      Toast.info(res.message);
      navigate(-1);
    }
  };
  useEffect(() => {
    infoLoadingRef.current = Toast.loading({
      message: "正在获取个人信息中...",
      forbidClick: true,
      duration: 0
    });
    getUserProfile()
      .then((res) => {
        if (res.code === 200) {
          setUserData(res.data);
        }
      })
      .finally(() => {
        infoLoadingRef.current.clear();
      });
  }, []);
  return (
    <div className={styles["info-container"]} ref={infoRef}>
      <img
        className={styles["common-back"]}
        onClick={() => {
          navigate(-1);
        }}
        src={require("@/images/login/ic_back.png")}
      />
      <img
        className={styles["right-banner"]}
        src={require("@/images/login/right-banner.png")}
      />
      <LoginTab className={styles["login-tab"]} dataList={initTabs} />
      <div className={styles["info-avatar"]}>
        <div className={styles["info-avatar-content"]}>
          <img
            className={styles["content-img"]}
            src={
              userData.profile_image
                ? `${process.env.REACT_APP_API_CDN}/${userData.profile_image}`
                : require("@/images/login/ic_avatar.png")
            }
          />
          <input
            accept="image/*"
            className={styles["info-avatar-input"]}
            onChange={handleAvatarChange}
            type="file"
          />
          <span className={styles["content-text"]}>点击上传头像</span>
        </div>
        <img
          className={styles["info-avatar-arrow"]}
          src={require("@/images/login/ic_right_arrow.png")}
        />
      </div>
      <div className={styles["info-common-cell"]}>
        <div className={styles["cell-content"]}>
          <img
            className={styles["cell-content-icon"]}
            src={require("@/images/login/ic_name.png")}
          />
          <input
            className={styles["cell-content-input"]}
            placeholder="英文名"
            type="text"
          />
        </div>
      </div>
      <Picker
        columns={columns}
        onConfirm={(v) => {
          setUserData(Object.assign(userData, { gender: v }));
        }}
        popup={{
          round: true,
          teleport: infoRef.current
        }}
        title="选择性别"
        value={userData.gender || ""}
      >
        {(val, _, actions) => {
          return (
            <div
              className={styles["info-common-cell"]}
              onClick={() => actions.open()}
            >
              <div className={styles["cell-content"]}>
                <img
                  className={styles["cell-content-icon"]}
                  src={require(
                    `@/images/login/ic_${
                      userData.gender === "男"
                        ? "gender_man"
                        : userData.gender === "女"
                        ? "gender_woman"
                        : "gender"
                    }.png`
                  )}
                />
                <span className={styles["cell-content-input"]}>
                  {userData.gender || "选择性别"}
                </span>
              </div>
              <img
                className={styles["cell-arrow"]}
                src={require("@/images/login/ic_right_arrow.png")}
              />
            </div>
          );
        }}
      </Picker>
      <DatetimePicker
        maxDate={new Date()}
        minDate={new Date(1900, 0, 1)}
        onConfirm={(v) => {
          setUserData(
            Object.assign(userData, { birthday: formatDateToYYYYMMDD(v) })
          );
        }}
        popup={{
          round: true,
          teleport: infoRef.current
        }}
        title="选择年月日"
        type="date"
        value={userData.birthday || ""}
      >
        {(val, _, actions) => {
          return (
            <div
              className={styles["info-common-cell"]}
              onClick={() => actions.open()}
            >
              <div className={styles["cell-content"]}>
                <img
                  className={styles["cell-content-icon"]}
                  src={require("@/images/login/ic_date.png")}
                />
                <span className={styles["cell-content-input"]}>
                  {userData.birthday || "选择出生日期"}
                </span>
              </div>
              <img
                className={styles["cell-arrow"]}
                src={require("@/images/login/ic_right_arrow.png")}
              />
            </div>
          );
        }}
      </DatetimePicker>
      <CommonButton
        className={styles["login-submit"]}
        delay={500}
        onClick={handleConfirm}
        text="完成"
      />
    </div>
  );
}
export default Info;
