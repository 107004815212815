import {useLocation, useNavigate} from "react-router-dom";
import CommonNavBar from "views/components/CommonNavBar";
import styles from "./questContent.module.less";
import {Divider} from "react-vant";

function QuestContent() {
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const content = searchParams.get("content")
    const title = searchParams.get("title")
    const serviceUrl = searchParams.get("webUrl")
    console.log(serviceUrl);
    return (
        <div>
            <CommonNavBar title={"常见问题"}
                          onClickBack={() => {
                              navigate(-1);
                          }}
            />
            <div className={styles["content-title"]}>
                {title}
            </div>
            <Divider/>
            <div className={styles["content-container"]} dangerouslySetInnerHTML={{ __html: content }} />
            <Divider/>
            <div className={styles["bottom-container"]} onClick={() => {
                navigate(
                    `/webUrlView?title=${encodeURIComponent(
                        "口袋领航外教客服中心"
                    )}&webUrl=${encodeURIComponent(serviceUrl)}`
                );
            }}>
                没有解决问题？联系人工客服
            </div>
        </div>
    )
}

export default QuestContent;
