import { useNavigate } from "react-router-dom";
import styles from "./MyExchange.module.less";
import CommonNavBar from "views/components/CommonNavBar";
import { getExchangeRecord } from "api/points";
import { useEffect, useState, useRef } from "react";
import { Toast } from "react-vant";
import useScrollLoad from "utils/useScrollLoad";

const recordStatus = {
  已发货: "item-status-title--status1",
  已完成: "item-status-title--status2",
  待发货: "item-status-title--status3"
};
const loadingText = {
  nomore: "已经到底啦～",
  loading: "正在加载～",
  empty: "暂无数据"
};
function MyExchange() {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [dataList, setDataList] = useState([]);
  const LoadingRef = useRef(null);
  const handleClickGood = (item) => {
    navigate(
      `/mine/logisticsInformation?goods=${encodeURIComponent(
        JSON.stringify(item)
      )}`
    );
  };
  const initData = async () => {
    LoadingRef.current = Toast.loading({
      message: "正在加载数据...",
      forbidClick: true,
      duration: 0
    });
    setLoading(true);
    const res = await getExchangeRecord(currentPage);
    LoadingRef.current.clear();
    setLoading(false);
    if (res.code === 200) {
      setCurrentPage(currentPage + 1);
      setTotalCount(res.data.total_count);
      setDataList([...dataList, ...res.data.data]);
    }
  };
  const containerRef = useScrollLoad(() => {
    if (dataList.length < totalCount && !isLoading) {
      initData();
    }
  });
  useEffect(() => {
    initData();
  }, []);
  return (
    <div className={styles["my-exchange-container"]} ref={containerRef}>
      <CommonNavBar
        onClickBack={() => {
          navigate(-1);
        }}
        title="我的兑换"
      />
      <div className={styles["goods-list"]}>
        {dataList.map((item, index) => (
          <div
            className={styles["goods-list-item"]}
            key={`exchange-item-${index}`}
          >
            <div className={styles["item-content"]}>
              <img className={styles["item-content-left"]} src={item.thumb} />
              <div className={styles["item-content-right"]}>
                <div className={styles["right-title"]}>{item.goods_name}</div>
                <div className={styles["right-text"]}>
                  {`创建时间：${item.create_time}`}
                </div>
                <div className={styles["right-text"]}>
                  {`订单编号：${item.order_sn}`}
                </div>
              </div>
            </div>
            <div className={styles["item-status"]}>
              <div
                className={`${styles["item-status-title"]} ${
                  styles[recordStatus[item.status]]
                }`}
              >
                {item.status}
              </div>
              <div
                className={styles["item-status-button"]}
                onClick={() => handleClickGood(item)}
              >
                查看物流
              </div>
            </div>
          </div>
        ))}
        <div className={styles["goods-list-bottom"]}>
          {loading ? (
            loadingText["loading"]
          ) : dataList.length === 0 ? (
            <div className={styles["empty-container"]}>
              <img
                className={styles["empty-icon"]}
                src={require("../../../images/image/Ub.png")}
              />
              <div className={styles["empty-text"]}>
                哎呀，您还没有兑换记录呢！快快赚取更多积分，到积分商城兑换精美礼品吧！
              </div>
            </div>
          ) : (
            loadingText["nomore"]
          )}
        </div>
      </div>
    </div>
  );
}

export default MyExchange;
