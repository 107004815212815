import { useEffect, useRef, useState } from "react";
import "./FlipCard.less";
import Countdown from "./components/Countdown";
import DSBridge from "dsbridge";
export default function FlipCard() {
  const [startDisabled, setStartDisabled] = useState(false);
  const countRef = useRef(null);
  const cardRef = useRef(null);
  const [cardList, setCardList] = useState([]);
  const [score, setScore] = useState(0);
  const resultScoreRef = useRef(0);
  const [endShow, setEndShow] = useState(false);
  const [fingerShow, setFingerShow] = useState(true);
  const [cardShow, setCardShow] = useState(true);
  const exitRef = useRef(null);
  function handleClickCard(s) {
    if (!startDisabled) {
      setStartDisabled(true);
      setScore(s);
      resultScoreRef.current = s;
      countRef.current.handleStop();
      setCardShow(false);
    }
  }
  function shuffle(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }
  async function init() {
    setStartDisabled(false);
    setScore(0);
    resultScoreRef.current = 0;
    setEndShow(false);
    setFingerShow(true);
    setCardShow(true);
    countRef.current.handleStart(15);
    const list = shuffle([4, 6, 8]);
    setCardList(list);
    DSBridge.call("playAudio", "flip.mp3");
  }
  function handleCountChange(count) {
    if (count === 0) {
      setStartDisabled(true);
      exitRef.current = setTimeout(() => {
        DSBridge.call("stopAudio", "flip.mp3");
        DSBridge.call("exitWebView");
      }, 1000);
      console.log("倒计时未点击，跳出");
    }
  }
  useEffect(() => {
    init();
    function handleAnimationEnd(e) {
      const classNames = e.target.className.split(" ");
      if (classNames.includes("card-list--hide")) {
        setEndShow(true);
        DSBridge.call("playAudio", "victory.mp3");
        exitRef.current = setTimeout(() => {
          DSBridge.call("addScore", String(resultScoreRef.current));
          DSBridge.call("stopAudio", "flip.mp3");
          DSBridge.call("stopAudio", "victory.mp3");
          DSBridge.call("exitWebView");
        }, 1000);
        console.log(`最后得分：${resultScoreRef.current}`);
      }
      if (classNames.includes("finger--hide")) {
        setFingerShow(false);
        console.log("手指动画结束");
      }
    }
    cardRef.current = document.querySelector(".card-list");
    if (cardRef.current) {
      cardRef.current.addEventListener("animationend", handleAnimationEnd);
      cardRef.current.addEventListener(
        "webkitAnimationEnd",
        handleAnimationEnd
      );
      cardRef.current.addEventListener("transitionend", handleAnimationEnd);
      cardRef.current.addEventListener(
        "webkitTransitionEnd",
        handleAnimationEnd
      );
    }
    return () => {
      if (cardRef.current) {
        cardRef.current.removeEventListener("animationend", handleAnimationEnd);
        cardRef.current.removeEventListener(
          "webkitAnimationEnd",
          handleAnimationEnd
        );
        cardRef.current.removeEventListener(
          "transitionend",
          handleAnimationEnd
        );
        cardRef.current.removeEventListener(
          "webkitTransitionEnd",
          handleAnimationEnd
        );
      }
      if (exitRef.current) {
        clearTimeout(exitRef.current);
      }
    };
  }, []);
  return (
    <div className="flip-container">
      {/*<img className="bg-top" src={require("@/images/game/flip/bg-top.png")} />*/}
      {/*<img className="bg-bottom" src={require("@/images/game/flip/bg-bottom.png")}/>*/}
      <div className="bg-top"></div>
      <div className="bg-bottom"></div>
      <Countdown handleCountChange={handleCountChange} ref={countRef} />
      <div className="top-title">
        <div className="top-title-text">Click The Lucky Card!</div>
      </div>
      {!endShow ? (
        <div className={`card-list${!cardShow ? " card-list--hide" : ""}`}>
          {cardList.map((item, index) => (
            <div
              className={`card-list-item${
                item === score ? " card-list-item--flip" : ""
              }`}
              key={`flipcard-${index}`}
              onClick={() => handleClickCard(item)}
            >
              <img
                className="common-card front"
                src={require("@/images/game/flip/card-front.png")}
              />
              <div className="common-card back">
                <img
                  className="back-score"
                  src={require(`@/images/game/flip/score-${item}.png`)}
                />
              </div>
            </div>
          ))}
          {fingerShow ? (
            <img
              className={`finger${startDisabled ? " finger--hide" : ""}`}
              src={require("@/images/game/flip/finger.png")}
            />
          ) : null}
        </div>
      ) : (
        <img
          className="end-bg"
          src={require(`@/images/game/flip/score-${score}.png`)}
        />
      )}
    </div>
  );
}
