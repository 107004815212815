import "./App.less";
import { HashRouter, Route, Routes, Navigate } from "react-router-dom";
import Navigation from "./views/study/Navigation.jsx";
import WebUrlView from "views/study/setting/WebUrlView";
import NotFoundPage from "views/NotFoundPage";
import Mall from "views/mall/Mall";
import Game from "views/game/Game";
import StudyReport from "views/study/report/StudyReport";
import StudyReportIframe from "views/study/report/StudyReportIframe";
import Pay from "@/views/study/pay/Pay";
import Welcome from "views/welcome/Welcome";
import DSBridge from "dsbridge";
import Invite from '@/views/invite';
import JoinSuccess from "views/invite/joinSuccess";

import Login from "views/login/Login";
import MineManage from "views/mine/MineManage";
import Mine from "views/study/Mine";
import MemberPay from "views/member-pay/MemberPay";
import DouyinExchange from "views/douyin-exchange/douyinExchange";
import DouyinExchangeFixed from "views/douyin-exchange/douyinExchangeFixed";
import DouyinWeixin from "views/douyin-weixin/DouyinWeixin";
import IntroTest from "views/intro-test/IntroTest";
import TextbookManage from "views/textbook/TextbookManage";
import RecommendedTextbooks from "views/study/recommended-textbooks/RecommendedTextbooks";
import { WebSocketSingleton } from "utils/logTool";
function App() {
  try {
    WebSocketSingleton.getInstance();
  } catch (e) {
    console.log(e);
  }
  window.addEventListener('popstate', function (e) {
    if (e.state && e.state.idx === 0) {
      if (DSBridge.hasNativeMethod("route.finishApp")) {
        DSBridge.call("route.finishApp");
      }
    }
  });
  try {
    if (DSBridge.hasNativeMethod("auth.updateUserProfile")) {
      let updateUserInfo = ""
      if (localStorage.getItem("userInfo")) {
        updateUserInfo = JSON.parse(localStorage.getItem("userInfo"))
        updateUserInfo["token"] = localStorage.getItem("access_token");
      }
      DSBridge.call("auth.updateUserProfile", JSON.stringify(updateUserInfo));
    }
  } catch (e) {
    console.log(e);
  }
  return (
    <>
      <HashRouter basename="/">
        <Routes>
          {/* 二级路由 */}
          <Route element={<Navigate to="/main/home" />} path="/" />
          <Route element={<Navigation />} path="/main/*" />
          <Route element={<Mall />} path="/mall/*" />
          <Route element={<Game />} path="/game/*" />
          <Route element={<Login />} path="/login/*" />
          <Route element={<MineManage />} path="/mine/*" />
          {/* 一级路由 */}
          <Route element={<WebUrlView />} path="/webUrlView" />
          <Route element={<NotFoundPage />} path="*" />
          <Route element={<StudyReport />} path="/studyReport" />
          <Route element={<StudyReportIframe />} path="/studyReportIframe" />
          <Route element={<Pay />} path="/pay" />
          <Route element={<Welcome />} path="/welcome" />
          <Route element={<Mine />} path="/mine" />
          <Route element={<Invite />} path="/invite" />
          <Route element={<JoinSuccess />} path="/joinSuccess" />
          <Route element={<MemberPay />} path="memberPay" />
          <Route element={<DouyinExchange />} path="douyinExchange" />
          <Route element={<DouyinExchangeFixed />} path="douyinExchangeFixed" />
          <Route element={<DouyinWeixin />} path="douyinWeixin" />
          <Route element={<IntroTest />} path="introTest" />
          <Route element={<TextbookManage />} path="/textbook/*" />
          <Route element={<DouyinExchangeFixed />} path="douyinExchangeFixed" />
          <Route element={<RecommendedTextbooks />} path="recommendedTextbooks" />
        </Routes>
      </HashRouter>
    </>
  );
}

export default App;
