export function formatDateToYYYYMMDD(date) {
  if (typeof date === "string") {
    return date;
  }
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return `${year}-${month}-${day}`;
}

export function calculateAge(birthdate) {
  if (!birthdate) {
    return 0;
  }
  const birthDate = new Date(birthdate);
  const currentDate = new Date();
  let age = currentDate.getFullYear() - birthDate.getFullYear();

  // 检查是否已经过了生日
  if (
    currentDate.getMonth() < birthDate.getMonth() ||
    (currentDate.getMonth() === birthDate.getMonth() &&
      currentDate.getDate() < birthDate.getDate())
  ) {
    age--;
  }
  if (!age) {
    return 0;
  }
  return age;
}
