import { useEffect, useRef, useState } from "react";
import { Toast } from "react-vant";
import styles from "./CodeDown.module.less";
import { sendVerificationCode } from "api/register";

function CodeDown({ getPhone }) {
  const [codeText, setCodeText] = useState("发送验证码");
  const countDownRef = useRef(null);
  const codeToastRef = useRef(null);
  const handleGetCode = async () => {
    const phone_number = getPhone();
    if (codeText === "发送验证码" && !countDownRef.current) {
      if (!phone_number) {
        return Toast.info("请输入手机号码");
      }
      if (!/^\d{11}$/.test(phone_number)) {
        return Toast.info("请输入正确的手机号码");
      }
      codeToastRef.current = Toast.loading({
        message: "正在发送验证码中...",
        forbidClick: true,
        duration: 0
      });
      const result = await sendVerificationCode({ phone_number });
      codeToastRef.current.clear();
      if (result && result.status === true) {
        let time = 60;
        setCodeText(`再次发送(${time}s)`);
        countDownRef.current = setInterval(() => {
          --time;
          if (time > 0) {
            setCodeText(`再次发送(${time}s)`);
          } else {
            clearInterval(countDownRef.current);
            countDownRef.current = null;
            setCodeText("发送验证码");
          }
        }, 1000);
      } else {
        Toast.info(result.message);
      }
    }
  };
  useEffect(() => {
    return () => {
      clearInterval(countDownRef.current);
      countDownRef.current = null;
    };
  }, []);
  return (
    <div className={styles["code-wrapper"]} onClick={handleGetCode}>
      {codeText}
    </div>
  );
}
export default CodeDown;
