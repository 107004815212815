import { useEffect, useState } from "react";
import "./GameStart.less";
import DSBridge from "dsbridge";
function GameStart() {
  useEffect(() => {
    DSBridge.call("playAudio", "gamestart.mp3");
    function handleAnimationEnd(e) {
      const classNames = e.target.className.split(" ");
      if (classNames.includes("top-text")) {
        const gameRoutes = [
          "/game/dice",
          "/game/dollmachine",
          "/game/turntable",
          "/game/ssc",
          "/game/flipcard"
        ];
        let gameList = localStorage.getItem("gameRandomList");
        try {
          gameList =
            gameList && JSON.parse(gameList).length > 0
              ? JSON.parse(gameList)
              : gameRoutes;
          const index = Math.floor(Math.random() * gameList.length);
          localStorage.setItem(
            "gameRandomList",
            JSON.stringify(gameList.filter((item) => item !== gameList[index]))
          );
          window.location.hash = gameList[index];
        } catch {
          localStorage.removeItem("gameRandomList");
          window.location.hash = gameRoutes[Math.floor(Math.random() * 5)];
        }
        console.log("结束跳转");
      }
    }
    const gameDom = document.querySelector(".game-start-container");
    gameDom.addEventListener("animationend", handleAnimationEnd);
    gameDom.addEventListener("webkitAnimationEnd", handleAnimationEnd);
    return () => {
      if (gameDom) {
        gameDom.addEventListener("animationend", handleAnimationEnd);
        gameDom.addEventListener("webkitAnimationEnd", handleAnimationEnd);
      }
      DSBridge.call("stopAudio", "gamestart.mp3");
    };
  }, []);
  return (
    <div className="game-start-container">
      <div className="top-text">GAME STARTS</div>
      <div className="center-text">
        <div className="center-text-wave">
          <span>游</span>
          <span>戏</span>
          <span>开</span>
          <span>始</span>
          <span>了</span>
          <span>！</span>
        </div>
      </div>
      {/* <img
        className="balloon-1"
        src={require("@/images/game/gamestart/balloon-1.png")}
      />
      <img
        className="balloon-2"
        src={require("@/images/game/gamestart/balloon-2.png")}
      />
      <img
        className="balloon-3"
        src={require("@/images/game/gamestart/balloon-3.png")}
      /> */}
    </div>
  );
}
export default GameStart;
