import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styles from "./ExportPreview.module.less";
import { Toast } from "react-vant";
import { exportLogs } from "@/api/wordCollection";
import DSBridge from "dsbridge";
import CommonButton from "views/components/CommonButton";
import CommonNavBar from "views/components/CommonNavBar";
function ExportPreview() {
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(useLocation().search);
  const [downData, setDownData] = useState({});
  function handleExportPDF() {
    DSBridge.call(
      "share.shareInfo",
      {
        type: "system",
        title: "pdf文件",
        text: "文件预览分享",
        mineType: "application/pdf",
        url: downData.dpf
      },
      (back) => {
        console.log(back);
      }
    );
  }
  useEffect(() => {
    Toast.loading({
      message: "预览加载中...",
      forbidClick: true,
      duration: 0
    });
    exportLogs({ ids: JSON.parse(decodeURIComponent(searchParams.get("ids"))) })
      .then((res) => {
        if (res.code === 200) {
          setDownData(res.data);
        }
      })
      .finally(() => {
        Toast.clear();
      });
  }, []);
  return (
    <div className={styles["export-preview-container"]}>
      <CommonNavBar
        onClickBack={() => {
          navigate(-1);
        }}
        title="导出预览"
      />
      {downData.pic ? (
        <>
          <img className={styles["export-pic"]} src={downData.pic} />
          <CommonButton
            className={styles["export-pdf"]}
            delay={500}
            onClick={handleExportPDF}
            text="生成生词本PDF"
          />
        </>
      ) : null}
    </div>
  );
}
export default ExportPreview;
