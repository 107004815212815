import { useEffect, useRef, useState } from "react";
import "./Dice.less";
import Countdown from "./components/Countdown";
import DSBridge from "dsbridge";
export default function Dice() {
  const [startDisabled, setStartDisabled] = useState(false);
  const [diceNum, setDiceNum] = useState(null);
  const countRef = useRef(null);
  const diceRef = useRef(null);
  const score = useRef(0);
  const [endShow, setEndShow] = useState(false);
  const exitRef = useRef(null);
  function handleCountChange(count) {
    if (count === 0) {
      setStartDisabled(true);
      exitRef.current = setTimeout(() => {
        DSBridge.call("stopAudio", "dice.mp3");
        DSBridge.call("exitWebView");
      }, 1000);
      console.log("倒计时未点击，跳出");
    }
  }
  async function handleClickStart() {
    if (!startDisabled) {
      setStartDisabled(true);
      countRef.current.handleStop();
      const randomIndex = String(Math.floor(Math.random() * 6) + 1);
      setDiceNum(randomIndex);
      score.current = randomIndex * 2;
    }
  }
  async function init() {
    setStartDisabled(false);
    setDiceNum(null);
    score.current = 0;
    setEndShow(false);
    countRef.current.handleStart(15);
    DSBridge.call("playAudio", "dice.mp3");
  }
  useEffect(() => {
    init();
    function handleAnimationEnd(e) {
      const classNames = e.target.className.split(" ");
      if (classNames.includes("dice-img") && e.animationName === "turn") {
        setEndShow(true);
        DSBridge.call("playAudio", "victory.mp3");
      }
      if (
        classNames.includes("ubao-img--fade-in") &&
        e.propertyName === "opacity"
      ) {
        exitRef.current = setTimeout(() => {
          DSBridge.call("addScore", String(score.current));
          DSBridge.call("stopAudio", "dice.mp3");
          DSBridge.call("stopAudio", "victory.mp3");
          DSBridge.call("exitWebView");
        }, 1000);
        console.log("动画结束，加分：" + score.current);
      }
    }
    diceRef.current = document.querySelector(".dice-container");
    if (diceRef.current) {
      diceRef.current.addEventListener("animationend", handleAnimationEnd);
      diceRef.current.addEventListener(
        "webkitAnimationEnd",
        handleAnimationEnd
      );
      diceRef.current.addEventListener("transitionend", handleAnimationEnd);
      diceRef.current.addEventListener(
        "webkitTransitionEnd",
        handleAnimationEnd
      );
    }
    return () => {
      if (diceRef.current) {
        diceRef.current.removeEventListener("animationend", handleAnimationEnd);
        diceRef.current.removeEventListener(
          "webkitAnimationEnd",
          handleAnimationEnd
        );
        diceRef.current.removeEventListener(
          "transitionend",
          handleAnimationEnd
        );
        diceRef.current.removeEventListener(
          "webkitTransitionEnd",
          handleAnimationEnd
        );
      }
      if (exitRef.current) {
        clearTimeout(exitRef.current);
      }
    };
  }, []);
  return (
      <div className="dice-container">
        {/*<img className="bg-top" src={require("@/images/game/dice/bg-top.png")} />*/}
        {/*<img className="bg-bottom" src={require("@/images/game/dice/bg-bottom.png")}/>*/}
        <div className="bg-top"></div>
        <div className="bg-bottom"></div>
        <Countdown handleCountChange={handleCountChange} ref={countRef}/>
        <img
            className="top-card"
            src={require("@/images/game/dice/top-card.png")}
        />
        <div
            className={`dice-img${diceNum ? " dice-img--turn" : ""}${
                endShow ? " dice-img--fade-out" : ""
            }`}
            style={
              endShow
                  ? {
                    backgroundImage: `url(${require(
                        "@/images/game/dice/dice-" + diceNum + ".png"
                    )})`
                  }
                  : null
            }
        ></div>
        <img
            className={`ubao-img${endShow ? " ubao-img--fade-in" : ""}`}
            src={
              endShow ? require(`@/images/game/dice/score-${diceNum * 2}.png`) : ""
            }
        />
        <div
            className={`start-button${
                startDisabled ? " start-button--disabled" : ""
            }`}
            onClick={handleClickStart}
        ></div>
      </div>
  );
}
