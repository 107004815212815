import { useNavigate } from "react-router-dom";
import CommonNavBar from "views/components/CommonNavBar";
import CommonButton from "../../components/CommonButton";
import styles from "./MailingAddress.module.less";
import { useEffect, useRef, useState } from "react";
import { Picker, Toast, Dialog } from "react-vant";
import {
  getAddress,
  getAddressList,
  addressAdd,
  addressEdit,
  addressDel
} from "api/points";
const status = {
  create: "添加收货地址",
  update: "编辑收货地址",
  read: "我的收货地址"
};
function MailingAddress() {
  const navigate = useNavigate();
  const loadingToastRef = useRef(null);
  const [navStatus, setNavStatus] = useState("read");
  const [addressList, setAddressList] = useState([]);
  const [areaLoading, setAreaLoading] = useState(false);
  const [columns, setColumns] = useState([]);
  const [areaString, setAreaString] = useState("");
  const [addressForm, setAddressForm] = useState({});
  const findAddressChildren = (id, data) => {
    for (const item of data) {
      if (item.value === id) {
        return item;
      }
      if (item.children) {
        const result = findAddressChildren(id, item.children);
        if (result) return result;
      }
    }
    return undefined;
  };
  const initData = async () => {
    loadingToastRef.current = Toast.loading({
      message: "正在获取收货地址...",
      forbidClick: true,
      duration: 0
    });
    const res = await getAddressList();
    loadingToastRef.current.clear();
    if (res.code === 200) {
      setAddressList(res.data);
    }
  };
  const handleGetAddress = async (id) => {
    setAreaLoading(true);
    const res = await getAddress(id || 0);
    setAreaLoading(false);
    if (res.code === 200) {
      res.data.forEach((item) => {
        item.value = item.id;
        delete item.id;
        item.text = item.name;
        delete item.name;
        item.children = [];
      });
      try {
        if (!id) {
          setColumns(res.data);
        } else {
          if (findAddressChildren(id, columns)) {
            findAddressChildren(id, columns).children = res.data;
          }
          setColumns([...columns]);
        }
      } catch {
        Toast.info("地区数据错误，请返回重试");
      }
    }
  };
  const handlePickChange = async (values) => {
    const copyValues = JSON.parse(
      JSON.stringify(
        values.filter((item) => item !== undefined && item !== null)
      )
    );
    if (copyValues.length === 0) {
      return;
    } else if (copyValues.length < 3) {
      handleGetAddress(copyValues[copyValues.length - 1]);
    }
  };
  const handlePickConfirm = (values) => {
    try {
      if (
        values.filter((item) => item !== undefined && item !== null).length > 0
      ) {
        const area = {
          province_id: values[0] || "",
          city_id: values[1] || "",
          area_id: values[2] || ""
        };
        setAddressForm({ ...addressForm, ...area });
        setAreaString(
          `${findAddressChildren(values[0], columns)?.text || ""}${
            findAddressChildren(values[1], columns)?.text || ""
          }${findAddressChildren(values[2], columns)?.text || ""}`
        );
      }
    } catch {
      Toast.info("地区数据错误，请返回重试");
    }
  };
  const handleClickOperate = async () => {
    if (navStatus === "read") {
      setAddressForm({
        consignee: "",
        mobile: "",
        address: "",
        province_id: "",
        city_id: "",
        area_id: ""
      });
      setAreaString("");
      setNavStatus("create");
    }
    if (navStatus === "create" || navStatus === "update") {
      const postFormData = new FormData();
      Object.keys(addressForm).forEach((key) => {
        postFormData.append(key, addressForm[key]);
      });
      loadingToastRef.current = Toast.loading({
        message: "正在保存收货地址...",
        forbidClick: true,
        duration: 0
      });
      const postApi = navStatus === "create" ? addressAdd : addressEdit;
      const addRes = await postApi(postFormData);
      loadingToastRef.current.clear();
      if (addRes.code === 200) {
        Toast.info(addRes.message);
        initData();
        setNavStatus("read");
      }
    }
  };
  const handleUpdate = (item) => {
    setAddressForm({
      address_id: item.address_id,
      consignee: item.consignee,
      mobile: item.mobile,
      address: item.address,
      province_id: item.province_id,
      city_id: item.city_id,
      area_id: item.area_id
    });
    setAreaString(`${item.province || ""}${item.city || ""}${item.area}`);
    setNavStatus("update");
  };
  const handleDelete = async (item) => {
    try {
      await Dialog.confirm({
        title: "删除收货地址",
        theme: "round-button",
        message: `是否删除姓名：${item.consignee}，手机号：${item.mobile}的收货地址？`,
        onConfirm: async () => {
          loadingToastRef.current = Toast.loading({
            message: "正在删除收货地址...",
            forbidClick: true,
            duration: 0
          });
          const delRes = await addressDel(item.address_id);
          loadingToastRef.current.clear();
          if (delRes.code === 200) {
            Toast.info(delRes.message);
            initData();
          }
        }
      });
    } catch {
      console.log("cancel");
    }
  };
  useEffect(() => {
    initData();
    handleGetAddress();
  }, []);
  return (
    <div className={styles["mailing-address-container"]}>
      <CommonNavBar
        backgroundColor="#fff"
        onClickBack={() => {
          if (navStatus === "read") {
            navigate(-1);
          } else {
            setNavStatus("read");
          }
        }}
        title={status[navStatus]}
      />
      {navStatus === "read" ? (
        <div className={styles["address-list"]}>
          {addressList.map((item, index) => (
            <div
              className={styles["address-list-item"]}
              key={`address-item-${index}`}
            >
              <div className={styles["item-top"]}>
                <div className={styles["item-top-name"]}>{item.consignee}</div>
                <div className={styles["item-top-phone"]}>{item.mobile}</div>
              </div>
              <div className={styles["item-center"]}>{`${item.province || ""}${
                item.city || ""
              }${item.area || ""}${item.address || ""} `}</div>
              <div className={styles["item-bottom"]}>
                <div
                  className={styles["item-bottom-update"]}
                  onClick={() => handleUpdate(item)}
                >
                  <img
                    className={styles["item-bottom-update-icon"]}
                    src={require("@/images/mine/ic_order_update.png")}
                  />
                  <span>编辑</span>
                </div>
                <div
                  className={styles["item-bottom-delete"]}
                  onClick={() => handleDelete(item)}
                >
                  <img
                    className={styles["item-bottom-update-icon"]}
                    src={require("@/images/mine/ic_order_delete.png")}
                  />
                  <span>删除</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className={styles["form-list"]}>
          <div className={styles["form-list-item"]}>
            <div className={styles["item-title"]}>收件人</div>
            <input
              className={styles["item-input"]}
              onChange={(e) => {
                addressForm.consignee = e.target.value;
                setAddressForm({ ...addressForm });
              }}
              placeholder="收件人姓名"
              type="text"
              value={addressForm.consignee}
            />
          </div>
          <div className={styles["form-list-item"]}>
            <div className={styles["item-title"]}>手机号</div>
            <input
              className={styles["item-input"]}
              onChange={(e) => {
                addressForm.mobile = e.target.value;
                setAddressForm({ ...addressForm });
              }}
              placeholder="收件人手机号"
              type="text"
              value={addressForm.mobile}
            />
          </div>
          <div className={styles["form-list-item"]}>
            <div className={styles["item-title"]}>所在地区</div>
            <Picker
              columns={columns}
              loading={areaLoading}
              onChange={handlePickChange}
              onConfirm={handlePickConfirm}
              popup={{
                round: true
              }}
              title="所在地区"
              value={addressForm.area}
            >
              {(val, _, actions) => {
                return (
                  <div
                    className={styles["item-select"]}
                    onClick={() => actions.open()}
                  >
                    {areaString ? (
                      <div className={styles["item-select-text"]}>
                        {areaString}
                      </div>
                    ) : (
                      <div className={styles["item-select-placeholder"]}>
                        点击选择省、市、区
                      </div>
                    )}

                    <img
                      className={styles["item-select-icon"]}
                      src={require("@/images/login/ic_right_arrow.png")}
                    />
                  </div>
                );
              }}
            </Picker>
          </div>
          <div className={styles["form-list-item"]}>
            <div className={styles["item-title"]}>详细地址</div>
            <textarea
              className={`${styles["item-input"]} ${styles["item-textarea"]}`}
              onChange={(e) => {
                addressForm.address = e.target.value;
                setAddressForm({ ...addressForm });
              }}
              placeholder="如街道、门牌号、小区、乡镇、村等"
              type="textarea"
              value={addressForm.address}
            />
          </div>
        </div>
      )}
      <div className={styles["operate-button"]}>
        <CommonButton
          delay={500}
          onClick={handleClickOperate}
          text={navStatus === "read" ? "新增收货地址" : "保存"}
        />
      </div>
    </div>
  );
}
export default MailingAddress;
