import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./OnStudy.module.less";
import { Skeleton, Toast } from "react-vant";
import { getLessonv3 } from "api/course";
import useScrollLoad from "utils/useScrollLoad";
import { debounce } from "lodash";
import TransferPage from "views/study/material/TransferPage";
const loadingText = {
  nomore: "已经到底啦～",
  loading: "正在加载～",
  empty: "暂无数据"
};
const OnStudy = () => {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [dataList, setDataList] = useState([]);
  const loadData = async () => {
    setIsLoading(true);
    const res = await getLessonv3(currentPage);
    setIsLoading(false);
    if (res.code === 200) {
      setTotalCount(res.data.total_count);
      setCurrentPage(currentPage + 1);
      if (Array.isArray(res.data?.data) && res.data?.data.length > 0) {
        setDataList([...dataList, ...res.data.data]);
      } else {
        setTotalCount(0);
      }
    }
  };
  const handleStartLesson = debounce(
    (lesson) => {
      if (!!!localStorage.getItem("access_token")) {
        Toast.info("当前操作需要登录!");
        navigate("/login/logon");
        return;
      }
      localStorage.setItem("data_json_path", lesson.data_json_path);
      localStorage.setItem("courseware_name", lesson.materials_title);
      localStorage.setItem("material_id", lesson.id);
      if (lesson.character_id) {
        localStorage.setItem(
          "teacher_info",
          JSON.stringify({
            character_id: lesson.character_id,
            image_url: lesson.teaAvatar || "",
            name: lesson.teaName || ""
          })
        );
        localStorage.setItem(
          "game_info",
          JSON.stringify({
            teacher_avatar: lesson.teaAvatar
          })
        );
        setVisible(true);
      } else {
        navigate("/main/learn/0");
        Toast.info("暂无外教信息，请选择任课外教");
      }
    },
    500,
    {
      leading: true,
      trailing: false
    }
  );
  const containerRef = useScrollLoad(() => {
    if (dataList.length < totalCount && !isLoading) {
      loadData();
    }
  });
  useEffect(() => {
    loadData();
  }, []);
  return (
    <div className={styles["onStudy-container"]} ref={containerRef}>
      {isLoading && dataList.length === 0 ? <Skeleton title /> : null}
      {!isLoading && dataList.length === 0 ? (
        <div className={styles["empty-block"]}>
          <div className={styles["empty-block-title"]}>
            {localStorage.getItem("access_token")
              ? "您还没有任何课程记录哦～"
              : "请登录以便获取更多信息"}
          </div>
          <div
            className={styles["empty-block-button"]}
            onClick={() => {
              if (localStorage.getItem("access_token")) {
                navigate("/main/textbook");
              } else {
                navigate("/login/logon");
              }
            }}
          >
            {localStorage.getItem("access_token") ? "立即选课" : "立即登录"}
          </div>
        </div>
      ) : null}
      {!isLoading && dataList.length > 0 ? (
        <>
          {dataList.map((item, index) => (
            <div className={styles["study-item"]} key={`onStudy-item-${index}`}>
              <div className={styles["study-item-top"]}>
                {item.lesson_id ? (
                  <span className={styles["study-item-top-id"]}>
                    {`ID：${item.lesson_id}`}
                  </span>
                ) : null}
                <span className={styles["study-item-top-time"]}>
                  {item.event_time || ""}
                </span>
              </div>
              <div className={styles["study-item-content"]}>
                <img className={styles["content-pic"]} src={item.cover} />
                <div className={styles["content-desc"]}>
                  <div className={styles["desc-series"]}>
                    {item.series_title || ""}
                  </div>
                  <div className={styles["desc-books"]}>
                    {item.books_title || ""}
                  </div>
                  <div className={styles["desc-grades"]}>
                    {item.grades_title || ""}
                  </div>
                  <div className={styles["desc-materials"]}>
                    {item.materials_title || ""}
                  </div>
                </div>
              </div>
              <div
                className={styles["study-item-button"]}
                onClick={() => handleStartLesson(item)}
              >
                继续学习
              </div>
            </div>
          ))}
          <div className={styles["bottom-text"]}>
            {isLoading && dataList.length === 0
              ? ""
              : isLoading
              ? loadingText["loading"]
              : dataList.length === 0
              ? loadingText["empty"]
              : loadingText["nomore"]}
          </div>
        </>
      ) : null}
      <TransferPage setVisible={setVisible} visible={visible} />
    </div>
  );
};

export default OnStudy;
