import { Tabbar } from "react-vant";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Home from "./Home.jsx";
import Textbook from "./Textbook.jsx";
import Learn from "./Learn.jsx";
import Course from "./Course.jsx";
import Mine from "./Mine.jsx";
import NotFoundPage from "views/NotFoundPage.jsx";
import { useEffect, useState } from "react";
import { createFromIconfontCN } from "@react-vant/icons";
import "./Navigation.less";

function Navigation() {
  const IconFont = createFromIconfontCN({
    scriptUrl: import("@/images/iconfont.js")
  });
  const navigate = useNavigate();
  const [bar, setBar] = useState(0);
  const location = useLocation();
  const selectIcon = (index) => {
    switch (index) {
      case 0:
        return (
          <IconFont
            className="icon"
            name={bar === 0 ? "icon-shouye-xz-blue-copy" : "icon-shouye-copy"}
          />
        );
      case 1:
        return (
          <IconFont
            className="icon"
            name={bar === 1 ? "icon-wendang1-blue-copy" : "icon-wendang1-copy"}
          />
        );
      case 2:
        return (
          <IconFont
            className="icon"
            name={bar === 2 ? "icon-diqiu-xz-blue-copy" : "icon-diqiu"}
          />
        );
      case 3:
        return (
          <IconFont
            className="icon"
            name={bar === 3 ? "icon-qianbao-xz-blue-copy" : "icon-qianbao-copy"}
          />
        );
      case 4:
        return (
          <IconFont
            className="icon"
            name={bar === 4 ? "icon-wode-xz-blue-copy" : "icon-wode-copy"}
          />
        );
    }
  };
  useEffect(() => {
    const routes = ["home", "textbook", "learn", "course", "mine"];
    const index = routes.findIndex((item) =>
      location.pathname.split("/").includes(item)
    );
    if (index !== -1) {
      setBar(index);
    }
  }, [location]);
  return (
    <>
      <Tabbar
        fixed
        onChange={(index) => {
          switch (index) {
            case 0:
              navigate("home");
              break;
            case 1:
              navigate("textbook");
              break;
            case 2:
              navigate("learn/0");
              break;
            case 3:
              navigate("course/0");
              break;
            case 4:
              if (!!!localStorage.getItem("access_token")) {
                navigate("/login/logon");
              } else {
                navigate("/main/mine");
              }
              break;
          }
          setBar(index);
        }}
        style={{ zIndex: 9 }}
        value={bar}
      >
        <Tabbar.Item className="font" icon={selectIcon(0)} name={0}>
          首页
        </Tabbar.Item>
        <Tabbar.Item className="font" icon={selectIcon(1)} name={1}>
          教材
        </Tabbar.Item>
        <Tabbar.Item className="font" icon={selectIcon(2)} name={2}>
          外教
        </Tabbar.Item>
        <Tabbar.Item className="font" icon={selectIcon(3)} name={3}>
          课程
        </Tabbar.Item>
        <Tabbar.Item className="font" icon={selectIcon(4)} name={4}>
          我的
        </Tabbar.Item>
      </Tabbar>
      <Routes>
        <Route element={<Home />} index path="home" />
        <Route element={<Textbook />} path="textbook" />
        <Route element={<Learn />} path="learn/:tab" />
        <Route element={<Course />} path="course/:index" />
        <Route element={<Mine />} path="mine" />
        <Route element={<NotFoundPage />} path="*" />
      </Routes>
    </>
  );
}

export default Navigation;
