import { useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styles from "./Find.module.less";
import LoginInput from "../components/LoginInput";
import LoginTab from "../components/LoginTab";
import CommonButton from "../../components/CommonButton";
import InputContainer from "../components/InputContainer";
import CommonNavBar from "views/components/CommonNavBar";
import CodeDown from "../components/CodeDown";
import { Toast } from "react-vant";
import { changePassword } from "api/register";
import { commonRules } from "../components/utils";

const initTabs = [
  {
    key: "find",
    value: "找回密码"
  }
];

const changeTabs = [
  {
    key: "change",
    value: "修改密码"
  }
];
function Find() {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const inputContainerRef = useRef(null);
  const handleFind = async (params) => {
    const res = await changePassword(params);
    if (res.code === 200) {
      Toast.info(res.message);
      localStorage.clear();
      navigate("/login/logon", { replace: true });
    }
  };
  const handleClick = async () => {
    try {
      const params = await inputContainerRef.current.getValues();
      if (params.repassword !== params.password) {
        return Toast.info("密码与确认密码不同");
      }
      handleFind(params);
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <div className={styles["find-container"]}>
      <CommonNavBar
        onClickBack={() => {
          navigate(-1);
        }}
      />
      <img
        className={styles["right-banner"]}
        src={require("@/images/login/right-banner.png")}
      />
      <img
        className={styles["top-banner"]}
        src={require("@/images/login/center-banner.png")}
      />
      <LoginTab
        className={styles["login-tab"]}
        dataList={queryParams.get("type") === "change" ? changeTabs : initTabs}
      />
      <InputContainer ref={inputContainerRef} rules={commonRules}>
        <LoginInput
          className={styles["login-input"]}
          field="phone_number"
          icon={require("@/images/login/ic_mobile.png")}
          inputMode="numeric"
          placeholderText="请输入手机号"
          selectedIcon={require("@/images/login/ic_mobile_selected.png")}
        />
        <LoginInput
          className={styles["login-input"]}
          field="verification_code"
          icon={require("@/images/login/ic_mail.png")}
          inputMode="numeric"
          placeholderText="请输入验证码"
          selectedIcon={require("@/images/login/ic_mail_selected.png")}
        >
          <CodeDown
            getPhone={() => {
              return inputContainerRef.current.getValue("phone_number");
            }}
          />
        </LoginInput>
        <LoginInput
          className={styles["login-input"]}
          field="password"
          icon={require("@/images/login/ic_pwd.png")}
          placeholderText="新密码"
          selectedIcon={require("@/images/login/ic_pwd_selected.png")}
          type="password"
        />
        <LoginInput
          className={styles["login-input"]}
          field="repassword"
          icon={require("@/images/login/ic_pwd.png")}
          placeholderText="确认密码"
          selectedIcon={require("@/images/login/ic_pwd_selected.png")}
          type="password"
        />
      </InputContainer>
      <CommonButton
        className={styles["login-submit"]}
        delay={500}
        onClick={handleClick}
        text="提交"
      />
    </div>
  );
}
export default Find;
