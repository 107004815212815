import request from "utils/request";

export async function getMaterials() {
  return request.get("/materials/getMaterials.php", "nocache");
}

export async function getFavoriteCourseware() {
  return request.get("/materials/favoriteCourseware.php", "nocache");
}

export async function setFavoriteCourseware(data) {
  return request.post("/materials/favoriteCourseware.php", data, "nocache");
}
