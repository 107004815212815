import { useLocation, useNavigate } from "react-router-dom";
import CommonNavBar from "views/components/CommonNavBar";
import styles from "./WebUrl.module.less";
function WebUrl() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  return (
    <div className={styles["web-url-container"]}>
      <CommonNavBar
        isTop
        title={
          searchParams.get("title")
            ? decodeURIComponent(searchParams.get("title"))
            : ""
        }
        onClickBack={() => {
          navigate(-1);
        }}
      />
      <iframe
        className={styles["web-url-iframe"]}
        src={
          searchParams.get("webUrl")
            ? decodeURIComponent(searchParams.get("webUrl"))
            : ""
        }
      />
    </div>
  );
}

export default WebUrl;
