import { useEffect, useRef } from 'react';

function useScrollLoad(onLoad, threshold = 100) {
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    const handleScroll = () => {
      if (!container) return;
      const scrollTop = container.scrollTop;
      const scrollHeight = container.scrollHeight;
      const clientHeight = container.clientHeight;
      if (scrollHeight - scrollTop - clientHeight <= threshold) {
        onLoad();
      }
    };
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [onLoad, threshold]);

  return containerRef;
}

export default useScrollLoad;
