export const commonRules = {
  // phone_number: (value, callback) => {
  //   if (!value) {
  //     return callback("请输入手机号码");
  //   }
  //   if (!/^\d{11}$/.test(value)) {
  //     return callback("请输入正确的手机号码");
  //   }
  //   return callback();
  // },
  password: (value, callback) => {
    if (!value) {
      return callback("请输入密码");
    }
    if (value.length < 6 || !/[0-9]/.test(value) || !/[a-zA-Z]/.test(value)) {
      return callback("密码必须大于等于6位，且包含字母和数字");
    }
    return callback();
  },
  repassword: (value, callback) => {
    if (!value) {
      return callback("请输入确认密码");
    }
    if (value.length < 6 || !/[0-9]/.test(value) || !/[a-zA-Z]/.test(value)) {
      return callback("确认密码必须大于等于6位，且包含字母和数字");
    }
    return callback();
  },
  verification_code: (value, callback) => {
    if (!/^\d{6}$/.test(value)) {
      return callback("验证码必须是6位数字");
    }
    if (!value) {
      return callback("请输入验证码");
    }
    return callback();
  }
};
export const handleRulesOfUseClick = (navigate, val) => {
  switch (val) {
    case "app":
      navigate(
        `/webUrlView?webUrl=${encodeURIComponent(
          `${process.env.REACT_APP_API_CDN}/utalkrc/privacy/en/app-permission.html`
        )}`
      );
      break;
    case "yszc":
      navigate(
        `/webUrlView?webUrl=${encodeURIComponent(
          `${process.env.REACT_APP_API_CDN}/utalkrc/privacy/en/app-privacy-policy.html`
        )}`
      );
      break;
    case "zxky":
      navigate(
        `/webUrlView?webUrl=${encodeURIComponent(
          `${process.env.REACT_APP_API_CDN}/utalkrc/privacy/en/app-service.html`
        )}`
      );
      break;
    case "AI":
      navigate(
        `/webUrlView?webUrl=${encodeURIComponent(
          `${process.env.REACT_APP_API_CDN}/utalkrc/privacy/en/platform-service.html`
        )}`
      );
      break;
  }
};