import { refreshTokenApi } from "api/register";
// const redirect = (uri) => {
//   const { protocol, hostname, port, pathname, hash } = window.location;
//   let url = protocol + "//" + hostname;
//   if (port) {
//     url += ":" + port;
//   }
//   if (pathname) {
//     url += pathname;
//   }
//   if (hash) {
//     url += "#";
//   }
//   return url + uri;
// };

export function navigateToSignIn(uri) {
  // if (!window.location.href.includes("register")) {
  //   localStorage.setItem("origin", window.location.href);
  // }
  if (!window.location.href.includes("logon")) {
    localStorage.setItem("origin", window.location.href);
  }
  window.location.replace(uri);
  // window.location.href = redirect(uri);
}

export function isTokenExpired() {
  const expirationTime = localStorage.getItem("token_expiration");
  if (!expirationTime) {
    return true;
  }
  const currentTime = new Date().getTime();
  return currentTime >= expirationTime;
}

export async function refreshToken() {
  return new Promise((resolve, reject) => {
    try {
      const refreshToken = localStorage.getItem("refresh_token");
      if (!refreshToken) {
        reject("无刷新Token");
      }
      refreshTokenApi({
        refresh_token: refreshToken
      }).then(res => {
        if (res.code === 200) {
          const newAccessToken = res.data.access_token;
          const expirationTime = new Date().getTime() + 60 * 60 * 1000;
          localStorage.setItem("token_expiration", expirationTime);
          localStorage.setItem("access_token", newAccessToken);
          localStorage.setItem("refresh_token", res.data.refresh_token);
          resolve(newAccessToken);
        } else {
          reject(res.message);
        }
      });
    } catch (error) {
      reject();
    }
  });
}
