import { useEffect, useState } from "react";
import styles from "./ProportionIcon.module.less";

const ProportionIcon = ({ percent = 0 }) => {
  const [text, setText] = useState(0);
  const toPercentage = (value) => {
    if (typeof value !== "number" || isNaN(value)) {
      return 0;
    }
    let percentage = (value * 100).toFixed(2);
    if (value > 0 && value < 0.0001) {
      percentage = 0.01;
    }
    if (percentage.split(".")[1] === "00") {
      percentage = percentage.split(".")[0];
    }
    return percentage;
  };
  const getBack = () => {
    if (text === 0) {
      return {
        background: `conic-gradient(#E8E8E8 0% 100%, #1955ff 100% 100%)`
      };
    } else {
      return {
        background: `conic-gradient(#E8E8E8 0% ${
          100 - Number(text)
        }%, #1955ff ${100 - Number(text)}% 100%)`
      };
    }
  };
  useEffect(() => {
    setText(toPercentage(percent));
  }, [percent]);
  return (
    <div className={styles["pro-icon-container"]}>
      <div className={styles["round"]} style={getBack()}></div>
      <div className={styles["center"]}>{`${text}%`}</div>
    </div>
  );
};

export default ProportionIcon;
