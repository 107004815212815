import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { List, Cell, NavBar, Space, Skeleton } from "react-vant";
import "./LessonState.less";
import { getLessonStateList } from "api/product";

function LessonState() {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [list, setlist] = useState([]);
  const [flag, setFlag] = useState(false);
  useEffect(() => {
    const getData = async () => {
      const result = await getLessonStateList(queryParams.get("id"));
      setlist(result.data);
      setFlag(true);
    };
    getData();
  }, []);
  return (
    <div className="lesson-state">
      <NavBar
        fixed
        leftText="购买课程使用详情"
        onClickLeft={() => {
          navigate(-1);
        }}
        placeholder
        safeAreaInsetTop
      />
      <div className="esson-state-content">
        <Skeleton loading={flag ? false : true} row={4}>
          <List>
            {list.map((item, i) => (
              <Cell
                key={i}
                label={
                  <Space direction="vertical">
                    {item.product_name}
                    {"有效期: " + item.expiration_date.split(" ")[0]}
                  </Space>
                }
                title={item.asset_category}
                value={item.status}
              />
            ))}
          </List>
        </Skeleton>
        <div className="double-block globalbg"></div>
      </div>
    </div>
  );
}

export default LessonState;
