import request from "utils/request";

export function getWordList(orderBy) {
  return request.get(`/ecdict/wordCollections.php?orderBy=${orderBy}`, "nocache");
}

export function wordAudio({ word, type }) {
  return request.get(`ecdict/wordAudio.php?word=${word}&type=${type}`, "nocache");
}

export function deleteWord(word_id) {
  return request.delete(`ecdict/wordCollections.php?word_id=${word_id}`, "nocache");
}

export function exportLogs(data) {
  return request.post(`ecdict/exportLogs.php`, data, "nocache");
}
