import { useEffect, useState } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { Tabs } from "react-vant";
import styles from "./Course.module.less";
import MyTextbook from "views/course/MyTextbook";
import OnStudy from "views/course/OnStudy";
import FinishStudy from "views/course/FinishStudy";
const tabsList = ["我的教材", "学习中", "已学完"];

const Course = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { index } = useParams();
  const [defaultIndex, setDefaultIndex] = useState(null);
  const tabComponents = (i) => {
    if (i === 0) {
      return <MyTextbook />;
    }
    if (i === 1) {
      return <OnStudy />;
    }
    if (i === 2) {
      return <FinishStudy />;
    }
  };
  useEffect(() => {
    setDefaultIndex(index ? Number(index) : 0);
  }, []);
  return (
    <div className={styles["course-container"]}>
      {defaultIndex !== null ? (
        <Tabs
          ellipsis={false}
          className={styles["tab-container"]}
          defaultActive={defaultIndex}
          onChange={async (index) => {
            const path = location.pathname;
            navigate(`${path.slice(0, path.length - 1)}${index}`);
          }}
          type="line"
        >
          {tabsList.map((title, index) => (
            <Tabs.TabPane key={`course-tab-${index}`} title={title}>
              {tabComponents(index)}
            </Tabs.TabPane>
          ))}
        </Tabs>
      ) : null}
    </div>
  );
};
export default Course;
