import { Route } from "react-router-dom";
import { Routes } from "react-router-dom";
import Turntable from "./Turntable";
import FlipCard from "./FlipCard";
import Ssc from "./Ssc";
import DollMachine from "./DollMachine";
import Dice from "./Dice";
import GameStart from "./GameStart";

function Game() {
  return (
    <Routes>
      <Route element={<Turntable />} path="turntable" />
      <Route element={<FlipCard />} path="flipcard" />
      <Route element={<Ssc />} path="ssc" />
      <Route element={<DollMachine />} path="dollmachine" />
      <Route element={<Dice />} path="dice" />
      <Route element={<GameStart />} path="gamestart" />
    </Routes>
  );
}

export default Game;
