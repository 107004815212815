import {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import CommonNavBar from "views/components/CommonNavBar";
import styles from './Service.module.less';
import {Dialog,List} from "react-vant";
import {getService,getQAList} from "api/mine";

const _ = require("lodash");

function Service(){
    const navigate = useNavigate();
    const [type,setType] = useState(1);
    const [weChatUrl, setWeChatUrl] = useState("");
    const [serviceUrl, setServiceUrl] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [title,setTitle] = useState("");
    const [questionData, setQuestionData] = useState([]);
    const [finished, setFinished] = useState(false);

    const getData = () => {
        getService(1).then((res) => {
           setWeChatUrl(res.data.link);
        });
        getService(2).then((res) => {
            setServiceUrl(res.data.link);
        })
    }

    const getQuestionList = () => {
        getQAList(currentPage,title).then(res => {
            if(res.data){
                setFinished(true);
                let data = res.data.data;
                data.sort((a, b) => b.sort - a.sort);// 降序排序
                setQuestionData(data);
            }
        })
    }

    const handleChange = (e) => {
        delayedSearch(e.target.value);
    }

    //延迟防抖
    const delayedSearch = _.debounce((val) => {
        console.log(val);
        setTitle(val);
    },250);

    useEffect(() => {
        getData()
        getQuestionList()
    }, [currentPage,title]);

    const gotoWeChatService = () => {
        navigate(
            `/webUrlView?title=${encodeURIComponent(
                "口袋领航外教企微客服"
            )}&webUrl=${encodeURIComponent(weChatUrl)}`
        );
    }
    const gotoAlipayService = () => {
        navigate(
            `/webUrlView?title=${encodeURIComponent(
                "口袋领航外教客服中心"
            )}&webUrl=${encodeURIComponent(serviceUrl)}`
        );
    }

    return (
        <div className={styles["main-container"]}>
            <CommonNavBar
                onClickBack={() => {
                    navigate(-1);
                }}
            />
            <img
                className={styles["top-banner"]}
                src={require("@/images/mine/ic_mine_header_bg.png")}
             />
            <div className={styles["top-container"]}>
                {/*<img className={styles["top-left"]} src={require("@/images/mine/ic_mine_avatar.png")}/>*/}
                <div className={styles["top-right"]}>
                    <div className={styles["top-right-text1"]}>欢迎体验</div>
                    <div className={styles["top-right-text2"]}>口袋领航外教在线客服</div>
                </div>
            </div>
            <div className={styles["search-QA"]}>
                <div className={styles["qa-text"]}>猜你想问</div>
                <div className={styles["qa-input"]}>
                    <img className={styles["qa-icon"]} src={require("@/images/mine/ic_mine_sousuo.png")}/>
                    <input placeholder="问题搜索" type="text" onChange={handleChange} />
                </div>
            </div>
            {/*<List finished={finished} errorText='请求失败，点击重新加载' className={styles["rv-list"]}>*/}
            <div className={styles["rv-list"]}>
                {
                    questionData.map((item, index) => {
                        return (
                            <div className={styles["tab-list"]} key={item.id} onClick={()=>
                                navigate(`/mine/questContent?title=${encodeURIComponent(item.title)}&content=${encodeURIComponent(item.content)}&webUrl=${encodeURIComponent(serviceUrl)}`)
                            }>
                                <div className={styles["tab-item"]}>
                                    {item.title}
                                </div>
                                <img className={styles["tab-item-icon"]}
                                     src={require("@/images/mine/ic_mine_r_arrow.png")}/>

                            </div>
                        )
                    })
                }
            </div>
            {/*</List>*/}
            <div className={styles["tab-container"]}>
                <div className={styles["tab-item"]} onClick={gotoWeChatService}>
                    <img className={styles["img1"]} src={require("@/images/mine/ic_mine_wechat.png")}/>
                    <div className={styles["tab-text"]}>微信客服</div>
                </div>
                <div className={styles["tab-item"]} onClick={gotoAlipayService}>
                    <img className={styles["img2"]} src={require("@/images/mine/ic_mine_p.png")}/>
                    <div className={styles["tab-text"]}>在线客服</div>
                </div>
            </div>
        </div>
    )
}

export default Service;
