import { Route } from "react-router-dom";
import { Routes } from "react-router-dom";
import Level from "./level/Level";
import CoursePackage from "./course-package/CoursePackage";
import NotFoundPage from "views/NotFoundPage";
function TextbookManage() {
  return (
    <Routes>
      <Route element={<Level />} path="level" />
      <Route element={<CoursePackage />} path="coursePackage" />
      <Route element={<NotFoundPage />} path="*" />
    </Routes>
  );
}

export default TextbookManage;
