import styles from "./CommonButton.module.less";
import { useCallback } from "react";

function CommonButton({
  text,
  className,
  delay = 0,
  onClick,
  disabled = false
}) {
  const debounceImmediate = (func, delay) => {
    let timer;
    return (...args) => {
      if (!timer) {
        func(...args);
      }
      if (timer) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        timer = null;
      }, delay);
    };
  };
  const handleClick = useCallback(
    debounceImmediate(() => {
      if (onClick && !disabled) {
        onClick();
      }
    }, delay),
    [onClick, delay, disabled]
  );
  return (
    <div
      className={`${styles["login-button-container"]} ${className} ${
        disabled ? styles["login-button-container--disabled"] : ""
      }`}
      onClick={handleClick}
    >
      {text}
    </div>
  );
}
export default CommonButton;
