import { useEffect, useRef, useState } from "react";
import styles from "./Pay.module.less";
import CommonModal from "views/components/CommonModal";
import { Toast } from "react-vant";
import { getRedeemCodeInfo, redeemCode } from "api/order";
const ExchangePop = ({ show, setShow }) => {
  const [modalType, setModalType] = useState(0);
  const [code, setCode] = useState("");
  const [codeData, setCodeData] = useState({});
  const [exchangeMessage, setExchangeMessage] = useState("");
  const codeLoadingRef = useRef(null);
  const getCodeData = async () => {
    const postForm = new FormData();
    postForm.append("redeem_code", code);
    codeLoadingRef.current = Toast.loading({
      message: "兑换码信息加载中...",
      forbidClick: true,
      duration: 0
    });
    const res = await getRedeemCodeInfo(postForm);
    codeLoadingRef.current.clear();
    if (res.code === 200) {
      if (res.data.status === true) {
        setCodeData(res.data);
        setModalType(2);
      } else {
        setExchangeMessage(res?.data.msg);
        setModalType(4);
      }
    }
  };
  const handleExchange = async () => {
    const postForm = new FormData();
    postForm.append("redeem_code", code);
    codeLoadingRef.current = Toast.loading({
      message: "兑换中",
      forbidClick: true,
      duration: 0
    });
    const res = await redeemCode(postForm);
    codeLoadingRef.current.clear();
    if (res.code === 200) {
      setExchangeMessage(res?.data.msg);
      if (res?.data?.status === true) {
        setModalType(3);
      } else {
        setModalType(4);
      }
    }
  };
  const contentFactory = () => {
    if (modalType === 1) {
      return (
        <div className={styles["content-1"]}>
          <input
            className={styles["content-1-input"]}
            type="text"
            onChange={(e) => {
              setCode(e.target.value);
            }}
          />
          <div
            className={styles["content-1-confirm"]}
            onClick={getCodeData}
          ></div>
        </div>
      );
    }
    if (modalType === 2) {
      return (
        <div className={styles["content-2"]}>
          <div className={styles["content-2-title"]}>兑换信息</div>
          <div className={styles["content-2-text"]}>
            <span className={styles["color-1"]}>是否确认</span>
            <span className={styles["color-2"]}>兑换课程卡，确认后</span>
            <span className={styles["color-1"]}>不可撤销</span>
          </div>
          <div className={styles["content-2-content"]}>
            <div className={`${styles["one-cell"]}`}>{`课程卡名称：${
              codeData?.title || ""
            }`}</div>
            <div className={styles["one-cell"]}>{`兑换课时：${
              codeData?.lesson_hour ?? ""
            }`}</div>
            <div>{`有效期：${codeData?.duration_months || ""}`}</div>
          </div>
          <div
            className={styles["content-2-confirm"]}
            onClick={handleExchange}
          ></div>
        </div>
      );
    }
    if (modalType === 3) {
      return (
        <div className={styles["content-3"]}>
          <div className={styles["content-3-prompt"]}>
            <div className={styles["color-1"]}>成功领取课程！</div>
            <div className={styles["color-2"]}>{exchangeMessage}</div>
          </div>
        </div>
      );
    }
    if (modalType === 4) {
      return (
        <div className={styles["content-4"]}>
          <div className={styles["content-4-prompt"]}>
            <div className={styles["color-1"]}>对不起！</div>
            <div className={styles["color-2"]}>{exchangeMessage}</div>
          </div>
        </div>
      );
    }
    return null;
  };
  useEffect(() => {
    if (show) {
      setModalType(1);
    } else {
      setModalType(0);
    }
  }, [show]);
  return (
    <CommonModal isOpen={show}>
      <div className={styles["modal"]}>
        {contentFactory()}
        <img
          onClick={() => {
            setShow(false);
            setCode("");
            setCodeData({});
            setExchangeMessage("");
          }}
          className={styles["modal-close"]}
          src={require("@/images/mine/recommend-officer/button_cancel.png")}
        />
      </div>
    </CommonModal>
  );
};

export default ExchangePop;
