// //设置 rem 函数
// function setRem() {
//   const screenWidth = 926;
//   const scale = screenWidth / 12;
//   const htmlWidth =
//     document.documentElement.clientWidth || document.body.clientWidth;
//   const htmlDom = document.getElementsByTagName("html")[0];
//   htmlDom.style.fontSize = htmlWidth / scale + "px";
// }
// // 初始化
// setRem();
// // 改变窗口大小时重新设置 rem
// window.onresize = function () {
//   setRem();
// };

const baseSize = 16;

function setRem() {
  const scale = window.innerWidth / 428;
  document.documentElement.style.fontSize =
    (baseSize * scale < 20 ? baseSize * scale : 20) + "px";
}
setRem();
window.onresize = function () {
  setRem();
};
