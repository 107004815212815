import axios from "axios";
import axiosRetry from "axios-retry";
import { Toast } from "react-vant";
import { isTokenExpired, refreshToken, navigateToSignIn } from "./tokenTools";
import { isNonEmptyArray, isNonEmptyObject } from "./objectTools";
const MAX_REFRESH_ATTEMPTS = 1;
let refreshAttempts = 0;
const globalCacheData = {};
const ajax = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 30000,
  withCredentials: false
});
// 避免同时发送多个刷新token的请求
let isRefreshing = false
// 在这里添加 axios-retry 插件，指定重试策略
axiosRetry(ajax, {
  retries: 3, // 重试次数
  retryDelay: (retryCount) => {
    return retryCount * 1000; // 重试延迟，可以根据需要自定义
  },
  shouldResetTimeout: true // 重试时是否重置超时时间
});

// 在请求拦截器中设置JWT令牌
ajax.interceptors.request.use(async (config) => {
  const token = localStorage.getItem("access_token");
  const useToken = config.url.includes("register") ? false : true;
  if (token && useToken) {
    if (isTokenExpired()) {
      if (!isRefreshing) {
        isRefreshing = true;
        try {
          const newToken = await refreshToken();
          config.headers.Authorization = `Bearer ${newToken}`;
          isRefreshing = false;
        } catch (e) {
          if (e === "无刷新Token") {
            refreshAttempts = 1;
            localStorage.clear();
            navigateToSignIn("#/login/logon");
          }
        }
      }
    } else {
      const token = localStorage.getItem("access_token");
      config.headers.Authorization = `Bearer ${token}`;
    }
  }
  return config;
});

ajax.interceptors.response.use(async (response) => {
  //原生数据或者其他问题
  if (!response?.data?.code) {
    return response?.data || response;
  }
  //鉴权错误
  if (response.data.code == 402) {
    if (refreshAttempts < MAX_REFRESH_ATTEMPTS) {
      refreshAttempts++;
      try {
        const newToken = await refreshToken();
        if (newToken) {
          const originalRequest = response.config;
          originalRequest.headers.Authorization = `Bearer ${newToken}`;
          return ajax(originalRequest);
        }
      } catch {
        console.log("刷新token失败");
      }
    } else {
      refreshAttempts = 0;
      Toast.fail(`${response.data.message} 请重新登录`);
      localStorage.clear();
      navigateToSignIn("#/login/logon");
    }
  }
  //弹错误消息
  if (response.data.code != 200 && response.data.code != 402) {
    try {
      JSON.parse(response.data.message);
      const mes = JSON.parse(response.data.message.replace(/'/g, '"'));
      const values = Object.values(mes);
      const firstValue = values[0];
      if (response.config.toast !== 'hideToast') {
        Toast.fail(firstValue);
      }
    } catch {
      if (response.config.toast !== 'hideToast') {
        Toast.fail(response.data.message);
      }
    }
  }
  //缓存有效期为 5 分钟
  if (isNonEmptyObject(response.data) || isNonEmptyArray(response.data)) {
    globalCacheData[response.config.url] = {
      data: response.data,
      time: new Date().getTime() + 300000
    };
  }
  return response.data;
},
  (error) => {
    //能走这里 一般都是 本地问题
    Toast.fail("当前网络不稳定!");
    return Promise.reject("当前网络不稳定!");
  }
);
const cacheData = (args) => {
  let [url] = args;
  let nocahce = false;
  if (typeof url === "object" && "url" in url) {
    url = url.url;
  }
  if (args.includes("nocache") || typeof args[2] === 'object' && args[2].cache === "nocache") {
    nocahce = true;
  }
  if (
    url &&
    !nocahce &&
    globalCacheData[url] &&
    globalCacheData[url]["time"] > new Date().getTime()
  ) {
    return globalCacheData[url]["data"];
  }
  return false;
};
const request = {
  get() {
    const args = Array.from(arguments);
    const cache = cacheData(args);
    if (cache) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(cache);
        }, 100);
      })
    }
    return ajax.get(...args);
  },
  post() {
    const args = Array.from(arguments);
    const cache = cacheData(args);
    if (cache) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(cache);
        }, 100);
      })
    }
    return ajax.post(...args);
  },
  delete() {
    const args = Array.from(arguments);
    const cache = cacheData(args);
    if (cache) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(cache);
        }, 100);
      })
    }
    return ajax.delete(...args);
  }
};

export default request;
