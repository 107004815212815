import request from "utils/request";

export async function getPromoterInfo() {
  return request.get("/profile/getPromoterInfo.php", "nocache");
}

export async function updatePromoter(data) {
  return request.post("/profile/updatePromoter.php", data, "nocache");
}

export async function checkUpdatePromoter() {
  return request.get("/profile/checkUpdatePromoter.php", "nocache");
}

export async function getLink() {
  return request.get("/admin/system/getLink.php", "nocache");
}

export async function getService(type) {
  return request.get(`/profile/onlineCustomerService.php?type=${type}`,"nocache");
}

export async function getQAList(currentPage,title) {
  return request.get(`/profile/onlineCustomerQuestion.php?currentPage=${currentPage}&pageSize=50&title=${title}`,"nocache");
}

export async function getInvite(currentPage){
  return request.get(`/my/inviteRecord.php?currentPage=${currentPage}`)
}

