import request from "utils/request";

export async function getMyMaterials() {
  return request.get(`/materials/myMaterials.php`, "nocache");
}

export async function getLessonv3(page) {
  return request.get(`/lesson/lessonv3.php?page=${page}`, "nocache");
}

export async function getMyLessonv3(page) {
  return request.get(`/lesson/myLessonv3.php?page=${page}`, "nocache");
}

export async function getMyMaterialsLesson() {
  return request.get(`/lesson/myMaterials.php`, "nocache");
}