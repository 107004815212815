import { useState, useImperativeHandle, forwardRef, useEffect } from "react";
import styles from "./LoginInput.module.less";
const LoginInput = forwardRef((props, ref) => {
  const {
    className,
    type = "text",
    children,
    icon,
    selectedIcon,
    placeholderText = "",
    field,
    inputMode = "text",
    defaultValue = ""
  } = props;
  const [inputValue, setInputValue] = useState("");
  const [onSelected, setOnSelected] = useState(false);
  const [showText, setShowText] = useState(false);

  useImperativeHandle(ref, () => ({
    getValue: () => inputValue,
    getKey: () => field
  }));
  useEffect(() => {
    setInputValue(defaultValue);
  }, [defaultValue]);
  return (
    <div
      className={`${styles["login-input-container"]} ${className} ${
        onSelected ? styles["login-input-container--selected"] : ""
      }`}
    >
      <div className={styles["left-content"]}>
        {icon ? (
          <img
            className={styles["left-content-icon"]}
            src={onSelected ? selectedIcon || icon : icon}
          />
        ) : null}
        <input
          className={styles["left-content-input"]}
          inputMode={inputMode}
          onPaste={(e) => {
            if (type === "password") {
              e.preventDefault();
            }
          }}
          onBlur={() => setOnSelected(false)}
          onChange={(e) => {
            // if (type === "password" && !showText) {
            //   if (inputValue.length < e.target.value.length) {
            //     const newInputValue = inputValue + e.target.value.slice(-1);
            //     setInputValue(newInputValue);
            //   }
            //   if (inputValue.length > e.target.value.length) {
            //     setInputValue(inputValue.slice(0, e.target.value.length));
            //   }
            // } else {
            //   setInputValue(e.target.value);
            // }
            setInputValue(e.target.value);
          }}
          onFocus={() => setOnSelected(true)}
          placeholder={placeholderText}
          type={type === "password" ? (showText ? "text" : "password") : type}
          value={inputValue}
        />
      </div>
      {type === "password" && !children && inputValue ? (
        <img
          className={styles["right-content"]}
          onClick={() => {
            setShowText(!showText);
          }}
          src={require(
            `@/images/login/ic_input_password_${showText ? "hide" : "show"}.png`
          )}
        />
      ) : null}
      {type !== "password" && !children && inputValue ? (
        <img
          className={styles["right-content"]}
          onClick={() => {
            setInputValue("");
          }}
          src={require("@/images/login/ic_input_clear.png")}
        />
      ) : null}
      {children || null}
    </div>
  );
});
export default LoginInput;
