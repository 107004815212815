import "./Turntable.less";
import Countdown from "./components/Countdown";
import { useEffect, useRef, useState } from "react";
import DSBridge from "dsbridge";
export default function Turntable() {
  const [startDisabled, setStartDisabled] = useState(false);
  const [coverShow, setCoverShow] = useState(false);
  const [endShow, setEndShow] = useState(false);
  const [animationClass, setAnimationClass] = useState("");
  const turntableRef = useRef(null);
  const [score, setScore] = useState(0);
  const resultScoreRef = useRef(0);
  const countRef = useRef(null);
  const exitRef = useRef(null);
  function handleCountChange(count) {
    if (count === 0) {
      setStartDisabled(true);
      exitRef.current = setTimeout(() => {
        DSBridge.call("stopAudio", "turntable.mp3");
        DSBridge.call("exitWebView");
      }, 1000);
      console.log("倒计时未点击，跳出");
    }
  }
  function handleClickStart() {
    if (startDisabled) return;
    setStartDisabled(true);
    countRef.current.handleStop();
    const randomScore = (Math.floor(Math.random() * 5) + 1) * 2;
    setScore(randomScore);
    resultScoreRef.current = randomScore;
    setAnimationClass(` animation-class--${randomScore}`);
  }
  async function init() {
    setStartDisabled(false);
    setCoverShow(false);
    setEndShow(false);
    setAnimationClass("");
    setScore(0);
    resultScoreRef.current = 0;
    countRef.current.handleStart(15);
    DSBridge.call("playAudio", "turntable.mp3");
  }
  useEffect(() => {
    init();
    function handleAnimationEnd(e) {
      const classNames = e.target.className.split(" ");
      if (classNames.includes("round")) {
        setCoverShow(true);
        console.log("转盘动画结束，进入隐藏动画");
      }
      if (classNames.includes("turntable")) {
        setEndShow(true);
        DSBridge.call("playAudio", "victory.mp3");
        console.log("隐藏动画结束，进入结算动画");
      }
      if (classNames.includes("end-bg-score")) {
        exitRef.current = setTimeout(() => {
          DSBridge.call("addScore", String(resultScoreRef.current));
          DSBridge.call("stopAudio", "turntable.mp3");
          DSBridge.call("stopAudio", "victory.mp3");
          DSBridge.call("exitWebView");
        }, 1000);
        console.log(
          `结算动画结束，隐藏弹窗，进行加分:${resultScoreRef.current}`
        );
      }
    }
    turntableRef.current = document.querySelector(".turntable-container");
    if (turntableRef.current) {
      turntableRef.current.addEventListener("animationend", handleAnimationEnd);
      turntableRef.current.addEventListener(
        "webkitAnimationEnd",
        handleAnimationEnd
      );
    }
    return () => {
      if (turntableRef.current) {
        turntableRef.current.removeEventListener(
          "animationend",
          handleAnimationEnd
        );
        turntableRef.current.removeEventListener(
          "webkitAnimationEnd",
          handleAnimationEnd
        );
      }
      if (exitRef.current) {
        clearTimeout(exitRef.current);
      }
    };
  }, []);
  return (
    <div className="turntable-container">
      <div className="bg-top"/>
      <div className="bg-bottom"/>
      <Countdown handleCountChange={handleCountChange} ref={countRef} />
      {!endShow ? (
          <div className={`turntable${coverShow ? " turntable--hide" : ""}`}>
            <div className={`round${animationClass}`}></div>
            {coverShow ? (
                <div className="cover">
                  <img
                      className="cover-select"
                      src={require("@/images/game/turntable/turn-select2.png")}
                  />
                </div>
            ) : null}
            <div className={`score${animationClass}`}>
              <img
                  className="score--2"
                  src={require("@/images/game/turntable/score-2.png")}
              />
              <img
                  className="score--4"
                  src={require("@/images/game/turntable/score-4.png")}
              />
              <img
                  className="score--6"
                  src={require("@/images/game/turntable/score-6.png")}
              />
              <img
                  className="score--8"
                  src={require("@/images/game/turntable/score-8.png")}
              />
              <img
                  className="score--10"
                  src={require("@/images/game/turntable/score-10.png")}
              />
            </div>
            <div
                className={`pointer${startDisabled ? "" : " pointer--flicker"}`}
                onClick={handleClickStart}
            ></div>
          </div>
      ) : (
          <div className="settlement">
            <div className="end-bg">
              <img
                  className="end-bg-score"
                  src={require(`@/images/game/turntable/end-${score}.png`)}
            />
          </div>
        </div>
      )}
      <img
        className="chassis"
        src={require("@/images/game/turntable/turn-bottom.png")}
      />
    </div>
  );
}
