import React, { useEffect, useState } from "react";
import "./SearchList.less";
import { ArrowLeft, DeleteO } from "@react-vant/icons";
import { Dialog } from "react-vant";
function SearchList({ setSearchShow, handleSearch, keyword }) {
  const [inputValue, setInputValue] = useState(keyword || "");
  const [history, setHistory] = useState([]);
  function handleChange(e) {
    setInputValue(e.target.value);
  }
  function handleBack() {
    setSearchShow(false);
  }
  function handleBackSearch(text) {
    handleSearch(text || inputValue);
    if (inputValue !== "" && !history.includes(text || inputValue)) {
      const newHistory = [...history];
      newHistory.push(text || inputValue);
      localStorage.setItem("search-history", JSON.stringify(newHistory));
    }
    handleBack();
  }
  function handleDelete() {
    Dialog.confirm({
      confirmButtonColor: "#f44336",
      message: "确认删除全部历史搜索？",
      onCancel: () => console.log("cancel"),
      onConfirm: () => {
        setHistory([]);
        localStorage.removeItem("search-history");
      }
    });
  }
  function handleKeyDown(e) {
    if (e.key === "Enter") {
      handleBackSearch();
    }
  }
  useEffect(() => {
    const localHistory = localStorage.getItem("search-history");
    if (localHistory) {
      try {
        setHistory(JSON.parse(localHistory));
      } catch {
        console.log("无历史记录");
      }
    }
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "";
    };
  }, []);
  return (
    <div className="search-list-container">
      <div className="header">
        <div className="header-back" onClick={handleBack}>
          <ArrowLeft fontSize={"20px"} />
        </div>
        <input
          autoFocus
          className="header-input"
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          placeholder=" 请输入关键词"
          type="search"
          value={inputValue}
        />
        <div className="header-search" onClick={() => handleBackSearch()}>
          搜索
        </div>
      </div>
      <div className="history">
        <h5>历史记录</h5>
        {history.length > 0 ? (
          <DeleteO
            className="history-delete"
            fontSize={"20px"}
            onClick={handleDelete}
          />
        ) : null}
        <div className="history-list">
          {history.map((item, index) => (
            <span
              className="history-list-item"
              key={`search-history-${index}`}
              onClick={() => handleBackSearch(item)}
            >
              {item}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
}

export default SearchList;
