export const getUrlParams = (url = window.location.href) => {
    try {
        // 获取URL中的参数
        const params = {};
        const queryString = url.split('?')[1];
        const keyValuePairs = queryString.split('&');
        keyValuePairs.forEach(keyValuePair => {
            const [key, value] = keyValuePair.split('=');
            params[key] = decodeURIComponent(value);
        });
        return params;
    } catch (err) {
        return {}
    }

}