import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./MyTextbook.module.less";
import { getMyMaterialsLesson } from "api/course";
import { Skeleton } from "react-vant";
import ProportionIcon from "views/components/ProportionIcon";
const MyTextbook = () => {
  const navigate = useNavigate();
  const [dataList, setDataList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const initData = async () => {
    setIsLoading(true);
    const res = await getMyMaterialsLesson();
    setIsLoading(false);
    if (res.code === 200) {
      setDataList([...dataList, ...res.data]);
    }
  };
  useEffect(() => {
    initData();
  }, []);
  return (
    <div className={styles["my-textbook-container"]}>
      {isLoading && dataList.length === 0 ? <Skeleton title /> : null}
      {dataList.length > 0 ? (
        <>
          {dataList.map((item, index) => (
            <div
              className={styles["textbook-item"]}
              key={`myTextbook-item-${index}`}
              onClick={() =>
                navigate(
                  `/textbook/coursePackage?series_id=${item.series_id}&grade_id=${item.grade_id}&book_id=${item.book_id}`
                )
              }
            >
              <div className={styles["textbook-item-time"]}>
                {`最近学习：${item.event_time || ""}`}
              </div>
              <div className={styles["textbook-item-content"]}>
                <div className={styles["content-left"]}>
                  <img
                    className={styles["content-left-pic"]}
                    src={item.cover}
                  />
                  <div className={styles["content-left-desc"]}>
                    <div className={styles["desc-series"]}>
                      {item.series_title || ""}
                    </div>
                    <div className={styles["desc-books"]}>
                      {item.books_title || ""}
                    </div>
                    <div className={styles["desc-title"]}>
                      {item.title || ""}
                    </div>
                  </div>
                </div>
                <div className={styles["content-right"]}>
                  {item.units_finished === item.total_units ? (
                    <img
                      className={styles["content-right-pro"]}
                      src={require("@/assets/icon/ic_book_list_finish.png")}
                    />
                  ) : (
                    <ProportionIcon
                      percent={
                        typeof Number(
                          item.units_finished / item.total_units
                        ) !== NaN
                          ? Number(item.units_finished / item.total_units)
                          : 0
                      }
                    />
                  )}
                </div>
              </div>
            </div>
          ))}
          <div className={styles["bottom-text"]}>已经到底啦～</div>
        </>
      ) : null}
      {!isLoading && dataList.length === 0 ? (
        <div className={styles["empty-wrapper"]}>
          <img
            className={styles["center-pic"]}
            src={require("@/images/image/Ub.png")}
          />
          <div className={styles["center-text"]}>
            {localStorage.getItem("access_token") ? (
              <>
                您还没有学习任何教材呢
                <br></br>
                赶快去选择教材吧^^{" "}
              </>
            ) : (
              <>请登录以便获取更多信息</>
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default MyTextbook;
