import { getWebList, orderH5, queryOrderStatus } from "api/payMember";
import styles from "./MemberPay.module.less";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Toast } from "react-vant";

function MemberPay() {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [modalType, setModalType] = useState(0);
  const [failMessage, setFailMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [selectId, setSelectId] = useState("");
  const [phone, setPhone] = useState("");
  const [agree, setAgree] = useState(false);
  const handleSelect = (item) => {
    setSelectId(item.activity_id);
  };
  const isWeChat = () => {
    var ua = window.navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
      return true;
    } else {
      return false;
    }
  };
  const handlePay = async () => {
    if (!agree) {
      return Toast.info("请阅读并同意课程服务协议");
    }
    if (selectId === "") {
      return Toast.info("请选择课时次卡");
    }
    if (!phone) {
      return Toast.info("请输入手机号码");
    }
    if (!/^\d{11}$/.test(phone)) {
      return Toast.info("请输入正确的手机号码");
    }
    if (loading) {
      return;
    }
    setLoading(true);
    const res = await orderH5({
      activity_id: selectId,
      phone_number: phone,
      rec_id: queryParams.get("rec_id")
    });
    if (res.code === 200) {
      if (res.data.status === false) {
        setFailMessage(res.data.msg);
        setModalType(2);
      } else {
        if (res?.data?.payData?.h5_url) {
          window.location.href = res.data.payData.h5_url;
        }
      }
    }
    setLoading(false);
  };
  const init = async () => {
    const res = await getWebList();
    if (res.code === 200) {
      try {
        const filterData = res.data.filter((_, index) => index < 3);
        setDataList(filterData);
      } catch (e) {
        console.log(e);
      }
    }
    if (queryParams.get("order_no")) {
      const queryRes = await queryOrderStatus({
        order_no: queryParams.get("order_no")
      });
      if (queryRes.code === 200) {
        if (queryRes.data.status === false) {
          setFailMessage(queryRes.data.msg);
          setModalType(2);
        } else {
          setModalType(1);
        }
      }
    }
  };
  useEffect(() => {
    if (isWeChat()) {
      Toast.info({
        message: "请手动复制网址到浏览器打开",
        forbidClick: true,
        duration: 0
      });
    } else {
      init();
    }
    document.body.setAttribute("style", "overflow-x: visible");
    return () => {
      document.body.removeAttribute("style");
    };
  }, []);
  return (
    <div className={styles["member-pay-container"]}>
      <div className={styles["container-back"]}>
        <div className={styles["content"]}>
          <div className={styles["content-title"]}>会员开通，限时优惠！</div>
          <div className={styles["content-box"]}>
            {dataList.map((item, index) => (
              <div
                className={`${styles["content-box-item"]} ${
                  selectId === item.activity_id
                    ? styles["content-box-item--selected"]
                    : ""
                }`}
                key={`pay-member-${index}`}
                onClick={() => handleSelect(item)}
              >
                <div
                  className={styles["item-text-1"]}
                >{`${item.quantity}课时次卡`}</div>
                <div
                  className={styles["item-text-2"]}
                >{`（${item.duration_months}个月有效期）`}</div>
                <div className={styles["item-text-3"]}>
                  <span className={styles["item-text-4"]}>¥</span>
                  <span>{item.total_price}</span>
                </div>
                <div
                  className={styles["item-text-5"]}
                >{`原价¥${item.original_price}`}</div>
                <div
                  className={styles["item-text-6"]}
                >{`每课仅¥${item.promo_price}`}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={styles["phone-wrapper"]}>
        <span className={styles["phone-wrapper-title"]}>手机号码：</span>
        <input
          className={styles["phone-wrapper-input"]}
          type="text"
          onChange={(e) => {
            setPhone(e.target.value);
          }}
          placeholder="请输入手机号码"
          value={phone}
        />
      </div>
      <div className={styles["bottom-block"]}></div>
      <div className={styles["bottom"]}>
        <div className={styles["agreement-box"]}>
          <div
            className={`${styles["box-icon"]} ${
              agree ? styles["box-icon--checked"] : ""
            }`}
            onClick={() => setAgree(!agree)}
          ></div>
          <div className={styles["text-1"]} onClick={() => setAgree(!agree)}>
            同意
          </div>
          <div
            className={styles["text-2"]}
            onClick={() => {
              navigate(
                `/webUrlView?webUrl=${encodeURIComponent(
                  `${process.env.REACT_APP_API_CDN}/utalkrc/privacy/en/app-service.html`
                )}&title=${encodeURIComponent("课程服务协议")}`
              );
            }}
          >
            《课程服务协议》
          </div>
        </div>
        <div className={styles["bottom-button"]} onClick={handlePay}>
          立即购买
        </div>
      </div>
      {modalType !== 0 ? (
        <div className={styles["modal"]}>
          {modalType === 1 ? (
            <div className={styles["modal-success"]}>
              <div className={styles["modal-success-title"]}>购买成功！</div>
              <div className={styles["modal-success-text"]}>
                请登录APP学习吧！
              </div>
            </div>
          ) : null}
          {modalType === 2 ? (
            <div className={styles["modal-fail"]}>
              <div className={styles["modal-fail-title"]}>购买失败！</div>
              <div className={styles["modal-fail-text"]}>{failMessage}</div>
            </div>
          ) : null}
          <img
            onClick={() => setModalType(0)}
            className={styles["modal-close"]}
            src={require("@/images/mine/recommend-officer/button_cancel.png")}
          />
        </div>
      ) : null}
    </div>
  );
}
export default MemberPay;
