import "./Sound.less";
export default function Sound({
  data,
  type,
  currentPlay,
  handleClickPlay,
  index
}) {
  return (
    <div
      className={`audio-container ${
        type === "student" ? "audio-container--yellow" : "audio-container--blue"
      }`}
    >
      <img
        className="play-button"
        onClick={() => handleClickPlay(index, type, data)}
        src={
          currentPlay === `${type}-${index}`
            ? require("@/images/report/audio-pause.png")
            : require("@/images/report/audio-play.png")
        }
      />
      <img className="sound" src={require("@/images/report/audio-sound.png")} />
    </div>
  );
}
