import { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Toast } from "react-vant";
import styles from "./IntroTest.module.less";
import CommonModal from "views/components/CommonModal";
import { getDemoAnswer, postSubmitAnswer, getDemoMeaterial } from "api/lesson";
const popStatus = {
  end: {
    title: "结束测评",
    text: ["测评还未结束", "确认提前交卷吗？"],
    confirm: "确认交卷",
    cancel: "继续答题"
  },
  exit: {
    title: "退出测评",
    text: ["测评还未结束", "确认退出吗？"],
    confirm: "退出测评",
    cancel: "继续答题"
  }
};
const audio_right = require("@/images/mine/test-right.MP3");
const audio_wrong = require("@/images/mine/test-wrong.MP3");
const audio_complete = require("@/images/mine/test-complete.MP3");
const IntroTest = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [endType, setEndType] = useState(null);
  const toastRef = useRef(null);
  const [materialList, setMaterialList] = useState([]);
  const [answerList, setAnswerList] = useState([]);
  const [answerIndex, setAnswerIndex] = useState(0);
  const wrongNum = useRef(0);
  const [resultData, setResultData] = useState({});
  const audioRef = useRef(null);
  const handleGetBooks = async () => {
    toastRef.current = Toast.loading({
      message: "正在获取教材数据中...",
      forbidClick: true,
      duration: 0
    });
    const materialRes = await getDemoMeaterial();
    if (materialRes.code === 200) {
      const handleMData = materialRes.data.map((item) => {
        item.select = false;
        // if (item.title === "其他") {
        //   item.text = "";
        // }
        return item;
      });
      handleMData.push({ title: "其他", text: "", select: false });
      handleMData.unshift({
        title: "零基础或英语启蒙一年内",
        select: false,
        unStudy: true
      });
      setMaterialList(handleMData);
      setStep(2);
    }
    toastRef.current.clear();
  };
  const handleGetAnswer = async () => {
    if (materialList.find((item) => item.unStudy).select) {
      return handleConfirm(true);
    }
    toastRef.current = Toast.loading({
      message: "正在获取题目数据中...",
      forbidClick: true,
      duration: 0
    });
    const answerRes = await getDemoAnswer();
    if (answerRes.code === 200) {
      const newAnswerData = answerRes.data.map((item) => {
        item.myAnswer = "";
        return item;
      });
      setAnswerList(newAnswerData);
      setStep(3);
    }
    toastRef.current.clear();
  };
  const handleConfirm = async (unStudy) => {
    try {
      audioRef.current.src = audio_complete;
      audioRef.current.play();
    } catch (e) {
      console.log(e);
    }
    toastRef.current = Toast.loading({
      message: "正在提交数据中...",
      forbidClick: true,
      duration: 0
    });
    const selectMaterialList = materialList.filter(
      (item) => item.title !== "其他" && item.select === true
    );
    selectMaterialList.forEach((item) => {
      delete item.select;
    });
    const otherText =
      materialList.find((item) => item.title === "其他")?.select === true
        ? materialList.find((item) => item.title === "其他").text
        : "";
    const submitRes = await postSubmitAnswer({
      score: unStudy
        ? 0
        : answerList.filter(
            (answerItem) => answerItem.answer === answerItem.myAnswer
          ).length * 2,
      other: unStudy ? "" : otherText,
      materials: unStudy ? [] : selectMaterialList
    });
    if (submitRes.code === 200) {
      setResultData(submitRes.data);
      if (unStudy) {
        setStep(5);
      } else {
        setStep(4);
      }
    }
    toastRef.current.clear();
  };
  useEffect(() => {
    audioRef.current = new Audio();
    return () => {
      if (audioRef.current?.pause) {
        audioRef.current.pause();
      }
    };
  }, []);
  return (
    <div className={styles["intro-test-container"]}>
      <img
        className={styles["right-banner"]}
        src={require("@/images/login/right-banner.png")}
      />
      {step === 1 ? (
        <div className={styles["page-1"]}>
          <div className={styles["page-1-content"]}>
            <div className={styles["content-top"]}>
              <img
                className={styles["content-top-icon"]}
                src={require("@/images/douyin/myai.png")}
              />
              <div className={styles["content-top-title"]}>入门测试</div>
            </div>
            <div className={styles["content-bottom"]}>
              <div
                className={styles["content-bottom-start"]}
                onClick={handleGetBooks}
              >
                进入测试
              </div>
              <div
                className={styles["content-bottom-end"]}
                onClick={() => navigate(-1)}
              >
                稍后测试
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {step === 2 ? (
        <div className={styles["page-2"]}>
          <div
            className={styles["exit-button"]}
            onClick={() => setEndType("exit")}
          >
            退出测试
          </div>
          <div className={styles["page-2-content"]}>
            <div className={styles["title"]}>
              <span>请选择你正在学习的教材或正在准备的考试</span>
              <span className={styles["title--select"]}>【多选】</span>
            </div>
            {materialList.map((item, index) => {
              if (item.title === "其他") {
                return (
                  <div
                    className={styles["option"]}
                    key={`introTest-materialList-${index}`}
                    onClick={() => {
                      item.select = !item.select;
                      setMaterialList([...materialList]);
                    }}
                  >
                    <div
                      className={
                        item.select
                          ? styles["option-select"]
                          : styles["option-unselect"]
                      }
                    ></div>
                    <div className={styles["option-text"]}>
                      <span>{item.title}</span>
                      <input
                        className={styles["option-input"]}
                        value={item.text}
                        type="text"
                        onChange={(e) => {
                          item.text = e.target.value;
                          setMaterialList([...materialList]);
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        onFocus={(e) => {
                          e.stopPropagation();
                        }}
                      />
                    </div>
                  </div>
                );
              } else {
                return (
                  <div
                    className={styles["option"]}
                    key={`introTest-materialList-${index}`}
                    onClick={() => {
                      item.select = !item.select;
                      setMaterialList([...materialList]);
                    }}
                  >
                    <div
                      className={
                        item.select
                          ? styles["option-select"]
                          : styles["option-unselect"]
                      }
                    ></div>
                    <div className={styles["option-text"]}>{item.title}</div>
                  </div>
                );
              }
            })}
            <div className={styles["start-button"]} onClick={handleGetAnswer}>
              开始答题
            </div>
          </div>
        </div>
      ) : null}
      {step === 3 ? (
        <div className={styles["page-3"]}>
          <div
            className={styles["exit-button"]}
            onClick={() => setEndType("exit")}
          >
            退出测试
          </div>
          <div className={styles["page-3-content"]}>
            <div className={styles["title"]}>
              <span>{answerList[answerIndex].question}</span>
            </div>
            {answerList[answerIndex].options.map((item, index) => {
              return (
                <div
                  className={`${styles["option"]} ${
                    answerList[answerIndex].myAnswer ===
                    String.fromCharCode(65 + index)
                      ? styles["option--select"]
                      : ""
                  }`}
                  key={`introTest-option-${answerIndex}-${index}`}
                  onClick={() => {
                    if (answerList[answerIndex].myAnswer === "") {
                      answerList[answerIndex].myAnswer = String.fromCharCode(
                        65 + index
                      );
                      setAnswerList([...answerList]);
                      if (
                        String.fromCharCode(65 + index) !==
                        answerList[answerIndex].answer
                      ) {
                        wrongNum.current = wrongNum.current + 1;
                        try {
                          audioRef.current.src = audio_wrong;
                          audioRef.current.play();
                        } catch (e) {
                          console.log(e);
                        }
                      } else {
                        wrongNum.current = 0;
                        try {
                          audioRef.current.src = audio_right;
                          audioRef.current.play();
                        } catch (e) {
                          console.log(e);
                        }
                      }
                      if (wrongNum.current >= 3) {
                        handleConfirm();
                      } else {
                        if (answerIndex < answerList.length - 1) {
                          setTimeout(() => {
                            setAnswerIndex(answerIndex + 1);
                          }, 1000);
                        } else {
                          handleConfirm();
                        }
                      }
                    }
                  }}
                >
                  <div className={styles["option-left"]}>
                    <div className={styles["option-left-item"]}>
                      {String.fromCharCode(65 + index)}
                    </div>
                    <div className={styles["option-left-text"]}>{item}</div>
                  </div>
                  <div
                    className={
                      answerList[answerIndex].myAnswer ===
                      String.fromCharCode(65 + index)
                        ? styles["option-select"]
                        : styles["option-unselect"]
                    }
                  ></div>
                </div>
              );
            })}
            <div className={styles["right-text"]}>{`答对${
              answerList.filter(
                (answerItem) => answerItem.answer === answerItem.myAnswer
              ).length
            }道`}</div>
            <div
              className={styles["inAdvance-button"]}
              onClick={() => setEndType("end")}
            >
              提前交卷
            </div>
          </div>
        </div>
      ) : null}
      {step === 4 ? (
        <div className={styles["page-4"]}>
          <div
            className={styles["exit-button"]}
            onClick={() => setEndType("exit")}
          >
            退出测试
          </div>
          <div className={styles["page-4-content"]}>
            <div className={styles["title"]}>答题结束</div>
            <img
              className={styles["center"]}
              src={require("@/images/image/intro-center.png")}
            />
            <div className={styles["next-button"]} onClick={() => setStep(5)}>
              查看结果
            </div>
          </div>
        </div>
      ) : null}
      {step === 5 ? (
        <div className={styles["page-5"]}>
          <div className={styles["exit-button"]} onClick={() => navigate(-1)}>
            退出测试
          </div>
          <div className={styles["page-5-content"]}>
            <div className={styles["title"]}>测评报告</div>
            <div className={styles["level"]}>
              <div>{resultData["Level"] || ""}</div>
              <div>{resultData["建议学习难度"] || ""}</div>
            </div>
            <img
              className={styles["report-img"]}
              src={require("@/images/image/introTest-report.png")}
            />
            <div className={styles["report-text"]}>
              {resultData["报告评语"] || ""}
            </div>
            <div className={styles["intro-title"]}>推荐课程</div>
            {resultData["推荐教材第一位"] ? (
              <div className={styles["intro-text"]}>
                {resultData["推荐教材第一位"]}
              </div>
            ) : null}
            {resultData.material.map((item, index) => (
              <div
                className={styles["intro-text"]}
                key={`introTest-intro-text${index}`}
              >
                {item}
              </div>
            ))}
          </div>
        </div>
      ) : null}
      <CommonModal isOpen={!!endType}>
        {endType ? (
          <div className={styles["end-pop"]}>
            <div className={styles["end-pop-title"]}>
              {popStatus[endType].title}
            </div>
            <div className={styles["end-pop-content"]}>
              {popStatus[endType].text.map((item, index) => (
                <div key={`introTest-endType-${index}`}>{item}</div>
              ))}
            </div>
            <div className={styles["end-pop-buttons"]}>
              <div
                className={styles["button-confirm"]}
                onClick={() => {
                  if (endType === "end") {
                    handleConfirm();
                  }
                  if (endType === "exit") {
                    navigate(-1);
                  }
                  setEndType(null);
                }}
              >
                {popStatus[endType].confirm}
              </div>
              <div
                className={styles["button-cancel"]}
                onClick={() => setEndType(null)}
              >
                {popStatus[endType].cancel}
              </div>
            </div>
          </div>
        ) : null}
      </CommonModal>
    </div>
  );
};
export default IntroTest;
