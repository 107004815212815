import { createPortal } from "react-dom";
import styles from "./CommonModal.module.less";
const CommonModal = ({ children, isOpen }) => {
  return (
    isOpen &&
    createPortal(
      <div className={styles["modal-container"]}>{children}</div>,
      document.body
    )
  );
};

export default CommonModal;
