import { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Toast } from "react-vant";
import styles from "./douyinExchange.module.less";
import CommonModal from "views/components/CommonModal";
import { sendVerificationCode } from "api/register";
import { getDouyin } from "api/payMember";

const DouyinExchange = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [selectInput, setSelectInput] = useState("");
  const [card, setCard] = useState("");
  const [phone, setPhone] = useState("");
  const [code, setCode] = useState("");
  const [codeText, setCodeText] = useState("短信验证码");
  const [result, setResult] = useState(null);
  const codeToastRef = useRef(null);
  const countDownRef = useRef(null);
  const handleGetCode = async () => {
    if (codeText === "短信验证码") {
      if (!phone) {
        return Toast.info("请输入手机号码");
      }
      if (!/^\d{11}$/.test(phone)) {
        return Toast.info("请输入正确的手机号码");
      }
      codeToastRef.current = Toast.loading({
        message: "正在发送验证码中...",
        forbidClick: true,
        duration: 0
      });
      const result = await sendVerificationCode({ phone_number: phone });
      codeToastRef.current.clear();
      if (result && result.status === true) {
        let time = 60;
        setCodeText(`再次发送(${time}s)`);
        countDownRef.current = setInterval(() => {
          --time;
          if (time > 0) {
            setCodeText(`再次发送(${time}s)`);
          } else {
            clearInterval(countDownRef.current);
            countDownRef.current = null;
            setCodeText("短信验证码");
          }
        }, 1000);
      } else {
        Toast.info(result.message);
      }
    }
  };
  const handleClickExchange = async () => {
    if (!card) {
      return Toast.info("请输入抖音卡券号");
    }
    if (!phone) {
      return Toast.info("请输入手机号码");
    }
    if (!/^\d{11}$/.test(phone)) {
      return Toast.info("请输入正确的手机号码");
    }
    if (!code) {
      return Toast.info("请输入短信验证码");
    }
    codeToastRef.current = Toast.loading({
      message: "正在兑换中...",
      forbidClick: true,
      duration: 0
    });
    const result = await getDouyin({
      phone_number: phone,
      exchange_code: card,
      verification_code: code
    });
    codeToastRef.current.clear();
    if (result.code === 200) {
      setResult(result.data);
      if (result.data.status === true) {
        Toast.info("正在跳转，请稍后...");
        setTimeout(() => {
          window.location.href = "https://downloads-apk.myaitalk.vip/index.php";
        }, 2000);
      }
    }
  };
  useEffect(() => {
    if (queryParams.get("code")) {
      setCard(queryParams.get("code"));
    }
    return () => {
      clearInterval(countDownRef.current);
      countDownRef.current = null;
    };
  }, []);
  return (
    <div className={styles["douyin-container"]}>
      <div className={styles["header"]}>
        <div className={styles["header-title"]}>抖音券核销</div>
        <img
          className={styles["header-ubao"]}
          src={require("@/images/douyin/ubao.png")}
        />
      </div>
      <div className={styles["content"]}>
        <div className={styles["content-title"]}>抖音卡券号</div>
        <input
          className={styles["content-card"]}
          onChange={(e) => setCard(e.target.value)}
          value={card}
          type="text"
        />
        <div className={styles["content-title"]}>填写联系方式</div>
        <div
          className={`${styles["content-phone"]} ${
            selectInput === "phone" ? styles["content-phone--selected"] : ""
          }`}
        >
          <img
            className={styles["content-phone-icon"]}
            src={
              selectInput === "phone"
                ? require("@/images/login/ic_mobile_selected.png")
                : require("@/images/login/ic_mobile.png")
            }
          />
          <input
            className={styles["content-phone-input"]}
            placeholder="请输入手机号"
            onChange={(e) => setPhone(e.target.value)}
            value={phone}
            onFocus={() => {
              setSelectInput("phone");
            }}
            onBlur={() => {
              setSelectInput("");
            }}
            type="number"
          />
          {phone !== "" ? (
            <img
              className={styles["content-phone-clear"]}
              src={require("@/images/douyin/clear.png")}
              onClick={() => setPhone("")}
            />
          ) : null}
        </div>
        <div
          className={`${styles["content-code"]} ${
            selectInput === "code" ? styles["content-code--selected"] : ""
          }`}
        >
          <img
            className={styles["content-code-icon"]}
            src={
              selectInput === "code"
                ? require("@/images/login/ic_mail_selected.png")
                : require("@/images/login/ic_mail.png")
            }
          />
          <input
            className={styles["content-code-input"]}
            placeholder="请输入验证码"
            onChange={(e) => setCode(e.target.value)}
            value={code}
            onFocus={() => {
              setSelectInput("code");
            }}
            onBlur={() => {
              setSelectInput("");
            }}
            type="text"
          />
          <div
            className={styles["content-code-message"]}
            onClick={handleGetCode}
          >
            {codeText}
          </div>
        </div>
      </div>
      <div className={styles["confirm-button"]} onClick={handleClickExchange}>
        立即兑换
      </div>
      <div className={styles["prompt"]}>
        <div className={styles["prompt-title"]}>温馨提示</div>
        <div className={styles["prompt-text"]}>
          ·您在抖音购买的抖音卡券仅支持核销一次，请确保您输入的信息正确;
        </div>
        <div className={styles["prompt-text"]}>
          ·如有疑问，请及时联系抖音店铺客服
        </div>
      </div>
      <CommonModal isOpen={!!result}>
        <div className={styles["pop-container"]}>
          {result?.status === true ? (
            <div className={styles["success-pop"]}>
              <div className={styles["success-pop-text"]}>{result?.msg}</div>
            </div>
          ) : (
            <div className={styles["fail-pop"]}>
              <div className={styles["fail-pop-text"]}>{result?.msg}</div>
            </div>
          )}

          <img
            onClick={() => setResult(null)}
            className={styles["close-icon"]}
            src={require("@/images/mine/recommend-officer/button_cancel.png")}
          />
        </div>
      </CommonModal>
    </div>
  );
};

export default DouyinExchange;
