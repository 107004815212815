import "./LabelCom.less";
export default ({ title, link, callback }) => {
  return (
    <div className="LabelCom_box">
      <div className="LabelCom_box_title">{title}</div>
      <div className="LabelCom_box_link" onClick={callback}>
        {link}
      </div>
    </div>
  );
};
