import { useEffect, useState } from "react";
import { getLink } from "api/mine";
import styles from "./DouyinWeixin.module.less";
import { Toast } from "react-vant";

const DouyinWeixin = () => {
  const [link, setLink] = useState("");
  const loadLink = async () => {
    const res = await getLink();
    if (res.code == 200 && res.data?.h5_link) {
      setLink(res.data.h5_link);
    }
  };
  useEffect(() => {
    loadLink();
  }, []);
  return (
    <div className={styles["douyin-weixin-container"]}>
      <div className={styles["douyin-box"]}>
        <div className={styles["step-wrapper"]}>
          <span className={styles["step-wrapper-text"]}>Step:1</span>
          <img
            className={styles["step-wrapper-button"]}
            src={require("@/images/douyin/douyin-button-1.png")}
            onClick={() => {
              if (link) {
                window.location.href = link;
              } else {
                Toast.info("暂无班主任信息，请刷新重试...");
              }
            }}
          />
        </div>
        <img
          className={styles["step-pic--1"]}
          src={require("@/images/douyin/douyin-weixin-1.png")}
        />
        <div className={styles["video-box"]}>
          <div className={styles["video-box-title"]}>操作视频：</div>
        </div>
        <div className={styles["step-wrapper"]}>
          <span className={styles["step-wrapper-text"]}>Step:2</span>
          <div className={styles["step-wrapper-content"]}>
            <img
              className={styles["step-wrapper-button"]}
              src={require("@/images/douyin/douyin-button-2.png")}
              onClick={() => {
                window.location.href =
                  "https://downloads-apk.myaitalk.vip/unsupported.html";
              }}
            />
            <div className={styles["step-wrapper-desc"]}>点击按钮下载</div>
          </div>
        </div>
        <div className={styles["step-wrapper"]}>
          <span className={styles["step-wrapper-text"]}>Step:3</span>
          <img
            className={styles["step-wrapper-button"]}
            src={require("@/images/douyin/douyin-button-3.png")}
          />
        </div>
        <img
          className={styles["step-pic--3"]}
          src={require("@/images/douyin/douyin-weixin-3.png")}
        />
        <div className={styles["step-wrapper"]}>
          <span className={styles["step-wrapper-text"]}>Step:4</span>
          <img
            className={styles["step-wrapper-button"]}
            src={require("@/images/douyin/douyin-button-4.png")}
          />
        </div>
        <img
          className={styles["step-pic--4"]}
          src={require("@/images/douyin/douyin-weixin-4.png")}
        />
      </div>
    </div>
  );
};

export default DouyinWeixin;
