import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Feedback.module.less";
import LoginTab from "views/login/components/LoginTab";
import CommonButton from "views/components/CommonButton";
import CommonNavBar from "views/components/CommonNavBar";
import LoginInput from "views/login/components/LoginInput";
import { Toast } from "react-vant";
import DSBridge from "dsbridge";

const initTabs = [
  {
    key: "info",
    value: "举报反馈"
  }
];
function Feedback() {
  const navigate = useNavigate();
  const [screenshot, setScreenshot] = useState("");
  const handleAvatarChange = async (e) => {
    const file = e.target.files[0];
    const maxSize = 2 * 1024 * 1024;
    if (file.size > maxSize) {
      return Toast.fail("请选择小于2MB文件上传");
    }
    const reader = new FileReader();
    reader.onloadend = () => {
      setScreenshot(reader.result);
    };
    reader.readAsDataURL(file);
  };
  const handleConfirm = async () => {
    Toast.info("反馈成功");
    try {
      if (DSBridge.hasNativeMethod("auth.uploadLog")) {
        DSBridge.call("auth.uploadLog", "");
      }
    } catch (e) {
      console.log(e);
    }
    navigate(-1);
  };
  return (
    <div className={styles["feedback-container"]}>
      <CommonNavBar
        onClickBack={() => {
          navigate(-1);
        }}
      />
      <img
        className={styles["right-banner"]}
        src={require("@/images/login/right-banner.png")}
      />
      <LoginTab className={styles["login-tab"]} dataList={initTabs} />
      <div className={styles["info-avatar"]}>
        <div className={styles["info-avatar-content"]}>
          <img
            className={styles["content-img"]}
            src={screenshot || require("@/images/login/ic_avatar.png")}
          />
          <input
            accept="image/*"
            className={styles["info-avatar-input"]}
            onChange={handleAvatarChange}
            type="file"
          />
          <span className={styles["content-text"]}>
            {!screenshot ? "点击上传截图" : "点击修改截图"}
          </span>
        </div>
        <img
          className={styles["info-avatar-arrow"]}
          src={require("@/images/login/ic_right_arrow.png")}
        />
      </div>
      <LoginInput
        className={styles["common-input"]}
        placeholderText="请输入反馈人姓名"
        type="textarea"
      />
      <LoginInput
        className={styles["common-input"]}
        placeholderText="请输入反馈内容"
        type="textarea"
      />
      <CommonButton
        className={styles["submit-button"]}
        delay={500}
        onClick={handleConfirm}
        text="完成"
      />
      <div className={styles["phone-number"]}>客服电话 0571-28010729</div>
    </div>
  );
}
export default Feedback;
