import "./Learn.less";
import { Tabs, Skeleton } from "react-vant";
import TeacherList from "./teacher/TeacherList";
import { setMyTeacher } from "api/teacher";
import { useEffect, useState } from "react";
import { getTeacherList, getMyTeacher } from "api/teacher";
import { useLocation, useNavigate } from "react-router-dom";
import SearchList from "./teacher/SearchList";
const fang_da_jing = require("@/assets/icon/fang_da_jing.png");
function Learn() {
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState("");
  const [active, setActive] = useState(0);
  const [searchShow, setSearchShow] = useState(false);
  const [dataListLeft, setDataListLeft] = useState([]);
  const [dataListRight, setDataListRight] = useState([]);
  const [isSearch, setIsSearch] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  async function getList(types, keyword) {
    setLoading(true);
    if (types.includes("elevebLabs")) {
      localStorage.getItem("teachersList")
        ? localStorage.getItem("teachersList")
        : localStorage.setItem(
            "teachersList",
            "/teachers/elevebLabsTeacher.php"
          );
      const result = await getTeacherList(
        `${localStorage.getItem("teachersList")}${
          keyword ? "?keyword=" + keyword : ""
        }`,
        "nocache"
      );
      setDataListLeft(result.data);
    }
    if (types.includes("favorite")) {
      const result = await getMyTeacher(keyword, "nocache");
      setDataListRight(result.data);
    }
    if (keyword !== undefined && keyword !== "") {
      setIsSearch(true);
    } else {
      setIsSearch(false);
    }
    setLoading(false);
  }
  async function handleFavorite(character_id) {
    if (!!!localStorage.getItem("access_token")) {
      navigate("/login/logon");
      return;
    }
    const result = await setMyTeacher({
      teacher_id: character_id
    });
    if (result.status) {
      let setDataList;
      if (active === 0) {
        setDataList = setDataListLeft;
      }
      if (active === 1) {
        setDataList = setDataListRight;
      }

      setDataList((dataList) => {
        const newDataList = dataList.map((item) =>
          item.character_id === character_id
            ? { ...item, favorite: !item.favorite }
            : item
        );
        if (
          active === 1 &&
          dataList.findIndex((item) => item.character_id === character_id) !==
            undefined
        ) {
          newDataList.splice(
            dataList.findIndex((item) => item.character_id === character_id),
            1
          );
        }
        return newDataList;
      });
    }
  }
  async function handleSearch(keyword) {
    setValue(keyword);
    if (active === 1) {
      getList(["favorite"], keyword);
    }
    if (active === 0) {
      getList(["elevebLabs"], keyword);
    }
  }
  function handleNavSearch() {
    setSearchShow(true);
  }
  useEffect(() => {
    const index = Number(
      location.pathname.charAt(location.pathname.length - 1)
    );
    setActive(index);
    setValue("");
    if (index === 0) {
      getList(["elevebLabs"]);
    }
    if (index === 1) {
      getList(["favorite"]);
    }
  }, [location.pathname]);
  return (
    <div className="learn-teacher">
      <div className="block-sticky"></div>
      <img
        className="fang_da_jing"
        onClick={handleNavSearch}
        src={fang_da_jing}
      />
      <div className="learn-teacher-content">
        <Tabs
          active={active}
          className="top-menu"
          defaultActive={0}
          // offsetTop={"2rem"}
          onClickTab={async (result) => {
            const { index } = result;
            const path = location.pathname;
            navigate(`${path.slice(0, path.length - 1)}${index}`);
          }}
          onChange={async (index) => {
            const path = location.pathname;
            navigate(`${path.slice(0, path.length - 1)}${index}`);
          }}
          // sticky
          // swipeable
          type="line"
        >
          <Tabs.TabPane title="全部外教">
            {loading ? (
              <div>
                <Skeleton className="sk-container" title />
              </div>
            ) : (
              <TeacherList
                dataList={dataListLeft}
                handleFavorite={handleFavorite}
                handleNavSearch={handleNavSearch}
                setValue={setValue}
                value={value}
                isSearch={isSearch}
              />
            )}
          </Tabs.TabPane>
          <Tabs.TabPane title="我的外教">
            {loading ? (
              <div>
                <Skeleton className="sk-container" title />
              </div>
            ) : (
              <TeacherList
                dataList={dataListRight}
                handleFavorite={handleFavorite}
                handleNavSearch={handleNavSearch}
                setValue={setValue}
                value={value}
                isSearch={isSearch}
              />
            )}
          </Tabs.TabPane>
        </Tabs>
      </div>
      {searchShow ? (
        <SearchList
          handleSearch={handleSearch}
          keyword={value}
          setSearchShow={setSearchShow}
        />
      ) : null}
    </div>
  );
}

export default Learn;
