import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./ModifyPassword.module.less";
import LoginInput from "views/login/components/LoginInput";
import LoginTab from "views/login/components/LoginTab";
import CommonButton from "views/components/CommonButton";
import InputContainer from "views/login/components/InputContainer";
import CommonNavBar from "views/components/CommonNavBar";
import CodeDown from "views/login/components/CodeDown";
import { Toast } from "react-vant";
import { changePassword } from "api/register";
import { commonRules } from "views/login/components/utils";

const initTabs = [
  {
    key: "modify",
    value: "修改密码"
  }
];

function ModifyPassword() {
  const navigate = useNavigate();
  const inputContainerRef = useRef(null);
  const handleModify = async (params) => {
    const res = await changePassword(params);
    if (res.code === 200) {
      Toast.info(res.message);
      localStorage.clear();
      navigate("/login/logon", { replace: true });
    }
  };
  const handleClick = async () => {
    try {
      const params = await inputContainerRef.current.getValues();
      if (params.repassword !== params.password) {
        return Toast.info("密码与确认密码不同");
      }
      handleModify(params);
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <div className={styles["modify-password-container"]}>
      <CommonNavBar
        onClickBack={() => {
          navigate("/mine/systemSetting", { replace: true });
        }}
      />
      <img
        className={styles["right-banner"]}
        src={require("@/images/login/right-banner.png")}
      />
      <img
        className={styles["top-banner"]}
        src={require("@/images/login/center-banner.png")}
      />
      <LoginTab className={styles["login-tab"]} dataList={initTabs} />
      <InputContainer ref={inputContainerRef} rules={commonRules}>
        <LoginInput
          className={styles["login-input"]}
          field="phone_number"
          icon={require("@/images/login/ic_mobile.png")}
          inputMode="numeric"
          placeholderText="请输入手机号"
          selectedIcon={require("@/images/login/ic_mobile_selected.png")}
        />
        <LoginInput
          className={styles["login-input"]}
          field="verification_code"
          icon={require("@/images/login/ic_mail.png")}
          inputMode="numeric"
          placeholderText="请输入验证码"
          selectedIcon={require("@/images/login/ic_mail_selected.png")}
        >
          <CodeDown
            getPhone={() => {
              return inputContainerRef.current.getValue("phone_number");
            }}
          />
        </LoginInput>
        <LoginInput
          className={styles["login-input"]}
          field="password"
          icon={require("@/images/login/ic_pwd.png")}
          placeholderText="新密码"
          selectedIcon={require("@/images/login/ic_pwd_selected.png")}
          type="password"
        />
        <LoginInput
          className={styles["login-input"]}
          field="repassword"
          icon={require("@/images/login/ic_pwd.png")}
          placeholderText="确认密码"
          selectedIcon={require("@/images/login/ic_pwd_selected.png")}
          type="password"
        />
      </InputContainer>
      <CommonButton
        className={styles["login-submit"]}
        delay={500}
        onClick={handleClick}
        text="提交"
      />
    </div>
  );
}
export default ModifyPassword;
