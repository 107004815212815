import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./RecommendOfficer.module.less";
import CommonNavBar from "views/components/CommonNavBar";
import { getPromoterInfo, updatePromoter, checkUpdatePromoter } from "api/mine";
import { Toast } from "react-vant";

function RecommendOfficer() {
  const navigate = useNavigate();
  const [modalType, setModalType] = useState(0);
  const [code, setCode] = useState("");
  const [data, setData] = useState(null);
  const [promptText, setPromptText] = useState("");
  const officerLoadingRef = useRef(null);
  const handleAdd = async () => {
    officerLoadingRef.current = Toast.loading({
      message: "推荐人信息修改中...",
      forbidClick: true,
      duration: 0
    });
    const formData = new FormData();
    formData.append("invitation_code", code);
    const res = await updatePromoter(formData);
    if (res.code === 200) {
      if (res.data.status === true) {
        setModalType(0);
        setCode("");
        initData();
      } else {
        setPromptText(res.data?.msg || "请重试...");
        setModalType(3);
      }
    }
    officerLoadingRef.current.clear();
  };
  const initData = async () => {
    officerLoadingRef.current = Toast.loading({
      message: "推荐人信息获取中...",
      forbidClick: true,
      duration: 0
    });
    const infoRes = await getPromoterInfo();
    if (infoRes.code === 200) {
      setData(infoRes.data);
    }
    officerLoadingRef.current.clear();
  };
  const handleCheckData = async () => {
    officerLoadingRef.current = Toast.loading({
      message: "正在加载中...",
      forbidClick: true,
      duration: 0
    });
    const checkRes = await checkUpdatePromoter();
    if (checkRes.code === 200) {
      if (checkRes.data?.status === true) {
        if (data.phone_number) {
          setModalType(2);
        } else {
          setModalType(1);
        }
      } else {
        setPromptText(checkRes.data?.msg || "请重试...");
        setModalType(3);
      }
    }
    officerLoadingRef.current.clear();
  };
  useEffect(() => {
    initData();
  }, []);
  return (
    <div className={styles["recommend-container"]}>
      <CommonNavBar
        title={"我的推荐官"}
        onClickBack={() => {
          navigate(-1);
        }}
      />
      {data !== null ? (
        data.phone_number !== null ? (
          <div className={styles["box-has"]}>
            <img
              className={styles["box-has-left"]}
              src={
                data.profile_image
                  ? `${process.env.REACT_APP_API_CDN}${
                      data.profile_image[0] === "/" ? "" : "/"
                    }${data.profile_image}`
                  : require("@/images/mine/recommend-officer/avatar.jpg")
              }
            />
            <div className={styles["box-has-right"]}>
              <div className={styles["right-text"]}>
                {data?.phone_number || "暂无手机号"}
              </div>
              <div className={styles["right-change"]} onClick={handleCheckData}>
                更换
              </div>
            </div>
          </div>
        ) : (
          <div className={styles["box-none"]}>
            <div className={styles["box-none-text"]}>您还没有绑定推荐官</div>
            <div className={styles["box-none-add"]} onClick={handleCheckData}>
              添加
            </div>
          </div>
        )
      ) : null}
      {modalType !== 0 ? (
        <div className={`${styles["modal"]}`}>
          {modalType === 1 ? (
            <div className={styles["code-content"]}>
              <div className={styles["code-content-title"]}>请填写邀请码</div>
              <input
                className={styles["code-content-input"]}
                type="text"
                onChange={(e) => {
                  setCode(e.target.value);
                }}
              />
              <div
                className={styles["code-content-confirm"]}
                onClick={handleAdd}
              >
                确认
              </div>
            </div>
          ) : null}
          {modalType === 2 ? (
            <div className={styles["change-content"]}>
              <div className={styles["change-content-title"]}>
                <div className={styles["title-wrapper"]}>
                  <span className={styles["color-1"]}>是否确认</span>
                  <span className={styles["color-2"]}>更换推荐官</span>
                </div>
                <div className={styles["title-wrapper"]}>
                  <span className={styles["color-1"]}>一个月</span>
                  <span className={styles["color-2"]}>仅可更换</span>
                  <span className={styles["color-1"]}>一次</span>
                </div>
              </div>
              <div
                className={styles["change-content-confirm"]}
                onClick={() => setModalType(1)}
              >
                确认
              </div>
            </div>
          ) : null}
          {modalType === 3 ? (
            <div className={styles["prompt-content"]}>
              <div className={styles["prompt-content-title"]}>温馨提示</div>
              <div className={styles["prompt-content-text"]}>{promptText}</div>
              <div
                className={styles["change-content-confirm"]}
                onClick={() => {
                  setModalType(0);
                  setCode("");
                  setPromptText("");
                }}
              >
                确认
              </div>
            </div>
          ) : null}
          <img
            onClick={() => {
              setModalType(0);
              setCode("");
              setPromptText("");
            }}
            className={styles["modal-close"]}
            src={require("@/images/mine/recommend-officer/button_cancel.png")}
          />
        </div>
      ) : null}
    </div>
  );
}
export default RecommendOfficer;
