import CommonNavBar from "views/components/CommonNavBar";
import { Dialog } from "react-vant";
import { useNavigate } from "react-router-dom";
import styles from "./SystemSetting.module.less";
import { useEffect, useRef, useState } from "react";
import DSBridge from "dsbridge";

function SystemSetting() {
  const navigate = useNavigate();
  const systemRef = useRef(null);
  const [appInfo, setAppInfo] = useState({});
  const options = [
    {
      title: "换绑手机号",
      icon: require("@/images/mine/ic_mine_phone.png"),
      onClick: () => {
        navigate("/mine/modifyPhone", {
          state: {
            lastRoute: "/mine/systemSetting"
          },
          replace: true
        });
      }
    },
    {
      title: "修改密码",
      icon: require("@/images/mine/ic_mine_password.png"),
      onClick: () => {
        navigate("/mine/modifyPassword", { replace: true });
      }
    },
    {
      title: "我的推荐官",
      icon: require("@/images/mine/ic_mine_recommend.png"),
      onClick: () => {
        navigate("/mine/recommendOfficer");
      }
    },
    {
      title: "我的收货地址",
      icon: require("@/images/mine/ic_mine_address.png"),
      onClick: () => {
        navigate("/mine/mailingAddress");
      }
    },
    {
      title: "算法备案公示",
      icon: require("@/images/mine/ic_mine_publicity.png"),
      onClick: () => {
        Dialog.alert({
          title: "算法备案公示",
          teleport: systemRef.current,
          closeable: true,
          theme: "round-button",
          message: (
            <div className={styles["dialog-container"]}>
              <div>模型名称：</div>
              <div>文心大模型算法-2</div>
              <div>备案号：</div>
              <div>网信算备110108645502801230069号</div>
              <div>模型名称：</div>
              <div>百度语音合成算法</div>
              <div>备案号：</div>
              <div>网信算备110108645502801230043号</div>
            </div>
          )
        });
      }
    },
    {
      title: "注销账号",
      icon: require("@/images/mine/ic_mine_logoff.png"),
      onClick: () => {
        navigate("/mine/logoff");
      }
    }
  ];
  useEffect(() => {
    const appInfo = DSBridge.call("getAppInfo");
    setAppInfo(appInfo);
  }, []);
  return (
    <div className={styles["system-setting-container"]} ref={systemRef}>
      <CommonNavBar
        backgroundColor="#fff"
        onClickBack={() => {
          navigate("/main/mine", { replace: true });
        }}
        title="系统设置"
      />
      <div className={styles["option-list"]}>
        {options.map((item, index) => (
          <div
            className={styles["option-list-item"]}
            key={`system-option-${index}`}
            onClick={item.onClick}
          >
            <div className={styles["item-left"]}>
              <img className={styles["item-left-icon"]} src={item.icon} />
              <div className={styles["item-left-title"]}>{item.title}</div>
            </div>
            <img
              className={styles["item-right"]}
              src={require("@/images/login/ic_right_arrow.png")}
            />
          </div>
        ))}
      </div>
      <div
        className={styles["logout-button"]}
        onClick={() => {
          localStorage.clear();
          navigate("/login/logon", { replace: true });
        }}
      >
        退出登录
      </div>
      <div className={styles["filings-wrapper"]}>
        <div className={styles["filings-wrapper-item"]}>
          <span className={styles["filings-wrapper-item-title"]}>备案号</span>
          <span className={styles["filings-wrapper-item-text"]}>
            {appInfo?.recordNumber || "浙ICP备2023048684号-2A"}
          </span>
        </div>
        <div
          className={styles["filings-wrapper-item"]}
          onClick={() => {
            navigate(
              `/mine/webUrl?webUrl=
                https://beian.miit.gov.cn&title=${encodeURIComponent(
                  "查询链接"
                )}
              `
            );
          }}
        >
          <span className={styles["filings-wrapper-item-title"]}>查询链接</span>
          <span className={styles["filings-wrapper-item-text"]}>
            {appInfo?.recordNumberLink || "https://beian.miit.gov.cn"}
          </span>
        </div>
      </div>
    </div>
  );
}
export default SystemSetting;
