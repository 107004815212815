import request from "utils/request";

export async function getTeacherList(url = "", nocache = "nocache") {
  return request.get(url, nocache);
}

export async function getMyTeacher(keyword, nocache = "nocache") {
  const url = keyword
    ? `/teachers/favoriteTeachers.php?keyword=${keyword}`
    : "/teachers/favoriteTeachers.php";
  return request.get(url, nocache);
}

export async function setMyTeacher(data) {
  return request.post("/teachers/favoriteTeachers.php", data, "nocache");
}

export async function getSpeakAudio({ token, text, voice_id }) {
  return request.post(
    `/teachers/speakAudio.php?token=${token}&text=${text}&voice_id=${voice_id}`,
    "nocache"
  );
}

export async function getAudioInfo(data) {
  return request.post("/teachers/audioInfo.php", data, "nocache");
}
