import request from "utils/request";

export async function getLesson(page) {
  return request.get(`/lesson/lesson.php?page=${page}`, "nocache");
}

export async function getLessonv2(page) {
  return request.get(`/lesson/lessonv2.php?page=${page}`, "nocache");
}

export async function getMyLesson(page) {
  return request.get(`/lesson/myLesson.php?page=${page}`, "nocache");
}

export async function getMyLessonv2(page) {
  return request.get(`/lesson/myLessonv2.php?page=${page}`, "nocache");
}

export async function getMyQualification(data, customConfig = {}) {
  return request.post("/lesson/startLesson.php", data, Object.assign({ cache: "nocache" }, customConfig));
}

export async function getChatHistory(id) {
  return request.get(`/history/playback.php?lesson_id=${id}`, "nocache");
}

export async function getPreLesson(data) {
  return request.post("/lesson/preLesson.php", data, "nocache");
}

export function getDemoAnswer() {
  return request.get(`/lesson/demoAnswer.php`, "nocache");
}

export async function postSubmitAnswer(data) {
  return request.post("/lesson/submitAnswer.php", data, "nocache");
}

export function getDemoMeaterial() {
  return request.get(`/materials/demoMeaterial.php`, "nocache");
}