import request from "utils/request";

export async function getProductList() {
  return request.get(`/product/list.php`, "nocache");
}

export async function getMyQualification(data) {
  return request.post("/product/order.php", data, "nocache");
}

export async function paymentCallback(data) {
  return request.post("/product/paymentCallback.php", data, "nocache");
}

export async function applepayCallback(data) {
  return request.post("product/appleCallback.php", data, "nocache");
}

export async function getRedeemCodeInfo(data) {
  return request.post("/product/getRedeemCodeInfo.php", data, "nocache");
}

export async function redeemCode(data) {
  return request.post("/product/redeemCode.php", data, "nocache");
}