import request from "utils/request";

export async function getWebList() {
  return request.get("/product/getWebList.php", "nocache");
}

export async function orderH5(params) {
  return request.post("/product/orderH5.php", params, "nocache");
}

export async function queryOrderStatus(params) {
  return request.post("/product/queryOrderStatus.php", params, "nocache");
}

export async function getDouyin(params) {
  return request.post("/douyin/douyinExchange.php", params, "nocache");
}