import request from "utils/request";
import { getStudentInfo } from "utils/userProfile";
import { mergeValidValues } from "utils/objectTools.js";
import DSBridge from "dsbridge";

export async function getUserInfo() {
  const userInfo = await request.get("/profile/getUserInfo.php", "nocache");
  if (userInfo && userInfo.status === true) {
    localStorage.setItem("userInfo", JSON.stringify(mergeValidValues(getStudentInfo(), userInfo.data)));
    try {
      if (DSBridge.hasNativeMethod("auth.updateUserProfile")) {
        if (localStorage.getItem("access_token")) {
          userInfo.data["token"] = localStorage.getItem("access_token");
          DSBridge.call("auth.updateUserProfile", JSON.stringify(userInfo.data));
        }
      }
    } catch (e) {
      console.log(e);
    }

  }
  return userInfo;
}

export async function updateUserProfile(data) {
  return request.post("/profile/updateUserProfile.php", data, "nocache");
}

export async function getUserProfile() {
  const profileRes = await request.get("/profile/getUserProfile.php", "nocache");
  if (profileRes.code === 200) {
    try {
      localStorage.setItem("userInfo", JSON.stringify(mergeValidValues(getStudentInfo(), profileRes.data)));
      if (DSBridge.hasNativeMethod("auth.updateUserProfile")) {
        if (localStorage.getItem("access_token")) {
          profileRes.data["token"] = localStorage.getItem("access_token");
          DSBridge.call("auth.updateUserProfile", JSON.stringify(mergeValidValues(getStudentInfo(), profileRes.data)));
        }
      }
    } catch (e) {
      console.log(e);
    }
  }
  return profileRes;
}

export async function getUserCourse() {
  return request.get("/profile/getUserCourse.php", "nocache");
}
export function deleteUserController() {
  return request.delete("/profile/deleteUserController.php", "nocache");
}