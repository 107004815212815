import { useState, useRef, useEffect } from "react";
import "./DollMachine.less";
import Countdown from "./components/Countdown";
import DSBridge from "dsbridge";
export default function DollMachine() {
  const [startDisabled, setStartDisabled] = useState(false);
  const countRef = useRef(null);
  const [selectIndex, setSelectIndex] = useState(null);
  const score = useRef(0);
  const dollRef = useRef(null);
  const [listHide, setListHide] = useState(false);
  const [resultShow, setResultShow] = useState(false);
  const exitRef = useRef(null);
  const preRef = useRef(null);
  const [showPre, setShowPre] = useState(false);
  function handleClickStart() {
    if (!startDisabled) {
      setStartDisabled(true);
      countRef.current.handleStop();
      setShowPre(true);
      preRef.current = setTimeout(() => {
        setShowPre(false);
        const randomIndex = Math.floor(Math.random() * 3);
        setSelectIndex(randomIndex);
        score.current = [5, 7, 8, 10][randomIndex];
      }, 4000);
    }
  }
  function handleCountChange(count) {
    if (count === 0) {
      setStartDisabled(true);
      exitRef.current = setTimeout(() => {
        DSBridge.call("stopAudio", "doll.mp3");
        DSBridge.call("exitWebView");
      }, 1000);
      console.log("倒计时未点击，跳出");
    }
  }
  async function init() {
    setStartDisabled(false);
    setSelectIndex(null);
    score.current = 0;
    countRef.current.handleStart(15);
    setListHide(false);
    setResultShow(false);
    DSBridge.call("playAudio", "doll.mp3");
  }
  useEffect(() => {
    init();
    function handleAnimationEnd(e) {
      const classNames = e.target.className.split(" ");
      if (
        classNames.includes("ubao-top-bg--select1") ||
        classNames.includes("ubao-top-bg--select3")
      ) {
        setListHide(true);
      }
      if (classNames.includes("machine-center-list")) {
        setResultShow(true);
        DSBridge.call("playAudio", "victory.mp3");
      }
      if (classNames.includes("machine-center-result")) {
        exitRef.current = setTimeout(() => {
          DSBridge.call("addScore", String(score.current));
          DSBridge.call("stopAudio", "doll.mp3");
          DSBridge.call("stopAudio", "victory.mp3");
          DSBridge.call("exitWebView");
        }, 1000);
        console.log(`加分：${score.current}`);
      }
    }
    dollRef.current = document.querySelector(".doll-container");
    if (dollRef.current) {
      dollRef.current.addEventListener("animationend", handleAnimationEnd);
      dollRef.current.addEventListener(
        "webkitAnimationEnd",
        handleAnimationEnd
      );
      dollRef.current.addEventListener("transitionend", handleAnimationEnd);
      dollRef.current.addEventListener(
        "webkitTransitionEnd",
        handleAnimationEnd
      );
    }
    return () => {
      if (dollRef.current) {
        dollRef.current.removeEventListener("animationend", handleAnimationEnd);
        dollRef.current.removeEventListener(
          "webkitAnimationEnd",
          handleAnimationEnd
        );
        dollRef.current.removeEventListener(
          "transitionend",
          handleAnimationEnd
        );
        dollRef.current.removeEventListener(
          "webkitTransitionEnd",
          handleAnimationEnd
        );
      }
      if (exitRef.current) {
        clearTimeout(exitRef.current);
      }
      if (preRef.current) {
        clearTimeout(preRef.current);
      }
    };
  }, []);
  return (
      <div className="doll-container">
        {/*<img className="bg-top" src={require("@/images/game/doll/bg-top.png")}/>*/}
        {/*<img className="bg-bottom" src={require("@/images/game/doll/bg-bottom.png")}/>*/}
        <div className="bg-top"></div>
        <div className="bg-bottom"></div>
        <Countdown handleCountChange={handleCountChange} ref={countRef}/>
        <div className="machine">
          <img
              className="machine-mask"
              src={require("@/images/game/doll/machine-mask.png")}
          />
          <div className="machine-center">
            {!resultShow ? (
                <div
                    className={`machine-center-list${
                        listHide ? " machine-center-list--hide" : ""
                    }`}
                >
                  {[1, 2, 3, 4].map((item, index) => {
                    return (
                        <div className="ubao" key={`machine-ubao-${index}`}>
                          <div
                              className={`ubao-top${
                                  selectIndex === index
                                      ? selectIndex === 0
                                          ? " ubao-top--select1"
                                          : " ubao-top--select3"
                                      : ""
                              }`}
                          >
                            {selectIndex === index ? (
                                <img
                                    className={`ubao-top-bg${
                                        selectIndex === 0
                                            ? " ubao-top-bg--select1"
                                            : " ubao-top-bg--select3"
                                    }`}
                                    src={require("@/images/game/doll/ubao-bg.png")}
                                />
                            ) : null}
                            <img
                                className="ubao-top-doll"
                                src={require(`@/images/game/doll/ubao-${item}.png`)}
                            />
                          </div>
                          <img
                              className="ubao-bottom"
                              src={require("@/images/game/doll/ubao-base.png")}
                          />
                        </div>
                    );
                  })}
                  <img
                      className={`machine-center-list-hanger ${
                          showPre
                              ? "machine-center-list-hanger--move-common"
                              : selectIndex !== null
                                  ? "machine-center-list-hanger--move-" + (selectIndex + 1)
                                  : ""
                      }`}
                      src={require("@/images/game/doll/hanger.png")}
                  />
                </div>
            ) : (
                <img
                    className="machine-center-result"
                    src={require(`@/images/game/doll/score-${score.current}.png`)}
                />
            )}
            <div
                className={`machine-center-button${
                    startDisabled ? " machine-center-button--disabled" : ""
                }`}
                onClick={handleClickStart}
            ></div>
          </div>
        </div>
      </div>
  );
}
