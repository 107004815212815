import "./index.less";
import { useNavigate } from "react-router-dom";
const title1 = require("@/assets/images/invite/标题-8.png");
const q100 = require("@/assets/images/invite/u宝-8.png");
const btnImg = require("@/assets/images/invite/立即体验按钮-8.png");
const tsImg = require("@/assets/images/invite/课程特色方框-8.png");
const kcImg = require("@/assets/images/invite/课程内容方框-8.png");
const hxlImg = require("@/assets/images/invite/七大核心力.png");
const code = require("@/assets/images/invite/二维码-8.png");
const regImg = require("@/assets/images/invite/register.png");
import { Checkbox, Input, Form, Toast, Button, Notify } from "react-vant";
import CodeDown from "@/views/login/components/CodeDown";
import getRules from "@/utils/validators";
import { register } from "@/api/register";
import { getUrlParams } from "utils/tools";

export default () => {
  const [form] = Form.useForm();
  const { getFieldsValue } = form;
  const navigate = useNavigate();
  const commit = async () => {
    // phone_number: undefined,
    // verification_code: undefined,
    // password: undefined,
    // repassword: undefined,
    // inviteCode: undefined
    // const defaultPassword = 123456;
    const params = getFieldsValue();
    const regEmoji =
      /[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF][\u200D|\uFE0F]|[\uD83C|\uD83D|\uD83E][\uDC00-\uDFFF]|[0-9|*|#]\uFE0F\u20E3|[0-9|#]\u20E3|[\u203C-\u3299]\uFE0F\u200D|[\u203C-\u3299]\uFE0F|[\u2122-\u2B55]|\u303D|[\xA9|\xAE]\u3030|\uA9|\uAE|\u3030/;
    if (
      params.name === "" ||
      !/^[a-zA-Z\s]*$/.test(params.name) ||
      regEmoji.test(params.name)
    ) {
      return Notify.show({
        type: "danger",
        message: "英文名仅允许输入英文字符"
      });
    }
    Object.assign(params, {
      // Password: defaultPassword,
      // repassword: defaultPassword,
      // 邀请码
      rec_id: getUrlParams().rec_id,
      is_web: 1
    });
    delete params.checkbox;
    const res = await register(params);
    Toast.info(res?.message ?? "未知错误");
    if (res.code == 200) navigate("/joinSuccess");
  };
  return (
    <div className="InviteBox">
      <img className="title1" src={title1}></img>
      <img className="q100" src={q100}></img>
      {/* 第一个框 */}
      <div className="userInfoBox">
        <img className="back-img" src={regImg} />
        <Form form={form} onFinish={commit}>
          <div className="formItem">
            <Form.Item
              rules={[{ required: false, message: "请输入英文名" }]}
              name="name"
            >
              <Input placeholder="英文名" maxLength={15} />
            </Form.Item>
          </div>
          <div className="formItem">
            <Form.Item
              rules={[
                { required: true, message: "请输入手机号" },
                {
                  validator: (_, value) => {
                    if (getRules().phone.test(value))
                      return Promise.resolve(true);
                    return Promise.reject(new Error("请输入正确的手机号码"));
                  }
                }
              ]}
              name="phone_number"
            >
              <Input placeholder="手机号" />
            </Form.Item>
          </div>
          <div className="formItem">
            <Form.Item
              rules={[{ required: true, message: "请输入验证码" }]}
              name="verification_code"
            >
              <Input placeholder="验证码" />
            </Form.Item>
            <a className="tagA a">
              <CodeDown getPhone={() => getFieldsValue().phone_number} />
            </a>
          </div>
          <div className="formItem">
            <Form.Item
              name="checkbox"
              rules={[{ required: true, message: "请勾选协议" }]}
            >
              <Checkbox shape="square" />
            </Form.Item>
            <span className="xy">
              同意
              <a
                className="tagA"
                onClick={() => {
                  navigate(
                    `/webUrlView?webUrl=${encodeURIComponent(
                      `${process.env.REACT_APP_API_CDN}/utalkrc/privacy/en/app-service.html`
                    )}&title=${encodeURIComponent("课程服务协议")}`
                  );
                }}
              >
                《课程服务协议》
              </a>
              与
              <a
                className="tagA"
                onClick={() => {
                  navigate(
                    `/mine/webUrl?webUrl=${encodeURIComponent(
                      `${process.env.REACT_APP_API_CDN}/utalkrc/privacy/en/app-privacy-policy.html`
                    )}&title=${encodeURIComponent("隐私协议")}`
                  );
                }}
              >
                《隐私政策》
              </a>
            </span>
          </div>
          <div className="formItem a">
            <Button nativeType="submit">
              <img src={btnImg} />
            </Button>
          </div>
        </Form>
      </div>
      <img className="tsImg" src={tsImg}></img>
      <img className="kcImg" src={kcImg}></img>
      <img className="hxlImg" src={hxlImg}></img>
      <img className="code" src={code}></img>
    </div>
  );
};
